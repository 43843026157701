import React, { useState } from "react";

import { Container, Row, Col, Form, Alert } from "react-bootstrap";

//Components
import TopMargin from "../components/TopMargin";
import RowMargin from "../components/RowMargin";
import Loading from "../components/Loading";
import SuperAppButton from "../components/SuperAppButton";
import { fetchSecure } from "../utils/utils";

export default function UploadFileForImages(props) {
  const [fileName, setFileName] = useState(props.fileName || null);
  const [fileNames, setFileNames] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [formData, setFormData] = useState({
    data: {},
    status: "IDLE",
  });

  const resetFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };


  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const localFileNames = [];
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        //value = e.target.files[0];
        value = e.target.files;
        for (let x = 0; x < value.length; x++) {
          console.log("File Name = " + value[x].name);
          console.log("File Prefix = " + props.filePrefix);
          localFileNames.push(props.filePrefix + value[x].name);
        }
        console.log("files = " + JSON.stringify(localFileNames));
        setFileNames(localFileNames);
        console.log("file value = " + JSON.stringify(value));
        break;
      default:
        value = target.value;
        break;
    }
    const name = target.name;
    console.log(target);
    console.log(props.filePrefix);
    console.log(name);
    if (props.filePrefix && name === "fileName") {
      console.log("IN HERE - " + props.filePrefix + value.name);
      setFileName(props.filePrefix + value.name);
    }
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  const handleSubmit = async () => {
    try {
      setFormData({
        ...formData,
        status: "SUBMITTED",
      });

      const uploadData = new FormData();
      console.log("XXX: " + JSON.stringify(formData.data));
      Object.keys(formData.data).forEach((key) => {
        console.log("KEY = " + key);
        if (fileName) {
          for (let x = 0; x < formData.data[key].length; x++)
            //uploadData.append(key, formData.data[key], fileName); think this is the old code when you could only upload one file at a time
            uploadData.append(
              key + "-" + x,
              formData.data[key][x],
              fileNames[x]
            );
        } /* for other fields you might want to send */ else
          uploadData.append(key, formData.data[key]);
      });
      uploadData.append("id", props.id);
      uploadData.append("userId", props.user_id);
      console.log("uploadData = ");
      for (var pair of uploadData.entries()) {
        console.log(pair[0] + ", " + JSON.stringify(pair[1]));
      }

      const response = await fetchSecure("/api/upload_for_images", {
        method: "post",
        body: uploadData,
      });

      const data = await response.json();
      if (response.status === 200) {
        setFormData({
          data: data,
          status: "SUCCESS",
        });

        resetFileInput();

        /* call back to redraw the uploaded image(s) */
        setTimeout(function () {
          if (props.callback) props.callback();
        }, 1000);
      } else {
        setFormData({
          data: data,
          status: "ERROR",
        });
      }
    } catch (e) {
      console.log(e);
      console.log(`Failed to upload new document`);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <TopMargin />
          <Form class="XXX" style={{ marginBottom: "20px" }}>
            <Form.Group controlId='uploadFile'>
              <Form.Label style={{ color: "#fff"}}>Upload Image File(s)</Form.Label>
              <Form.Control
                key={fileInputKey}
                type='file'
                name='fileName'
                placeholder='Select a file or files'
                className='form-control'
                onChange={handleInputChange}
                accept='pdf'
                multiple
                style={{
                  height: '38px',
                }}
              />
            </Form.Group>
            <Form.Group></Form.Group>
            <RowMargin />
            <SuperAppButton
              variant='primary'
              type='button'
              onClick={handleSubmit}
              disabled={fileNames.length === 0}>
              Upload
            </SuperAppButton>
            &nbsp;
          </Form>
        </Col>
      </Row>
      {formData.status === "SUBMITTED" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Loading />
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "ERROR" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='danger'>
                {formData.data && "message" in formData.data
                  ? formData.data.message
                  : "Failed to upload file, please try again"}
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='success'>
                {formData.data && "message" in formData.data
                  ? formData.data.message
                  : "Successfully uploaded file!"}
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </Container>
  );
}

/* used to have a cancel button..but not sure it makes much sense
            <Button variant="primary" type="button" onClick={props.handleUploadFileCancel}>
              Cancel
            </Button>
*/
