import React, { useState } from "react";

import { Row, Col, Form, Button, Alert } from "react-bootstrap";

//Components
import RowMargin from "../components/RowMargin";
import Loading from "../components/Loading";
import { fetchSecure } from "../utils/utils";

export default function UploadFileForImport(props) {
  const [fileName, setFileName] = useState(props.fileName || null);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [formData, setFormData] = useState({
    data: {},
    status: "IDLE",
  });

  const [documentData, setDocumentData] = useState({});

  const resetFileInput = () => {
    let randomString = Math.random().toString(36);
    setFileInputKey(randomString);
  };

  const handleInputChange = (e) => {
    const target = e.currentTarget;
    let value;
    switch (target.type) {
      case "checkbox":
        value = target.checked;
        break;
      case "file":
        value = e.target.files[0];
        console.log("files = " + JSON.stringify(value));
        break;
      default:
        value = target.value;
        break;
    }
    const name = target.name;
    console.log(target);
    console.log(name);
    console.log(value);
    if (name === "fileName") {
      setFileName(value.name);
    }
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  const handleSubmit = async () => {
    try {
      const uploadData = new FormData();
      console.log(JSON.stringify(formData.data));
      uploadData.append("fileName", formData.data["fileName"], fileName);
      uploadData.append("userId", props.user_id);
      uploadData.append("objectType", props.collection_name);
      console.log("uploadData = " + JSON.stringify(uploadData));

      const response = await fetchSecure("/api/import", {
        method: "post",
        body: uploadData,
      });

      const data = await response.json();
      if (response.status === 200) {
        setFormData({
          data: data,
          status: "SUCCESS",
        });
        resetFileInput();

        setDocumentData(data);
        /* go and refresh the screen with the new imported elements */
        setTimeout(function () {
          props.callback();
        }, 3000);
      } else {
        setFormData({
          data: data,
          status: "ERROR",
        });
      }
    } catch (e) {
      console.log(e);
      console.log(`Failed to upload new document`);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };

  return (
    <div className='authentication-page center-form'>
      <div className='custom-container'>
        <div className='form-sec'>
          <div className='form-container w447px'>
            <Form className='headerPart'>
              <h2>Import</h2>
            </Form>
            <Form className='bodyPart'>
              <Form.Group controlId='uploadFile' className='form-group'>
                <Form.Label>Upload File For Import</Form.Label>
                {/* 12/25/2024 - Ryan changed this */}
                <Form.Control
                  key={fileInputKey}
                  type='file'
                  name='fileName'
                  placeholder='Select a file'
                  className='form-control'
                  onChange={handleInputChange}
                  accept='pdf'
                  style={{
                    height: "38px",
                  }}
                />
              </Form.Group>
              <div className='submit-btn-lists'>
                <Button
                  variant='primary'
                  type='button'
                  className='submit-btn primary-gradient-btn'
                  onClick={handleSubmit}>
                  Upload
                </Button>
                <Button
                  variant='primary'
                  type='button'
                  className='submit-btn transparent-bg-white-btn'
                  onClick={props.handleUploadFileCancel}>
                  Cancel
                </Button>
              </div>
            </Form>

            {formData.status === "SUBMITTED" && (
              <React.Fragment>
                <RowMargin />
                <Row>
                  <Col>
                    <Loading />
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {formData.status === "ERROR" && (
              <React.Fragment>
                <RowMargin />
                <Row>
                  <Col>
                    <Alert variant='danger'>
                      {formData.data && "message" in formData.data
                        ? formData.data.message
                        : "Failed to upload file, please try again"}
                    </Alert>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {formData.status === "SUCCESS" && (
              <React.Fragment>
                <RowMargin />
                <Row>
                  <Col>
                    <Alert variant='success'>
                      {formData.data && "message" in formData.data
                        ? formData.data.message
                        : "Successfully uploaded file!"}
                    </Alert>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {documentData && Object.keys(documentData).length > 0 && (
              <React.Fragment>
                <RowMargin />
                <Row>
                  <Col></Col>
                </Row>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
