import React, { useContext } from "react";

//Components
import NotLoggedInMessage from "./NotLoggedInMessage";
import Footer from "./Footer";
import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";
import { Box } from "@mui/material";
import TickPlacementBars from "./BarChartComponent";

export default function UserAdminStatistics(props) {
  const { count, countByMonth, totalMessageCount } = props;
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  return (
    <React.StrictMode>
      <Box ref={containerRef} className='table-listing-page-wrapper'>
        {props.user && props.user.length > 0 && (
          <React.Fragment>
            <h4>Total Log Messages: {count}</h4>
            <h4>Total Message Count: {totalMessageCount}</h4>
            <h4>Message Limit: {props.userDeep.maxMessageCount}</h4>
            <div>
              <TickPlacementBars
                data={countByMonth}
                _key='month_name'
                config={[
                  { dataKey: "num_requests", label: "Requests Per Month" },
                ]}
              />
            </div>
          </React.Fragment>
        )}
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
      </Box>
    </React.StrictMode>
  );
}
/* OLD WAY Jan 24, 2025
              <TickPlacementBars data={countByMonth} _key="month_name" value="num_requests" label="Requests Per Month"/>
*/
