import React, { useState } from "react";

// import RowMargin from "./RowMargin";
// import GlobalContext from "../GlobalContext";

const UserAdminSidebar = (props) => {
  const [activeMenu, setActiveMenu] = useState("Billing");
  // const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  // const activeStyle = superAppStyle.sideBarActive;
  // const inactiveStyle = superAppStyle.sideBarInactive;

  return (
    <>
      <ul className='menu-lists'>
        <li className='menu-item'>
          <button
            className={activeMenu === "Billing" && "active"}
            onClick={(e) => {
              setActiveMenu("Billing");
              props.callback("Billing");
            }}>
            Billing
          </button>
        </li>
        <li className='menu-item'>
          <button
            className={activeMenu === "Statistics" && "active"}
            onClick={(e) => {
              setActiveMenu("Statistics");
              props.callback("Statistics");
            }}>
            Statistics
          </button>
        </li>
        <li className='menu-item'>
          <button
            className={activeMenu === "Test Plans" && "active"}
            onClick={(e) => {
              setActiveMenu("Test Plans");
              props.callback("Test Plans");
            }}>
            Test Plans
          </button>
        </li>
        <li className='menu-item'>
          <button
            className={activeMenu === "System Config" && "active"}
            onClick={(e) => {
              setActiveMenu("System Config");
              props.callback("System Config");
            }}>
            System Config
          </button>
        </li>
        <li className='menu-item'>
          <button
            className={activeMenu === "Failed Messages" && "active"}
            onClick={(e) => {
              setActiveMenu("Failed Messages");
              props.callback("Failed Messages");
            }}>
            Failed Messages
          </button>
        </li>
      </ul>
    </>
  );
};

export default UserAdminSidebar;
