import Cookies from "js-cookie";
import { createClient, OAuthStrategy } from "@wix/sdk";
import { plans } from "@wix/pricing-plans";

export default async function GetPlans() {

const myWixClient = createClient({
  modules: { plans },
  auth: OAuthStrategy({
    clientId: `498e3f0a-62b1-43ee-8a8d-1ff072217b5b`,
    tokens: JSON.parse(Cookies.get("session") || null),
  }),
});
    const response = await plans.listPublicPlans({});
    console.log(JSON.stringify(response));
    const planList = await myWixClient.plans.queryPublicPlans().find();
    console.log(planList);
    return planList; 
}

