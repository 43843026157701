import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";

//Components
import RowMargin from "./RowMargin";
import SuperAppButton from "./SuperAppButton";
import NotLoggedInMessage from "./NotLoggedInMessage";
import Footer from "./Footer";

import { fetchSecure } from "./superappobject/utils";

export default function Billing(props) {
  const stripeSessionId = props.userDeep.stripeSessionId;
  const stripeCustomerId = props.userDeep.stripeCustomerId;
  const [wixToken] = useState(localStorage.getItem("wixToken")); // KAD Dec 26, 2024 ...need logic to show different billing information based on Wix or Firebase

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {}
    loadComponent();
  }, []);

  const cancelService = async (_ev) => {
    _ev.preventDefault();
    _ev.stopPropagation();
    try {
      const res = await fetchSecure("/api/cancelSubscription", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify({ stripeCustomerId: stripeCustomerId, userDeep: props.userDeep }),
      });
      const finalResp = await res.json();
      //console.log(JSON.stringify(finalResp));
      window.location.href = "/";
      alert("Service Cancelled");
    } catch (e) {
      alert(
        "Something went wrong. Please contact support@genaisolutions.ai for assistance"
      );
      console.error(e);
      console.error(e.stack);
    }
  };
  const handleSubmit = async (_ev) => {
    _ev.preventDefault();
    _ev.stopPropagation();
    try {
      const res = await fetchSecure("/api/create-portal-session", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify({ session_id: stripeSessionId }),
      });
      const finalResp = await res.json();
      window.location.href = finalResp?.url;
 
    } catch (e) {
      alert(
        "Your billing code is incorrect. Please contact support@genaisolutions.ai for assistance with your billing information"
      );
      console.error(e);
      console.error(e.stack);
    }
  };

  return (
    <React.StrictMode>
      <>
        <>
          <>
            {props.user && props.user.length > 0 && (
              <>
                {wixToken && (
                  <h4>
                    Please go to Wix to review your billing or cancel your
                    service
                  </h4>
                )}
                {!stripeSessionId && wixToken === null && (
                  <h4>You are not billing anything yet</h4>
                )}
                {stripeSessionId && (
                  <>
                    <form onSubmit={handleSubmit}>
                      {/* <input
                        type="hidden"
                        id="session-id"
                        name="session_id"
                        value={stripeSessionId}
                      /> */}
                      <SuperAppButton
                        id='checkout-and-portal-button'
                        type='submit'
                        className='primary-gradient-btn'>
                        View your billing information
                      </SuperAppButton>
                      <RowMargin />
                      <SuperAppButton
                        onClick={(e) => cancelService(e)}
                        id='checkout-and-portal-button'
                        type='submit'
                        className='primary-gradient-btn'>
                        Cancel your service
                      </SuperAppButton>
                      <RowMargin />
                    </form>
                  </>
                )}
                {wixToken === null && (
                  <SuperAppButton
                    id='change-your-service'
                    className='primary-gradient-btn'>
                    <HashLink
                      className='hash-link'
                      smooth
                      to='/home#plan-table'>
                      Change your service
                    </HashLink>
                  </SuperAppButton>
                )}
              </>
            )}
            <NotLoggedInMessage {...props} />
            {props.showLoginErrorMessage && <Footer />}
          </>
        </>
      </>
    </React.StrictMode>
  );
}
