import {
  Container,
  Row,
  Col,
  Tabs,
  Tab,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  Fragment,
} from "react";
import { useRef } from "react"; /* FOR TREE COMPONENT */

import { Typeahead } from "react-bootstrap-typeahead";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";

import GlobalContext from "../GlobalContext";
import { fetchSecure } from "../utils/utils";
import { useMyContext } from "../MyContext";

import SuperAppObject from "./superappobject/SuperAppObject";
import DisplayDetails from "./superappobject/DisplayDetails";
import YesNoDialog from "./YesNoDialog";

import { withRouter, useParams } from "react-router";
//import '../pages/Home.css';
import "../index.css";

//Components
import TopMargin from "../components/TopMargin";
import RowMargin from "../components/RowMargin";

// NEW TREE STUFF
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2";
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2GroupTransition,
  TreeItem2Label,
  TreeItem2Root,
  TreeItem2Checkbox,
} from "@mui/x-tree-view/TreeItem2";
import { TreeItem2Icon } from "@mui/x-tree-view/TreeItem2Icon";
import { TreeItem2Provider } from "@mui/x-tree-view/TreeItem2Provider";

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
}));

const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
  const {
    id,
    itemId,
    label,
    disabled,
    children,
    handleDeleteTab,
    handleAddTab,
    showAddButton,
    tabName,
    showDeleteButton,
    handleReorderTabs,
    showUpButton,
    showDownButton,
    index,
    ...other
  } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const [showYesNoDialog, setShowYesNoDialog] = useState(false);

  return (
    <TreeItem2Provider itemId={itemId}>
      <TreeItem2Root {...getRootProps(other)}>
        <CustomTreeItemContent {...getContentProps()}>
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <Box sx={{ flexGrow: 1, display: "flex", gap: 1 }}>
            <Avatar
              sx={(theme) => ({
                background: theme.palette.primary.main,
                width: 24,
                height: 24,
                fontSize: "0.8rem",
              })}
            >
              {label[0]}
            </Avatar>
            <TreeItem2Label {...getLabelProps()} />
            {props.showAddButton && (
              <IconButton
                key="20"
                sx={{ padding: 0 }}
                edge="end"
                aria-label="add"
                onClick={handleAddTab}
              >
                <AddIcon style={{ color: "white" }} />
              </IconButton>
            )}
            {props.showUpButton && (
              <IconButton
                key="21"
                sx={{ padding: 0 }}
                edge="end"
                aria-label="reorder up"
                onClick={() => {
                  handleReorderTabs(index, "UP");
                }}
              >
                <NorthIcon style={{ color: "white" }} />
              </IconButton>
            )}
            {props.showDownButton && (
              <IconButton
                key="22"
                sx={{ padding: 0 }}
                edge="end"
                aria-label="reorder down"
                onClick={() => {
                  handleReorderTabs(index, "DOWN");
                }}
              >
                <SouthIcon style={{ color: "white" }} />
              </IconButton>
            )}
            {props.showDeleteButton && (
              <IconButton
                key="23"
                sx={{ padding: 0 }}
                edge="end"
                aria-label="delete"
                onClick={(e) =>
                  window.confirm("Are you sure you wish to delete this row?") &&
                  handleDeleteTab(tabName)
                }
              >
                <DeleteIcon style={{ color: "white" }} />
              </IconButton>
            )}
          </Box>
        </CustomTreeItemContent>
        {children && (
          <TreeItem2GroupTransition {...getGroupTransitionProps()} />
        )}
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

/*
function Noder({ ref, styles, data }) {
  return (
    <div ref={ref} style={styles.row}>
      <div style={styles.indent}>{data.name}</div>
    </div>
  );
}
*/

function ConfigEditor(props) {
  const { currentTab } = useParams();
  //const [ dynamicConfig, setDynamicConfig ] = useState(props.dynamicConfig || null);
  //const [ dynamicConfig, setDynamicConfig ] = useState(null);
  const myContext = useMyContext();
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const dynamicConfig = myContext["configData"];
  const setConfigData = myContext["setConfigData"];
  const [expandedItems, setExpandedItems] = React.useState([]);

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const [showTreeNode, setShowTreeNode] = useState("");

  const [formData, setFormData] = useState({
    data: { User: props.user, id: props.user },
    status: "IDLE",
  });
  /* MOVE TO NEW COMPONENT */
  const [tableName, adminSetTableName] = useState("");
  const [addTable, setAddTable] = useState(false);

  const [tabs, setTabs] = useState({
    data:
      dynamicConfig && dynamicConfig.tab_order ? dynamicConfig.tab_order : [],
    status: "IDLE",
  });
  const [fields, setFields] = useState({
    data:
      dynamicConfig &&
      dynamicConfig.app_config &&
      tableName &&
      tableName in dynamicConfig.app_config
        ? dynamicConfig.app_config[tableName].fields
        : [{ name: "1", display_name: "Kevin" }],
    status: "IDLE",
  });
  const [table, setTable] = useState({
    data:
      dynamicConfig &&
      dynamicConfig.app_config &&
      tableName &&
      tableName in dynamicConfig.app_config
        ? dynamicConfig.app_config[tableName].table
        : [{ kevin: "1" }],
    status: "IDLE",
  });
  const [header, setHeader] = useState({
    data:
      dynamicConfig &&
      dynamicConfig.app_config &&
      tableName &&
      tableName in dynamicConfig.app_config
        ? dynamicConfig.app_config[tableName]
        : {},
    status: "IDLE",
  });

  function sendChatMessage(message) {
    const webhookURL =
      "https://chat.googleapis.com/v1/spaces/AAAAkwqshsk/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=AqmkzeZCCx3pwyTqLe5xaF1QEFUXor27nbTYRrc2kJg%3D";

    const data = JSON.stringify({
      text: message,
    });

    fetch(webhookURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      body: data,
    }).then((response) => {
      //console.log(response);
    });
  }

  const handleDeleteTab = async (tabName) => {
    console.log("in handleDeleteTab()");
    if (tabName in dynamicConfig.app_config)
      delete dynamicConfig.app_config[tabName];
    for (let x = 0; x < dynamicConfig.tab_order.length; x++) {
      let obj = dynamicConfig.tab_order[x];
      if (obj.name === tabName) {
        console.log("IN HERE " + x);
        dynamicConfig.tab_order.splice(x, 1);
      }
    }
    const response = await fetchSecure("/api/saveObject/config", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(dynamicConfig),
    });
    const data = await response.json();
    if (data.success) {
      setConfigData(dynamicConfig);
    } else {
      throw Error("Failed to save data");
    }
  };

  const handleReorderRows = async (table, index, direction) => {
    console.log("in handleReorderRows() - " + table + "," + direction);
    var srcIndex = index;
    var destIndex = index;
    if (direction === "UP") destIndex--;
    if (direction === "DOWN") destIndex++;
    console.log(direction);
    console.log(srcIndex + "-" + destIndex);
    if (false) {
      var deleted = dynamicConfig.tab_order.splice(srcIndex, 1);
      dynamicConfig.tab_order.splice(destIndex, 0, deleted[0]);
    }
    if (table === "fields") {
      var deleted = dynamicConfig["app_config"][tableName]["fields"].splice(
        srcIndex,
        1
      );
      dynamicConfig["app_config"][tableName]["fields"].splice(
        destIndex,
        0,
        deleted[0]
      );
      const fields = dynamicConfig.app_config[tableName]["fields"];
      for (let x = 0; x < fields.length; x++) {
        fields[x]["id"] = x;
        fields[x]["max_rows"] = fields.length;
      }
      dynamicConfig.app_config[tableName]["fields"] = fields;
      console.log("fields = " + JSON.stringify(fields));
    }
    if (table === "table") {
      var deleted = dynamicConfig["app_config"][tableName]["table"].splice(
        srcIndex,
        1
      );
      dynamicConfig["app_config"][tableName]["table"].splice(
        destIndex,
        0,
        deleted[0]
      );
      const table = dynamicConfig.app_config[tableName]["table"];
      for (let x = 0; x < table.length; x++) {
        table[x]["id"] = x;
        table[x]["max_rows"] = table.length;
      }
      dynamicConfig.app_config[tableName]["table"] = table;
    }

    const response = await fetchSecure("/api/saveObject/config", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(dynamicConfig),
    });
    const data = await response.json();
    if (data.success) {
      setConfigData(dynamicConfig);
      console.log("TABLE = " + table);
      if (table === "fields") {
        console.log(
          "fields2 = " +
            JSON.stringify(dynamicConfig["app_config"][tableName]["fields"])
        );
        setFields({
          data: dynamicConfig["app_config"][tableName]["fields"],
          status: "IDLE",
        });
      }
      if (table === "table") {
        setTable({
          data: dynamicConfig["app_config"][tableName]["table"],
          status: "IDLE",
        });
      }
    } else {
      throw Error("Failed to save data");
    }
  };
  const handleReorderTabs = async (index, direction) => {
    console.log("in handleReorderTabs()");
    var srcIndex = index;
    var destIndex = index;
    if (direction === "UP") destIndex--;
    if (direction === "DOWN") destIndex++;
    var deleted = dynamicConfig.tab_order.splice(srcIndex, 1);
    dynamicConfig.tab_order.splice(destIndex, 0, deleted[0]);

    const response = await fetchSecure("/api/saveObject/config", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(dynamicConfig),
    });
    const data = await response.json();
    if (data.success) {
      setConfigData(dynamicConfig);
    } else {
      throw Error("Failed to save data");
    }
  };

  const tableTemplate = {
    fields: [
      {
        required: false,
        input_type: "text",
        name: "field11",
        display_name: "Field_One",
      },
    ],
    buttonName: "Save",
    otherTableOptions: {
      paginationPerPage: 20,
    },
    security: {
      delete: "CreatedBy",
      view: "AllUsers",
      create: "AllUsers",
      edit: "AllUsers",
    },
    name: "Test Table",
    tableName: "test_table",
    flags: "",
    collection: "test_table",
    exportFields: ["field1", "field3"],
    table: [
      {
        sortable: true,
        minWidth: "20%",
        name: "Field1",
      },
      {
        sortable: true,
        name: "Field2",
        minWidth: "25%",
      },
    ],
    showLikes: false,
    filterField: "field1",
    style: {
      "font-size": "11px",
    },
  };
  const handleSaveFields = async (event, subArray, form) => {
    var result = dynamicConfig;
    console.log(
      "in handleSaveFields " +
        JSON.stringify(form.data) +
        "-" +
        subArray +
        "-" +
        tableName
    );
    //result["app_config"][tableName][subArray] = result["app_config"][tableName][subArray].push(form.data);
    if (Array.isArray(form)) {
      result["app_config"][tableName][subArray] = form;
    } else {
      if ("id" in form.data) {
        const id = form.data["id"];
        for (
          let x = 0;
          x < result["app_config"][tableName][subArray].length;
          x++
        ) {
          if (result["app_config"][tableName][subArray][x]["id"] === id) {
            result["app_config"][tableName][subArray][x] = form.data;
          }
        }
      } else {
        /* must be a new one to add on the end */
        result["app_config"][tableName][subArray].push(form.data);
      }
    }
    console.log("in handleSaveFields " + JSON.stringify(result));

    const response = await fetchSecure("/api/saveObject/config", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(result),
    });
    const data = await response.json();
    if (data.success) {
      setConfigData(dynamicConfig);
    } else {
      throw Error("Failed to save data");
    }
  };
  const handleSaveModule = async (event, subArray, form) => {
    var result = dynamicConfig;
    header.data["security"] = {
      create: "AllUsers",
      edit: "AllUsers",
      delete: "CreatedBy",
      view: "AllUsers",
    };
    const tableName = form.data.tableName;
    header.data["style"] = {};
    header.data["tableName"] = tableName;
    header.data["name"] = form.data.displayName;

    console.log(JSON.stringify(form.data));
    console.log("initial tabs " + JSON.stringify(tabs.data));
    result["tab_order"] = tabs.data.concat(form.data);
    console.log("tabs after " + JSON.stringify(result["tab_order"]));
    result["app_config"][tableName] = {};
    result["app_config"][tableName] = tableTemplate;
    /*
       result["app_config"][tableName] = header.data;
       if (fields.data.length > 0)
          result["app_config"][tableName]["fields"] = fields.data;
       if (table.data.length > 0)
          result["app_config"][tableName]["table"] = table.data;
*/

    try {
      console.log(" IN HERE CONFIG");
      console.log(" IN HERE CONFIG 3 :" + JSON.stringify(result, null, " "));

      var bodyData = result;
      const response = await fetchSecure("/api/saveObject/config", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(bodyData),
      });
      const data = await response.json();
      if (data.success) {
        setConfigData(result);
      } else {
        throw Error("Failed to save new tab");
      }
    } catch (e) {
      console.log(`Failed to save new tab: ${form.data}`);
    }
  };
  const handleSubmitConfig2 = async (event, collection, formData) => {
    console.log("collection = " + collection);
    console.log("Formdata = " + JSON.stringify(formData));
    var result = dynamicConfig;
    formData.data["security"] = {
      create: "AllUsers",
      edit: "AllUsers",
      delete: "CreatedBy",
      view: "AllUsers",
    };
    formData.data["style"] = {};
    result["tab_order"] = tabs.data;
    result["app_config"][tableName] = formData.data;
    result["app_config"][tableName]["fields"] = fields.data;
    result["app_config"][tableName]["table"] = table.data;
    console.log("result = " + JSON.stringify(result));
    console.log(" IN HERE CONFIG 3 :" + JSON.stringify(result));
    var bodyData = result;
    const response = await fetchSecure("/api/saveObject/config", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(bodyData),
    });
    const data = await response.json();
    if (data.success) {
      setConfigData(result);
      setHeader({ data: result["app_config"][tableName], status: "IDLE" });
    } else {
      throw Error("Failed to save header");
    }
  };

  function getConfig() {
    var configObj = {};
    try {
      configObj["layout"] = {
        tableName: "layout",
        name: "Layout",
        buttonName: "Save",
        fields: [
          {
            placeholder: "",
            display_name: "XS",
            name: "xs",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "MD",
            name: "md",
            input_type: "text",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Table Name",
            name: "table_name",
            input_type: "text",
            default: "",
            required: true,
          },
        ],
        table: [
          { selector: "table_name", name: "Table Name", sortable: true },
          { selector: "xs", name: "XS", sortable: true },
          { selector: "md", name: "MD", sortable: true },
        ],
        filterField: "table_name",
        exportFields: ["table_name"],
        otherTableOptions: { paginationPerPage: 20 },
      };
      configObj["users"] = {
        tableName: "users",
        name: "Users",
        collection: "users",
        buttonName: "Save",
        defaultSort: { selector: 1, asc: false },
        security: {
          create: "",
          edit: "",
          delete: "",
          view: "AllUsers"
        },
        flags: "",
        fields: [
          {
            placeholder: "",
            display_name: "User",
            name: "user",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Roles",
            name: "roles",
            input_type: "text",
            default: "",
            required: true,
          },
        ],
        table: [
          {
            selector: (row) => row.userId,
            name: "User",
            sortable: true,
            minWidth: "45%",
          },
          {
            selector: (row) => row.roles,
            name: "Roles",
            sortable: true,
            minWidth: "45%",
          },
        ],
        filterField: "userId",
        exportFields: ["userId"],
        otherTableOptions: { paginationPerPage: 20 },
      };
      configObj["plans"] = {
        tableName: "plans",
        name: "Plans",
        collection: "plans",
        buttonName: "Save",
        security: {
          create: "",
          edit: "",
          delete: "",
          view: "",
        },
        fields: [
          {
            placeholder: "",
            display_name: "Plan Name",
            name: "planName",
            input_type: "text",
            default: "",
            required: true,
          },
        ],
        table: [
          {
            selector: (row) => row.planName,
            name: "PlanName",
            sortable: true,
            minWidth: "45%",
          },
        ],
        filterField: "planName",
        exportFields: ["planName"],
        otherTableOptions: { paginationPerPage: 20 },
      };
      configObj["tabs"] = {
        tableName: "tabs",
        name: "Tabs",
        collection: "tabs",
        buttonName: "Save",
        security: {
          create: "AllUsers",
          edit: "AllUsers",
          delete: "AllUsers",
          view: "AllUsers",
        },
        fields: [
          {
            placeholder: "",
            display_name: "Table Name",
            name: "tableName",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Module Display Name",
            name: "displayName",
            input_type: "text",
            default: "",
            required: true,
          },
        ],
        table: [{ selector: "name", name: "Tab Name", sortable: true }],
        filterField: "name",
        exportFields: ["name"],
        otherTableOptions: { paginationPerPage: 20 },
      };
      /*
   placeholder: "",
                  display_name: "Model Type",
                  name: "modelType",
                  input_type: "dropdown",
                  values: ["Gemini", "Open AI"],
                  default: "",
                  help: "We recommend you start with Gemini first. If you can't get the results you desire you can try Open AI",
                  conditional: "agentType == FAQ",
                  required: true
*/
      configObj["fields"] = {
        tableName: "fields",
        name: "Fields",
        collection: "fields",
        buttonName: "Save",
        security: {
          create: "AllUsers",
          edit: "AllUsers",
          delete: "AllUsers",
          view: "AllUsers",
        },
        fields: [
          {
            placeholder: "",
            display_name: "Name",
            name: "name",
            input_type: "text",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Display Name",
            name: "display_name",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Placeholder",
            name: "placeholder",
            input_type: "text",
            default: "",
            flag: "autofocus",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Input Type",
            name: "input_type",
            input_type: "dropdown",
            values: [
              "text",
              "textarea",
              "dropdown",
              "checkbox",
              "list",
              "display",
              "color",
              "date",
            ],
            required: true,
          },
          {
            placeholder: "",
            display_name: "Help",
            name: "help",
            input_type: "text",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Values",
            name: "values",
            input_type: "text",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Required",
            name: "required",
            input_type: "checkbox",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Flags",
            name: "flags",
            input_type: "text",
            flags: "stringToJSON",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Conditional",
            name: "conditional",
            input_type: "text",
            flags: "stringToJSON",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Default",
            name: "Default",
            input_type: "text",
            flags: "stringToJSON",
            required: false,
          },
        ],
        table: [
          { selector: (row) => row.name, name: "Name", sortable: true },
          {
            selector: (row) => row.display_name,
            name: "Display Name",
            sortable: true,
          },
          {
            selector: (row) => row.input_type,
            name: "Input Type",
            sortable: true,
          },
          {
            cell: (row) => (
              <>
                {row.id > 0 && (
                  <button style={{ padding: "0", border: "none", background: "none" }}
                    onClick={() => handleReorderRows("fields", row.id, "UP")}
                    id={row.ID}
                  >
                    <NorthIcon />
                  </button>
                )}{" "}
                {row.id < row.max_rows - 1 && (
                  <button style={{ padding: "0", border: "none", background: "none" }}
                    onClick={() => handleReorderRows("fields", row.id, "DOWN")}
                    id={row.ID}
                  >
                    <SouthIcon />
                  </button>
                )}
              </>
            ),
          },
        ],
        filterField: "name",
        exportFields: ["name", "display_name", "input_type", "values"],
        otherTableOptions: { paginationPerPage: 20 },
      };
      configObj["table"] = {
        tableName: "table",
        name: "Table",
        collection: "table",
        buttonName: "Save",
        security: {
          create: "AllUsers",
          edit: "AllUsers",
          delete: "AllUsers",
          view: "AllUsers",
        },
        fields: [
          {
            placeholder: "",
            display_name: "Name",
            name: "name",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Display Name",
            name: "display_name",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Sortable",
            name: "sortable",
            input_type: "checkbox",
            required: false,
          },
        ],
        table: [
          { selector: (row) => row.name, name: "Name", sortable: true },
          {
            selector: (row) => row.display_name,
            name: "Display Name",
            sortable: true,
          },
          {
            selector: (row) => row.sortable,
            name: "Sortable",
            sortable: true,
            cell: (row) => (row.sortable ? "Yes" : "No"),
          },
          {
            cell: (row) => (
              <>
                {row.id > 0 && (
                  <button
                    onClick={() => handleReorderRows("table", row.id, "UP")}
                    id={row.ID}
                  >
                    <NorthIcon />
                  </button>
                )}{" "}
                {row.id < row.max_rows - 1 && (
                  <button
                    onClick={() => handleReorderRows("table", row.id, "DOWN")}
                    id={row.ID}
                  >
                    <SouthIcon />
                  </button>
                )}
              </>
            ),
          },
        ],
        filterField: "name",
        exportFields: ["name", "display_name", "sortable"],
        otherTableOptions: { paginationPerPage: 20 },
      };
      configObj["feedback"] = {
        tableName: "feedback",
        name: "Feedback",
        buttonName: "Save",
        fields: [
          {
            placeholder: "",
            display_name: "User",
            name: "User",
            input_type: "text",
            default: "{USER}",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Request Type",
            name: "RequestType",
            input_type: "dropdown",
            values: [
              "Provide Feedback",
              "Report a Bug",
              "Add Community",
              "Add Interest",
              "Add Business Interest",
            ],
            required: true,
          },
          {
            placeholder: "DataEntity",
            display_name: "Data Entity",
            name: "DataEntity",
            input_type: "textarea",
            required: true,
          },
          {
            placeholder: "Creation Date",
            display_name: "Creation Date",
            name: "CreationDate",
            input_type: "display",
            required: false,
          },
          {
            placeholder: "Update Date",
            display_name: "Update Date",
            name: "UpdateDate",
            input_type: "display",
            required: false,
          },
        ],
        table: [
          { selector: "User", name: "User", sortable: true },
          { selector: "RequestType", name: "Request Type", sortable: true },
          { selector: "DataEntity", name: "Data Entity", sortable: true },
          {
            selector: "CreationDate",
            name: "Creation Date",
            sortable: true,
            sortOrder: "desc",
            cell: (row) =>
              row.CreationDate ? row.CreationDate.substring(0, 10) : "Unknown",
          },
          {
            selector: "UpdateDate",
            name: "Update Date",
            sortable: true,
            cell: (row) =>
              row.UpdateDate ? row.UpdateDate.substring(0, 10) : "Unknown",
          },
        ],
        filterField: "user",
        exportFields: ["user", "RequestType", "DataEntity", "CreationDate"],
        otherTableOptions: { paginationPerPage: 20 },
      };
    } catch (e) {
      console.log("Failed to configure: " + e);
      console.log(e.stack);
    }
    return configObj;
  }

  const config = getConfig();

  useEffect(() => {
    console.log("Header = " + JSON.stringify(header));
  }, [header.data]);

  /*
useEffect(() => {
    async function loadConfig() {
      try {
        if (dynamicConfig === null) {
          const response = await fetchSecure("/api/getObjects/config");
          const data = await response.json();
          console.log("#######Config = " + JSON.stringify(data[0], " ", 2));
          //setDynamicConfig(data[0]);
          setFormDataConfig({
            data: data[0],
            status: "SUBMITTED",
          });
        }
      } catch (e) {
        console.log("Failed to load config!");
        console.trace(e.stack);
      }
    }
    loadConfig();
}, [])
*/

  useEffect(() => {
    if (
      (tableName && tableName.length === 0) ||
      typeof dynamicConfig === "undefined" ||
      dynamicConfig === null
    )
      return;

    console.log(JSON.stringify(dynamicConfig));

    setTabs({
      data:
        dynamicConfig && dynamicConfig.tab_order ? dynamicConfig.tab_order : [],
      status: "IDLE",
    });
    setHeader({
      data:
        dynamicConfig &&
        dynamicConfig.app_config &&
        tableName &&
        tableName in dynamicConfig.app_config
          ? dynamicConfig.app_config[tableName]
          : {},
      status: "IDLE",
    });
    if (
      dynamicConfig &&
      tableName &&
      tableName in dynamicConfig.app_config &&
      "fields" in dynamicConfig.app_config[tableName]
    ) {
      const fields = dynamicConfig.app_config[tableName]["fields"];
      for (let x = 0; x < fields.length; x++) {
        fields[x]["id"] = x;
        fields[x]["max_rows"] = fields.length;
      }
    }
    setFields({
      data:
        dynamicConfig &&
        tableName &&
        tableName in dynamicConfig.app_config &&
        "fields" in dynamicConfig.app_config[tableName]
          ? dynamicConfig.app_config[tableName]["fields"]
          : [],
      status: "IDLE",
    });
    if (
      dynamicConfig &&
      tableName &&
      tableName in dynamicConfig.app_config &&
      "table" in dynamicConfig.app_config[tableName]
    ) {
      const table = dynamicConfig.app_config[tableName]["table"];
      for (let x = 0; x < table.length; x++) {
        table[x]["id"] = x;
        table[x]["max_rows"] = table.length;
      }
    }
    setTable({
      data:
        dynamicConfig &&
        tableName &&
        tableName in dynamicConfig.app_config &&
        "table" in dynamicConfig.app_config[tableName]
          ? { ...dynamicConfig.app_config[tableName] }.table
          : [],
      status: "IDLE",
    });

    setHeader({
      data:
        dynamicConfig && tableName && tableName in dynamicConfig.app_config
          ? dynamicConfig.app_config[tableName]
          : {},
      status: "IDLE",
    });
  }, [tableName]);

  /* 
"filterField": "agentName",
            "exportFields": ["id", "agentName", "agentDescription", "agentType", "prompt", "faqs", "firstPhrase", "webhookURL", "dialogflowAgentId", "dialogflowProjectId", "dialogflowLocation", "helpText", "headerColor", "inputHelperText", "animationGIF", "animationCaption", "creationDate", "updateDate"],
            "otherTableOptions": { "paginationPerPage": 20 },
*/
  const headerConfig = {
    collection: "header",
    name: "Header",
    showLikes: false,
    buttonName: "Save",
    defaultSort: { selector: 5, asc: false },
    security: {
      create: "AllUsers",
      edit: "AllUsers",
      delete: "None",
      view: "AllUsers",
    },
    flags: "",
    fields: [
      {
        placeholder: "",
        display_name: "Table Name",
        name: "tableName",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Display Name",
        name: "name",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Display Type",
        name: "displayType",
        input_type: "dropdown",
        values: ["TABLE", "BASIC_TABLE"],
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Flags",
        name: "flags",
        input_type: "text",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Button Name",
        name: "buttonName",
        input_type: "text",
        default: "Save",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Show Likes",
        name: "showLikes",
        input_type: "checkbox",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Filter Field",
        name: "filterField",
        input_type: "input",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Export Fields",
        name: "exportFields",
        input_type: "input",
        default: "",
        required: false,
      },
    ],
  };

  function getUniqueId() {
    return Math.random() + "";
  }
  return (
    <React.StrictMode>
      <Container fluid>
        {true && (
          <React.Fragment>
            <RowMargin />
            <RowMargin />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h2 style={{ color: "white" }}>Admin</h2>
                <Box sx={{ minHeight: 200, minWidth: 300 }}>
                  <SimpleTreeView
                    style={{ color: "white" }}
                    itemId="100"
                    aria-label="Admin Console"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    expandedItems={expandedItems}
                    onExpandedItemsChange={handleExpandedItemsChange}
                    sx={{
                      height: 400,
                      flexGrow: 1,
                      maxWidth: 275,
                      overflowY: "auto",
                    }}
                  >
                    <CustomTreeItem
                      style={{ color: "white" }}
                      itemId="1"
                      label="Modules"
                      showAddButton="true"
                      handleAddTab={() => {
                        setShowTreeNode("Add New Table");
                      }}
                    >
                      {dynamicConfig &&
                        dynamicConfig.tab_order &&
                        dynamicConfig.tab_order.map((key, index) => {
                          var opts = {};

                          if (index > 0) {
                            opts["showUpButton"] = "true";
                          }
                          if (index < dynamicConfig.tab_order.length - 1) {
                            opts["showDownButton"] = "true";
                          }
                          return (
                            <CustomTreeItem
                              style={{ color: "white" }}
                              index={index}
                              itemId="2"
                              label={key.displayName}
                              tabName={key.name}
                              handleDeleteTab={handleDeleteTab}
                              showDeleteButton="true"
                              {...opts}
                              handleReorderTabs={handleReorderTabs}
                              onClick={() => adminSetTableName(key.name)}
                            >
                              <CustomTreeItem
                                style={{ color: "white" }}
                                itemId="3"
                                label="Header"
                                onClick={() => {
                                  adminSetTableName(key.name);
                                  setShowTreeNode("Header");
                                }}
                              />
                              <CustomTreeItem
                                style={{ color: "white" }}
                                itemId="4"
                                label="Fields"
                                onClick={() => {
                                  console.log(
                                    "Clicked Fields, table = " +
                                      key.name +
                                      "," +
                                      JSON.stringify(fields.data)
                                  );
                                  adminSetTableName(key.name);
                                  setShowTreeNode("Fields");
                                }}
                              />
                              <CustomTreeItem
                                style={{ color: "white" }}
                                itemId="5"
                                label="Table View"
                                onClick={() => {
                                  adminSetTableName(key.name);
                                  setShowTreeNode("Table View");
                                }}
                              />
                            </CustomTreeItem>
                          );
                        })}
                    </CustomTreeItem>
                    <TreeItem2
                      style={{ color: "white" }}
                      itemId="1000"
                      label="Users"
                      onClick={() => setShowTreeNode("Users")}
                    />
                    <TreeItem2
                      style={{ color: "white" }}
                      itemId="1001"
                      label="Plans"
                      onClick={() => setShowTreeNode("Plans")}
                    />
                    <TreeItem2
                      style={{ color: "white" }}
                      itemId="1002"
                      label="Feedback"
                      onClick={() => setShowTreeNode("Feedback")}
                    />
                  </SimpleTreeView>
                </Box>
              </div>
              <div style={{ width: "100%" }}>
                {showTreeNode === "Add New Table" && (
                  <div>
                    <h2 style={{ color: "white" }}>Create Module</h2>
                    <div className="table-listing-page-wrapper">
                      <SuperAppObject
                        depth={1}
                        style={superAppStyle}
                        title="Create Module"
                        config={config["tabs"]}
                        user={props.user}
                        userDeep={props.userDeep}
                        display_state="CREATE_EDIT"
                        actionType="CREATE"
                        dontPersistData="true"
                        pagination="false"
                        submit={handleSaveModule}
                        input_data={[]}
                        setFormData={setFormData}
                      />
                    </div>
                  </div>
                )}

                {showTreeNode === "Header" && (
                  <div>
                    <h2 style={{ color: "white" }}>
                      {header &&
                        header.data &&
                        "name" in header.data &&
                        header.data["name"]}
                    </h2>
                    <div className="table-listing-page-wrapper">
                      <SuperAppObject
                        depth={1}
                        style={superAppStyle}
                        title="Header"
                        config={headerConfig}
                        user={props.user}
                        userDeep={props.userDeep}
                        display_state="DISPLAY"
                        this_object={header.data}
                        dontPersistData="true"
                        pagination="false"
                        submit={handleSubmitConfig2}
                        input_data={[]}
                      />
                    </div>
                  </div>
                )}
                {showTreeNode === "Fields" && (
                  <div className="table-listing-page-wrapper">
                    <SuperAppObject
                      depth={1}
                      style={superAppStyle}
                      title="Fields"
                      config={config["fields"]}
                      user={props.user}
                      userDeep={props.userDeep}
                      collection_name="fields"
                      display_state="TABLE"
                      input_data={fields.data}
                      dontPersistData="true"
                      pagination="false"
                      submit={handleSaveFields}
                      canEdit="true"
                    />
                  </div>
                )}
                {showTreeNode === "Table View" && (
                  <div className="table-listing-page-wrapper">
                    <SuperAppObject
                      depth={1}
                      style={superAppStyle}
                      title="Table View"
                      config={config["table"]}
                      user={props.user}
                      userDeep={props.userDeep}
                      collection_name="table"
                      display_state="TABLE"
                      input_data={table.data}
                      dontPersistData="true"
                      pagination="false"
                      submit={handleSaveFields}
                      canEdit="true"
                    />
                  </div>
                )}
                {showTreeNode === "Feedback" && (
                  <div className="table-listing-page-wrapper">
                    <SuperAppObject
                      depth={1}
                      style={superAppStyle}
                      title="Feedback"
                      config={config["feedback"]}
                      user={props.user}
                      userDeep={props.userDeep}
                      collection_name="feedback"
                      display_state="TABLE"
                    />
                  </div>
                )}
                {showTreeNode === "Users" && (
                  <div className="table-listing-page-wrapper">
                    <SuperAppObject
                      depth={1}
                      style={superAppStyle}
                      title="Users"
                      config={config["users"]}
                      user={props.user}
                      userDeep={props.userDeep}
                      collection_name="users"
                      display_state="TABLE"
                    />
                  </div>
                )}
                {showTreeNode === "Plans" && (
                  <div className="table-listing-page-wrapper">
                    <SuperAppObject
                      depth={1}
                      style={superAppStyle}
                      title="Plans"
                      config={config["plans"]}
                      user={props.user}
                      userDeep={props.userDeep}
                      collection_name="plans"
                      display_state="TABLE"
                    />
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </Container>
    </React.StrictMode>
  );
}
export default withRouter(ConfigEditor);
