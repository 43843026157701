import React, { useContext } from "react";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

const FailedMessages = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {};
  config["failed_messages"] = {
    collection: "messages",
    //defaultSort: "kevin",
    name: "Messages",
    defaultSort: { selector: 5, asc: false },
    showLikes: false,
    buttonName: "Save",
    security: {
      create: "AllUsers",
      edit: "AllUsers",
      delete: "None",
      view: "AllUsers",
    },
    flags: "",
    fields: [
      {
        placeholder: "",
        display_name: "FAQ ID",
        name: "id",
        input_type: "display",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Request",
        name: "request",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Response",
        name: "response",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Date",
        name: "log_timestamp",
        input_type: "text",
        default: "",
        required: true,
      },
    ],
    table: [
      {
        selector: (row) => row.user_id,
        name: "User ID",
        sortable: true,
        wrap: true,
        minWidth: "10%",
      },
      {
        selector: (row) => row.agent_id,
        name: "Agent ID",
        sortable: true,
        wrap: true,
        minWidth: "10%",
      },
      {
        selector: (row) => row.request,
        name: "Request",
        sortable: true,
        wrap: true,
        minWidth: "10%",
      },
      {
        selector: (row) => row.response,
        name: "Response",
        //wrap: true,
        sortable: true,
        wrap: true,
        minWidth: "50%",
      },
      {
        selector: (row) => row.log_timestamp,
        name: "Creation Date",
        hide: "md",
        sortable: true,
        sortOrder: "desc",
        minWidth: "10%",
        sortFunction: (rowB, rowA) => {
          // Perform ASCII comparison
          if (typeof rowB.log_timestamp === "undefined") return true;
          return rowB.log_timestamp.localeCompare(rowA.log_timestamp);
        },
        cell: (row) =>
          row.log_timestamp ? row.log_timestamp.substring(0, 10) : "Unknown",
      },
    ],
    filterField: "response",
    exportFields: ["request", "response"],
    otherTableOptions: { paginationPerPage: 10 },
  };
  return (
    <div className="table-listing-page-wrapper">
      <SuperAppObject
        depth={1}
        style={superAppStyle}
        config={config["failed_messages"]}
        user={props.user}
        userDeep={props.userDeep}
        collection_name="XXXX"
        input_data={props.failedMessages}
        display_state="BASIC_TABLE"
        canEdit="false"
      />
    </div>
  );
};

export default FailedMessages;
