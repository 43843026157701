import React, { useState, useEffect, useContext } from "react";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

/* NEW IMAGE STUFF */
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
/* NEW IMAGE STUFF */
import { fetchSecure } from "../utils/utils";

import "../index.css";
import SuperAppButton from "./SuperAppButton";

const AddTests = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  const addTests = async (rows, testPlanId) => {
    console.log(JSON.stringify(rows));
    console.log(testPlanId);
    const body = { testPlanId: testPlanId, tests: rows, userId: props.user };
    const response = await fetchSecure("/api/addAgentTests", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(body),
    });
    const data = await response.json();

    if (data.success) {
      alert("Tests have been added");
    }
  };

  useEffect(() => {
    console.log("IN HERE AddTests useEffect - ");
    async function initialize() {}
    initialize();
  }, []);

  return (
    <div>
      <SuperAppButton
        className='primary-gradient-btn'
        onClick={(e) =>
          window.confirm("Are you sure you wish to add these tests?") &&
          addTests(props.selectedRows, props.data[props._key])
        }>
        {props.label}
      </SuperAppButton>
    </div>
  );
};

export default AddTests;
