import React, { useContext } from "react";

// import { Row, Col, Button } from "react-bootstrap";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

const Plans = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = useContext(GlobalContext)["planConfig"];
  /*
  const config = {};
         config["plans"] = {
            "collection": "plans",
            "name": "Plans",
            "showLikes": false,
            "defaultSort": { selector: 1, asc: true },
            "buttonName": "Save",
            "security": {
               "create": "AllUsers",
               "edit": "AllUsers",
               "delete": "AllUsers",
               "view": "AllUsers",
            },
            flags: "",
            "fields": [
               {
                  placeholder: "",
                  display_name: "Plan ID",
                  name: "id",
                  input_type: "display",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Order",
                  name: "order",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Plan Name",
                  name: "planName",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Plan Display Name",
                  name: "planDisplayName",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Max Agents",
                  name: "maxAgents",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Trial Length",
                  name: "trialLength",
                  input_type: "text",
                  conditional: "planName == trial",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Max Message Count",
                  name: "maxMessageCount",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Max File Size (MB)",
                  name: "maxFileSize",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Max FAQ Length",
                  name: "maxFAQLength",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Types of Agents",
                  name: "typeOfBots",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "",
                  display_name: "Agent Chaining",
                  name: "agentChaining",
                  input_type: "checkbox",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Supports Production Workloads",
                  name: "supportsProduction",
                  input_type: "checkbox",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Automated Agent Testing",
                  name: "automatedAgentTesting",
                  input_type: "checkbox",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Production Use",
                  name: "productionUse",
                  input_type: "checkbox",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Time Bound (30 days)",
                  name: "timeBound",
                  input_type: "checkbox",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Agent Chaining",
                  name: "agentChaining",
                  input_type: "checkbox",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Cost",
                  name: "cost",
                  input_type: "text",
                  default: "",
                  required: false
               },
               {
                  placeholder: "",
                  display_name: "Button Name",
                  name: "buttonName",
                  input_type: "text",
                  default: "",
                  required: true
               },
               {
                  placeholder: "Creation Date",
                  display_name: "Creation Date",
                  name: "creationDate",
                  input_type: "date",
                  flags: "display",
                  required: false
               },
               {
                  placeholder: "Update Date",
                  display_name: "Update Date",
                  name: "updateDate",
                  input_type: "date",
                  flags: "display",
                  required: false
               }
            ],
            "table": [
               { selector: (row) => row.order, name: "Order", sortOrder: "asc", sortable: true, minWidth: "10%" },
               { selector: (row) => row.planName, name: "Plan Name", sortable: true, minWidth: "10%" },
               { selector: (row) => row.cost, name: "Cost", sortable: true, minWidth: "10%" },
               { selector: (row) => row.maxAgents, name: "Max Agents", sortable: true, minWidth: "10%" },
               { selector: (row) => row.maxMessageCount, name: "Max Msg Cnt", sortable: true, minWidth: "10%" },
               { selector: (row) => row.maxFileSize, name: "Max File Size (MB)", sortable: true, minWidth: "10%" },
            ],
            "filterField": "planName",
            "exportFields": ["planName"],
            "otherTableOptions": { "paginationPerPage": 20 },
         }
*/
  return (
    <div className="table-listing-page-wrapper">
      <SuperAppObject
        depth={1}
        style={superAppStyle}
        config={config["plans"]}
        user={props.user}
        userDeep={props.userDeep}
        collection_name="plans"
        display_state="TABLE"
      />
    </div>
  );
};

export default Plans;
