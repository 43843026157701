import React from "react";

/* NEW MENU STUFF */
import MenuItem from "@mui/material/MenuItem";
//import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
/* NEW MENU STUFF */
/* NEW MENU STUFF */

function DrawMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuSelect = (e, value, field) => {
    e.preventDefault();

    props.setFormData({
      data: { ...props.data, [field]: value },
      status: "IDLE",
    });

    setAnchorEl(null);
  };

  const handleMenuClick = (event) => {
    if (anchorEl === null) setAnchorEl(event.currentTarget);
    else setAnchorEl(null);
  };

  return (
    <>
      <button
        type="button"
        className="small-primary-btn"
        aria-controls="simple-menu"
        aria-haspopup="true"
        style={{
          cursor: "pointer",
          whiteSpace: "nowrap",
          // overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        onClick={handleMenuClick}
      >
        (Show/Hide Samples)
      </button>

      <Menu
        keepMounted
        disableScrollLock={true}
        anchorEl={anchorEl}
        onClose={handleMenuClick}
        open={Boolean(anchorEl)}
      >
        {props.field.samples.map((sample) => {
          return (
            <MenuItem
              sx={{ whiteSpace: "normal" }}
              key={sample}
              onClick={(e) => {
                handleMenuSelect(e, sample, props.field.name);
              }}
            >
              {sample.startsWith("https://") && (
                <img alt={sample} src={sample}></img>
              )}
              {!sample.startsWith("https://") && sample}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
/* END NEW MENU STUFF */
export default DrawMenu;
