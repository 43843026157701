import React from "react";
import Subscribe from "../components/Subscribe";

const Subscription = () => {
  //Subscribe route user deep values - Sourav - 31Jan25
  const userDeepObj = sessionStorage.getItem("userDeepObj");
  const userDeepJson = JSON.parse(userDeepObj);
  return <Subscribe userDeep={userDeepJson?.userObject} />;
};

export default Subscription;
