/* https://dev.wix.com/docs/rest/business-management/payments/wix-payments-provider/transactions/introduction */

import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

//Components
import RowMargin from "../components/RowMargin";
import Footer from "../components/Footer";
import Registration from "../components/Registration";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import NewPlanTable from "../components/NewPlanTable";
import Stripe from "../components/Stripe";
// import GlobalContext from "../GlobalContext";
import { useMyContext } from "../MyContext";
import { fetchSecure } from "../utils/utils";
import "./Home.css";
import { Snackbar, Alert } from "@mui/material";
/* STRIPE STUFF */

export default function Home(props) {
  const myContext = useMyContext();
  const [screen, setScreen] = useState(props.screen || 1);
  const [currentPlanName, setCurrentPlanName] = useState(
    props.userDeep.planName
  );
  const [, /* allPlansData */ setAllPlansData] = useState([]);
  const [newPlanData, setNewPlanData] = useState([]);
  const [activeTrialCount, setActiveTrialCount] = useState(0);
  // const maxTrialsAllowed = 3;
  //const [checkOutURL, setCheckOutURL] = useState([]);
  const [stripeSessionId, setStripeSessionId] = useState("");
  const [, /* stripeCustomerId*/ setStripeCustomerId] = useState("");
  const [trialNumDaysLeft, setTrialNumDaysLeft] = useState(
    props.userDeep.trialLength || 100
  );
  const [open, setOpen] = useState(false);
  // const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [userData, setUserData] = useState({
    data: props.userDeep,
    status: "IDLE",
  });

  function extractValueFromURL(str, key) {
    var returnStr = "";
    if (typeof str.length === "undefined") return "";
    if (str.indexOf(key) >= 0) {
      returnStr = str.substring(str.indexOf(key) + key.length + 1);
      let endPosition = returnStr.indexOf("&");
      if (endPosition >= 0) returnStr = returnStr.substring(0, endPosition);
    }
    return returnStr;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      try {
        const response = await fetchSecure("/api/getPlans");
        const planDataArray = await response.json();
        setAllPlansData(planDataArray);

        const activeTrialCountResponse = await fetchSecure(
          "/api/getActiveTrialCount"
        );
        const activeTrialCount = await activeTrialCountResponse.json();
        setActiveTrialCount(activeTrialCount["activeTrialCount"]);

        //console.log("session = " + props.userDeep.setStripeSessionId)
        try {
          if (
            props.userDeep.stripeSessionId &&
            props.userDeep.stripeSessionId.length > 0
          ) {
            setStripeSessionId(props.userDeep.stripeSessionId);
          }
        } catch (e) {
          console.error(e);
        }

        setUserData({
          data: props.userDeep,
          status: "IDLE",
        });
        var d1 = new Date(props.userDeep.creationDate),
          d2 = new Date();
        setTrialNumDaysLeft(
          props.userDeep.trialLength - Math.floor((d2 - d1) / 60000 / 24 / 60)
        );
        setCurrentPlanName(props.userDeep.planName);

        const query = new URLSearchParams(window.location.search) + "";
        if (
          query.indexOf("screen") >= 0 &&
          typeof props.userDeep !== "undefined" &&
          props.userDeep &&
          "userId" in props.userDeep
        ) {
          setCurrentPlanName(extractValueFromURL(query, "plan_name"));
          setStripeSessionId(extractValueFromURL(query, "session_id"));
          //console.log("session = " + extractValueFromURL(query, "session_id"));
          await confirmBilling(
            extractValueFromURL(query, "session_id"),
            props.userDeep
          );
          setScreen(4);
        }
      } catch (e) {
        console.error(e);
        console.error(e.stack);
      }
    }
    loadComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userDeep]);

  async function prevScreen() {
    setScreen(screen - 1);
  }

  async function updateBilling() {
    console.log("in updateBilling");
    const body = {};
    body["planName"] = newPlanData["planName"];
    body["stripeCustomerId"] = props.userDeep["stripeCustomerId"];
    console.log("in updateBilling - " + newPlanData["planName"]);
    console.log("in updateBilling - " + props.userDeep["userId"]);
    const response2 = await fetchSecure("/api/updateSubscription", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(body),
    });
    const data2 = await response2.json();
    if (data2.success) {
      await confirmBilling("", props.userDeep);
    }
  }

  async function confirmBilling(sessionId, user) {
    if (user && "planName" in user) {
      user["subscribed"] = user["proposedPlan"]["subscribed"];
      user["planName"] = user["proposedPlan"]["planName"];
      user["maxAgents"] = user["proposedPlan"]["maxAgents"];
      user["maxMessageCount"] = user["proposedPlan"]["maxMessageCount"];
      user["maxFileSize"] = user["proposedPlan"]["maxFileSize"];
      user["maxFAQLength"] = user["proposedPlan"]["maxFAQLength"];
      user["subscriptionDate"] = new Date().toISOString();
      if (typeof sessionId !== "undefined" && sessionId.length > 0) {
        user["stripeSessionId"] = sessionId;
        //userData.data["stripeCustomerId"] = stripeCustomerId;
        user["paymentInfoLoaded"] = true;
      }
      user["billingLive"] = true;
    }

    if ("id" in user) {
      /* save the form */
      const response2 = await fetchSecure("/api/saveObject/users", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(user),
      });
      const data2 = await response2.json();
      if (data2.success) {
        // alert("Plan was updated to " + capitalize(user["planName"]) + "");
        setScreen(3);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(true);
    }
  }

  async function redirectToCheckOut() {
    let body = {};
    body["planName"] = newPlanData["planName"];
    body["userId"] = props.userDeep["userId"];
    //console.log(body["planName"]);
    const response3 = await fetchSecure("/api/create-checkout-session", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(body),
    });
    const data3 = await response3.json();
    setStripeCustomerId(data3["stripeCustomerId"]);
    window.location.href = data3["url"];
  }
  async function selectPlan(planName) {
    const response = await fetchSecure("/api/getPlanByName/" + planName);
    const planData = await response.json();
    setNewPlanData(planData);

    const response1 = await fetchSecure("/api/getAgentCount/" + props.user);
    const responseData = await response1.json();
    const numAgents = responseData["numAgents"];
    if (
      numAgents > planData["maxAgents"] &&
      !myContext.data["adminEmails"].includes(userData.data["userId"])
    ) {
      window.alert(
        "You cannot move to this plan as you have created too many agents.  Please either delete the agents you don't want or pick a plan that supports this many agents. If you want to keep your work, you can use the Export button on the Agents page to download a file which you could import in at a later date"
      );
      return;
    }

    /* Add the data into the form */
    userData.data["proposedPlan"] = {};
    if (planName !== "trial") {
      /* can only set subscribed if you are not in trial */
      userData.data["proposedPlan"]["subscribed"] = true;
    } else {
      /* this code should never get executed if things are working properly */
      userData.data["proposedPlan"]["subscribed"] = false;
    }
    userData.data["proposedPlan"]["planName"] = planName;
    userData.data["proposedPlan"]["maxAgents"] = planData["maxAgents"];
    userData.data["proposedPlan"]["maxMessageCount"] =
      planData["maxMessageCount"];
    userData.data["proposedPlan"]["maxFileSize"] = planData["maxFileSize"];
    userData.data["proposedPlan"]["maxFAQLength"] = planData["maxFAQLength"];

    /* save the form */
    if ("id" in userData.data) {
      const response2 = await fetchSecure("/api/saveObject/users", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(userData.data),
      });
      const data = await response2.json();
      if (data.success) {
        setScreen(screen + 1);
      } else {
        alert("Proposed Plan was not updated");
      }
    } else {
      alert("Proposed Plan was not updated");
    }
  }
  const capitalize = (str) => {
    if (typeof str === "undefined") return "Undefined";
    if (str.length < 2) return str;
    str = str.substring(0, 1).toUpperCase() + str.substring(1);
    return str;
  };
  const AgentList = () => {
    return (
      <ul>
        <li>
          FAQ Agent - an agent that will answer frequently asked questions
        </li>
        <li>
          Document QA Agent - an agent that will answer questions about uploaded
          PDF, DOCX, JSON, CSV, TXT, or XLSX documents
        </li>
        <li>
          Website QA Agent - an agent that will answer questions about scanned
          websites that you maintain
        </li>
        <li>
          Dialogflow Agent - an agent that will connect to a Dialogflow or
          Vertex AI Agent that you created in Google
        </li>
        <li>
          Webhook- an agent you can build using our SDK leverage all the front end capabiliites we offer but with your own custom logic on the backend
        </li>
        <p></p>
      </ul>
    );
  };

  function canRegisterForTrial() {
    //console.log("active Trial Count " + activeTrialCount);
    const maxTrialsAllowed = myContext["data"]["maxTrialsAllowed"]; // KAD added Dec 22, 2024
    //console.log("Max Trials Allowed = " + maxTrialsAllowed);
    if (activeTrialCount > maxTrialsAllowed) {
      return false;
    }
    return true;
  }
  function showPlanButtons() {
    const wixToken = localStorage.getItem("wixToken");
    if (typeof wixToken === "string" && wixToken.length > 0) return false;
    return true;
  }

  function buttonName() {
    if (newPlanData["planName"] === "freemium") return "Accept";
    if (
      newPlanData["planName"] !== "freemium" &&
      props.userDeep.paymentInfoLoaded === false
    )
      return "Checkout";
    if (
      newPlanData["planName"] !== "freemium" &&
      !("paymentInfoLoaded" in props.userDeep)
    )
      return "Checkout";
    if (
      newPlanData["planName"] !== "freemium" &&
      props.userDeep.paymentInfoLoaded === true
    )
      return "Confirm Plan Change";
  }

  const handleSubmit = async (_ev) => {
    _ev.preventDefault();
    _ev.stopPropagation();
    const res = await fetchSecure("/api/create-portal-session", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify({ session_id: stripeSessionId }),
    });
    const finalResp = await res.json();
    window.location.href = finalResp?.url;
  };

  //FINISH Registration - Sourav - 29Jan25
  if (props?.isFinishRegistration) {
    return (
      <>
        <div className="consent-to-bill-msg-wrapper mt">
          <h3>
            <font color="white">
              Thank you for registering. Your account is active. Feel free to
              login.
            </font>
          </h3>
        </div>
        <div className="consent-to-bill-msg-wrapper for-info-card">
          <h3>Welcome to your Agent/Chatbot Builder Trial</h3>
          <div>
            {props.userDeep.trialOver && (
              <p>
                <font color="red">
                  Your trial has expired. Tomorrow, we will archive all of your
                  agents in preparation for deletion in 30 days. If you'd like
                  to purchase a plan, you can review the list below and pick
                  one. If you need more time, please send us a note at{" "}
                  <a href="mailto:info@genaisolutions.ai">
                    info@genaisolutions.ai
                  </a>{" "}
                  to request more time. Please specify how much time you need,
                  and why you need the additional time.
                </font>
              </p>
            )}
            {!props.userDeep.trialOver && (
              <>
                <p>
                  You have {trialNumDaysLeft} days left to try out all our
                  different types of agents:
                  <RowMargin />
                  <AgentList />
                  <RowMargin />
                  During this trial, you will be allowed to create up to{" "}
                  {props.userDeep.maxAgents} agents of any type; however, you
                  will NOT be able to put these agents into any production
                  sites. The software provides a way for you to see and interact
                  with your chatbot/agent. You will also be limited by the size
                  of the data that you are able to store. Other than these
                  limitations, the agents should be fully functional. We also
                  have some demos on the main website which can aid in your
                  evaluation.
                </p>
                <p>
                  If you have done your evaluation, please use the following
                  table to evaluate our various plans. Click on the "Buy Now"
                  button for the plan you want to choose.
                </p>
              </>
            )}
            {/* <PlanTable showButtons={true} currentPlanName={currentPlanName} selectPlan={selectPlan} /> */}
          </div>
        </div>
        <NewPlanTable
          showButtons={showPlanButtons}
          currentPlanName={
            currentPlanName || props?.userDeep?.planName || "trial"
          } //Fix current plan name- Sourav - 30Jan2025
          selectPlan={selectPlan}
          isFinishRegistration={true}
        />
        <div
          className="paragraph-text"
          dangerouslySetInnerHTML={{
            __html: myContext["data"]["marketingMessage"],
          }}
        />
        {props.showLoginErrorMessage && <Footer />}
      </>
    );
  }
  function hideTrial() {
    if (
      props.userDeep.planName === "cancelled" ||
      props.userDeep.planName === "Freemium" ||
      props.userDeep.planName === "freemium"
    )
      return true;

    return false;
  }

  function hideFreemium() {
    if (
      props.userDeep.planName === "gold" ||
      props.userDeep.planName === "silver"
    )
      return true;

    return false;
  }

  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" variant="filled">
          Failed to update plan!
        </Alert>
      </Snackbar>

      {userData &&
        userData.data.planName === "unregistered" &&
        !canRegisterForTrial() &&
        screen === 1 && (
          <>
            <div className="consent-to-bill-msg-wrapper for-info-card mt">
              <h3>Gen AI Trial Limit Exceeded</h3>
              <p>
                Thank you for your interest in trying our solution.
                Unfortunately, we are temporarily out of trial licenses. If
                you'd like to try our solution, you can sign up for the Freemium
                plan right now, or if you need more access to our broader
                solutions, you can click the button below and we will alert you
                when more licenses become available
              </p>
            </div>
            {/* TODO...finish this code <SuperAppButton>Alert me when I can start my trial</SuperAppButton> */}
            <NewPlanTable
              showButtons={showPlanButtons}
              currentPlanName={currentPlanName || props?.userDeep?.planName} //Fix current plan name- Sourav - 30Jan2025
              selectPlan={selectPlan}
              hideTrial={true}
              isFinishRegistration={false}
            />

            {props.showLoginErrorMessage && <Footer />}
          </>
        )}
      {userData &&
        userData.data.planName === "unregistered" &&
        canRegisterForTrial() &&
        screen === 1 && (
          <div className="authentication-page">
            <div className="image-container">
              <img
                alt={"login-register-pic1"}
                src="/images/login-register-pic1.png"
              />
            </div>
            <div className="form-sec">
              <div className="form-container">
                <div className="headerPart">
                  <div className="center-logo">
                    <img src="/images/genAIAgentBuilder-logo.png" alt="" />
                  </div>
                </div>
                <div className="bodyPart">
                  <Registration
                    userDeep={props.userDeep}
                    setScreen={setScreen}
                  />
                </div>
              </div>
            </div>

            {/* {props.showLoginErrorMessage && <Footer />} */}
          </div>
        )}
      {props.user &&
        props.userDeep &&
        typeof userData.data["planName"] !== "undefined" &&
        props.userDeep.planName !== "trial" &&
        props.userDeep.planName !== "unregistered" &&
        screen === 1 && (
          <>
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper for-info-card mt">
                {props.userDeep.planName === "cancelled" && (
                  <>
                    <h3>
                      You subscription is currently in the Cancelled state
                    </h3>
                    <h4 style={{ textAlign: "center" }}>
                      If you want to start up your subscription again, feel free
                      to pick one of the packages below
                    </h4>
                  </>
                )}
                {props.userDeep.planName !== "cancelled" && (
                  <>
                    <h3>
                      You are currently subscribed to the{" "}
                      {capitalize(userData.data["planName"])} plan.
                    </h3>
                  </>
                )}
                {props.userDeep.planName !== "cancelled" &&
                  props.userDeep.planName !== "gold" &&
                  showPlanButtons() && (
                    <>
                      <h4 style={{ textAlign: "center" }}>
                        If you need more capacity, feel free to upgrade to one
                        of our bigger packages seen below
                      </h4>
                    </>
                  )}
                {props.userDeep.planName === "gold" && showPlanButtons() && (
                  <>
                    <h4 style={{ textAlign: "center" }}>
                      If you need more capacity, please contact sales for custom
                      pricing at sales@genaisolutions.ai.
                    </h4>
                  </>
                )}
                {!showPlanButtons() && (
                  <h4 style={{ textAlign: "center" }}>
                    If you need more capacity, feel free to go subscribe to a
                    bigger packages on the Wix website
                  </h4>
                )}

                {false && props.userDeep.planName !== "cancelled" && (
                  <div
                    className="paragraph-text"
                    dangerouslySetInnerHTML={{
                      __html: myContext["data"]["marketingMessage"],
                    }}
                  />
                )}
              </div>
              {/* props.userDeep.planName !== 'freemium' && BEFORE Jan 18 2025 */}
              {true && (
                <NewPlanTable
                  showButtons={showPlanButtons() ? true : false}
                  currentPlanName={currentPlanName || props?.userDeep?.planName} //Fix current plan name- Sourav - 30Jan2025
                  selectPlan={selectPlan}
                  hideTrial={hideTrial()}
                  hideFreemium={hideFreemium()}
                  isFinishRegistration={false}
                />
              )}
              {/* <PlanTable showButtons={true} currentPlanName={currentPlanName} selectPlan={selectPlan} /> */}
              <RowMargin />
            </div>
            {props.showLoginErrorMessage && <Footer />}
          </>
        )}
      {props.user &&
        props.userDeep &&
        props.userDeep.planName === "trial" &&
        screen === 1 && (
          <>
            <div className="consent-to-bill-msg-wrapper for-info-card mt">
              <h3>Welcome to your Agent/Chatbot Builder Trial</h3>
              <div>
                {props.userDeep.trialOver && (
                  <p>
                    <font color="red">
                      Your trial has expired. Tomorrow, we will archive all of
                      your agents in preparation for deletion in 30 days. If
                      you'd like to purchase a plan, you can review the list
                      below and pick one. If you need more time, please send us
                      a note at{" "}
                      <a href="mailto:info@genaisolutions.ai">
                        info@genaisolutions.ai
                      </a>{" "}
                      to request more time. Please specify how much time you
                      need, and why you need the additional time.
                    </font>
                  </p>
                )}
                {!props.userDeep.trialOver && (
                  <>
                    <p>
                      You have {trialNumDaysLeft} days left to try out all our
                      different types of agents:
                      <RowMargin />
                      <AgentList />
                      <RowMargin />
                      During this trial, you will be allowed to create up to{" "}
                      {props.userDeep.maxAgents} agents of any type; however,
                      you will NOT be able to put these agents into any
                      production sites. The software provides a way for you to
                      see and interact with your chatbot/agent. You will also be
                      limited by the size of the data that you are able to
                      store. Other than these limitations, the agents should be
                      fully functional. We also have some demos on the main
                      website which can aid in your evaluation.
                    </p>
                    <p>
                      If you have done your evaluation, please use the following
                      table to evaluate our various plans. Click on the "Buy
                      Now" button for the plan you want to choose.
                    </p>
                  </>
                )}
                {/* <PlanTable showButtons={true} currentPlanName={currentPlanName} selectPlan={selectPlan} /> */}
              </div>
            </div>
            <NewPlanTable
              showButtons={showPlanButtons}
              currentPlanName={currentPlanName || props?.userDeep?.planName} //Fix current plan name- Sourav - 30Jan2025
              selectPlan={selectPlan}
              isFinishRegistration={false}
            />
            <div
              className="paragraph-text"
              dangerouslySetInnerHTML={{
                __html: myContext["data"]["marketingMessage"],
              }}
            />
            {props.showLoginErrorMessage && <Footer />}
          </>
        )}
      {props.user && screen === 2 && (
        <>
          <div className="consent-to-bill-msg-sec">
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper">
                <h1>Consent to Bill </h1>
                <h4>
                  {" "}
                  You have selected the{" "}
                  <strong>{capitalize(newPlanData["planName"])}</strong> plan.
                  {"  "}
                  {newPlanData["planName"] === "freemium" && (
                    <>
                      Please click on the <strong>"{buttonName()}"</strong>{" "}
                      button to confirm.
                    </>
                  )}
                  {newPlanData["planName"] !== "freemium" && (
                    <>
                      To checkout and enter your billing information, please
                      click the <strong>"{buttonName()}"</strong> button. Your
                      subscription will continue until you cancel the service.
                    </>
                  )}
                </h4>

                <div className="more-action">
                  <Button
                    name="Submit"
                    onClick={prevScreen}
                    className="transparent-bg-white-btn action-btn"
                  >
                    Back
                  </Button>{" "}
                  {newPlanData["planName"] === "freemium" && (
                    <Button
                      name="Submit"
                      onClick={() => confirmBilling("", userData.data)}
                      className="primary-gradient-btn action-btn"
                    >
                      Accept
                    </Button>
                  )}
                  {newPlanData["planName"] !== "freemium" &&
                    props.userDeep.paymentInfoLoaded === false && (
                      <Button
                        name="Submit"
                        onClick={redirectToCheckOut}
                        className="primary-gradient-btn action-btn"
                      >
                        Checkout
                      </Button>
                    )}
                  {newPlanData["planName"] !== "freemium" &&
                    !("paymentInfoLoaded" in props.userDeep) && (
                      <Button
                        name="Submit"
                        onClick={redirectToCheckOut}
                        className="primary-gradient-btn action-btn"
                      >
                        Checkout
                      </Button>
                    )}
                  {newPlanData["planName"] !== "freemium" &&
                    props.userDeep.paymentInfoLoaded === true && (
                      <Button
                        name="Submit"
                        onClick={updateBilling}
                        className="primary-gradient-btn action-btn"
                      >
                        Confirm Plan Change
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
      )}
      {props.user && screen === 3 && (
        <>
          <div className="consent-to-bill-msg-sec">
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper">
                <h1>Billing Screen</h1>
                <h4>
                  Subscription to {capitalize(newPlanData["planName"])} plan
                  successful!
                </h4>

                {newPlanData["planName"] !== "freemium" && (
                  <form onSubmit={handleSubmit}>
                    <div className="more-action">
                      <Button
                        id="checkout-and-portal-button"
                        type="submit"
                        className="action-btn primary-gradient-btn"
                      >
                        View your billing information
                      </Button>{" "}
                    </div>
                  </form>
                )}
                <div className="more-action">
                  <Button
                    id="continue-button"
                    type="submit"
                    className="action-btn primary-gradient-btn"
                    onClick={() => (window.location.href = "/")}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
      )}
      {props.user && screen === 4 && (
        <>
          {/* <RowMargin />
          <h1 style={{ paddingLeft: "100px" }}>Billing Screen</h1> */}
          <div className="consent-to-bill-msg-sec">
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper">
                <Stripe />
              </div>
            </div>
          </div>
          {props.showLoginErrorMessage && <Footer />}
        </>
      )}
      {props.user && screen === 5 && (
        <>
          <div className="consent-to-bill-msg-sec">
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper">
                <h1>Consent to Bill</h1>
                <h4>
                  Congratulations! You are now configured to go LIVE with 1
                  chatbot/agent into production (WHAT TO DO ABOUT ALL THE OTHER
                  CHAT BOTS THEY CREATED???)
                </h4>
              </div>
            </div>
          </div>

          {props.showLoginErrorMessage && <Footer />}
        </>
      )}
      <NotLoggedInMessage {...props} />
    </React.Fragment>
  );
}
/*
    Current Plan = {capitalize(currentPlanName)}
          <form>
            <select name="planName" onChange={handleInputChange}>
              <option selected value=""></option>
              { currentPlanName !== "freemium" && <option value="freemium">Freemium</option>}
              { currentPlanName !== "silver" && <option value="silver">Silver</option>}
              { currentPlanName !== "gold" && <option value="gold">Gold</option>}
              { currentPlanName !== "platinum" && <option value="platinum">Platinum</option>}
            </select>
          </form> 
          <Button name="Submit" onClick={handleClick}>Submit</Button>
    <WixSubscriptions />
    <Elements stripe={stripePromise}>
      <MyCheckoutForm />
    </Elements>
             <div><Button name="Submit" onClick={confirmBilling}>Accept</Button></div>
in screen 2
          {props.userDeep.billingLive === true &&
             <div><Button name="Submit" onClick={prevScreen}>Back</Button>&nbsp;<Button name="Submit" onClick={() => updateBilling()}>Accept</Button></div>
          }
          {props.userDeep.billingLive === false &&
             <div><Button name="Submit" onClick={prevScreen}>Back</Button>&nbsp;<Button name="Submit" onClick={getBillingURL}>Proceed to Billing Information</Button></div>
          }
  async function updateBilling() {
     * CONTACT BILLING FOR NEXT MONTH *
     await confirmBilling(); * use same method to change the plan *
     setScreen(4);
  }
         <div style={{ height: "50%", backgroundImage: `url("/robot.jpg")`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }} >
      </div>
*/
