import React, { useState, useEffect } from "react";

/* NEW IMAGE STUFF */
//import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import UploadFileForImages from "./UploadFileForImages";
// import { Gallery } from "react-grid-gallery";
// import { Form } from "react-bootstrap";
import Gallery from "./Gallery";
/* NEW IMAGE STUFF */
import { fetchSecure } from "../utils/utils";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../index.css";

// RAD 1/5/2025 - Made data sources work and added styling
const PopupGallery = (props) => {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState(false);
  const [savedImages, setSavedImages] = useState([]);

  const defaultImages = [
    { src: "/Magic_wand.gif" },
    { src: "./chatbot.png" },
    { src: "./logo192.png" },
    { src: "./robot.jpg" },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    //console.log("IN HERE PopupGallery useEffect - ");
    async function loadImages() {
      const fileData = await fetchSecure(
        "/api/getFileURLs/" + props.userDeep.id
      );

      var files = await fileData.json();

      files = files.map((file) => file.src);

      let tempSavedImages = new Set();

      for (var file of files) {
        const index = file.indexOf(props.userDeep.id);

        if (index > -1) {
          file = file.substring(index);
        }

        const response = await fetchSecure("/api/getFileImage/" + file);

        var data = await response.json();

/* KAD took out after merge Jan 26, 2025 because not sure it makes sense in popup component 
        if (data?.code?.includes("auth")) {
          setOpenAlert(true);

        }
*/

        data[0]["fileName"] = file;

        tempSavedImages.add(data[0]);
      }

      const arrayFromSet = Array.from(tempSavedImages);

      setSavedImages(arrayFromSet);

      setImages(true);
    }
    if (!images) {
      loadImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // RAD 1/7/2025 - Fixed a bug of images being out of order
  const reloadImages = async () => {
    const fileData = await fetchSecure("/api/getFileURLs/" + props.userDeep.id);

    var files = await fileData.json();

    files = files.map((file) => file.src);

    // Mapping to avoid duplicates
    let uniqueImagesMap = new Map();

    for (var file of files) {
      const index = file.indexOf(props.userDeep.id);

      if (index > -1) {
        file = file.substring(index);
      }

      const response = await fetchSecure("/api/getFileImage/" + file);

      var data = await response.json();

      // if (data?.code?.includes("auth")) {
      //   setOpenAlert(true);
      // }

      // Assuming data[0] is the image object
      const image = data[0];

      image["fileName"] = file;

      uniqueImagesMap.set(image.src, image);
    }

    // Convert Map values back to an array
    const uniqueImagesArray = Array.from(uniqueImagesMap.values());

    setSavedImages(uniqueImagesArray);
  };

  function isImage(fieldData) {
    if (typeof fieldData === "undefined") return false;
    if (
      fieldData.toLowerCase().endsWith(".png") ||
      fieldData.toLowerCase().endsWith(".gif") ||
      fieldData.toLowerCase().endsWith(".jpeg") ||
      fieldData.toLowerCase().endsWith(".jpg") ||
      fieldData.toLowerCase().endsWith(".webp") ||
      fieldData.toLowerCase().endsWith(".svg") ||
      fieldData.toLowerCase().endsWith(".ico")
    )
      return true;
    else return false;
  }

  const handleSelect = async (index, item, event) => {

    if (isImage(item)) {
      props.setFormData({
        data: { ...props.data, [props.field.name]: defaultImages[index].src.replace(/^\.\/+/, '') },
        status: "IDLE",
      });
    } else {
      const fileData = await fetchSecure(
        "/api/getFileURLs/" + props.userDeep.id
      );

      var files = await fileData.json();

      files = files.map((file) => file.src);

      props.setFormData({
        data: { ...props.data, [props.field.name]: files[index] },
        status: "IDLE",
      });
    }

    setOpen(false);
  };

  /*
      <Button style={{ height: '10px' }} variant="outlined" onClick={handleClickOpen}>
        Open Gallery
      </Button>
*/
  return (
    <div className='gallery'>
      <div className='small-primary-btn' onClick={handleClickOpen}>
        Open Gallery
      </div>
      <Dialog
        className='popup-gallery-dialog'
        disableScrollLock
        open={open}
        onClose={handleClose}>
        <div className='close-icon'>
          <IconButton className='icon-button' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle className='dialog-title'>Image Gallery</DialogTitle>
        <UploadFileForImages
          filePrefix={props.userDeep.id.concat("/")}
          id={props.userDeep.id}
          user_id={props.userDeep.userId}
          callback={reloadImages}
        />
        <DialogTitle className='dialog-title'>Saved Images</DialogTitle>
        <Gallery
          images={savedImages}
          handleSelect={handleSelect}
          canDelete={true}
          reloadImages={reloadImages}
        />
        {/* <Gallery
          defaultContainerWidth={1300}
          margin={20}
          images={savedImages}
          onSelect={handleSelect}
        /> */}
        <DialogTitle className='dialog-title'>Sample Images</DialogTitle>
        <Gallery
          images={defaultImages}
          handleSelect={handleSelect}
          canDelete={false}
          reloadImages={reloadImages}
        />
        {/* <Gallery
          defaultContainerWidth={1300}
          margin={20}
          images={defaultImages}
          onSelect={handleSelect}
        /> */}
      </Dialog>
    </div>
  );
};

export default PopupGallery;
