import { getAuth, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/config";
import { initializeApp } from "firebase/app";
import { jwtDecode } from "jwt-decode"; // KAD Dec 29, 2024

// Helper function to check if the token is expired
function isTokenExpired(token) {
  try {
    if (typeof token !== "string") return false;

    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
}

// Helper function to refresh the token
async function refreshToken() {
  try {
    // It avoids crashing by returning null if auth.currentUser is not available. Vik Feb 6, 2025
    if (!auth?.currentUser) {
      console.warn("refreshToken: User is not authenticated.");
      return null; // Return null instead of crashing
    }
    const newToken = await auth.currentUser.getIdToken(true); // Force refresh
    //setToken(newToken);
    return newToken;
  } catch (error) {
    console.error("Error refreshing token:", error);
    return null;
  }
}

export async function fetchSecure(url, options) {
  let token = localStorage.getItem("token");
  const wixToken = localStorage.getItem("wixToken");

  // KAD trying to refresh tokens
  if (isTokenExpired(token)) {
    // Wait for Firebase authentication state before refreshing the token, Vik Feb 6, 2025
    await new Promise((resolve) => {
      // onAuthStateChanged ensures that Firebase has fully loaded the authentication state
      // This prevents trying to refresh the token when Firebase hasn't yet restored the session, Vik Feb 6, 2025
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          // Refresh the token only if the user is authenticated, Vik Feb 6, 2025
          token = await refreshToken();
          if (token) {
            localStorage.setItem("token", token);
          }
        }
        unsubscribe();
        resolve();
      });
    });
  }

  if (options === undefined) {
    options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      },
    };
  } else {
    if (!("headers" in options)) {
      options["headers"] = {};
      options.headers = {
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      };
    } else {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      };
    }
  }
  return fetch(url, options);
}

/* KAD is this used Feb 8, 2025 TODO KAD FIX */
export function initializeFirebase(environmentName) {
  // Import the functions you need from the SDKs you need
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  var firebaseConfig = "";

  const firebaseConfigDev = {
    apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_DEV_MEASUREMENT_ID,
  };
  const firebaseConfigProd = {
    apiKey: process.env.REACT_APP_FIREBASE_PROD_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_PROD_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROD_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_PROD_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_PROD_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_PROD_APP_ID,
  };
  const firebaseConfigTest = {
    apiKey: process.env.REACT_APP_FIREBASE_TEST_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_TEST_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_TEST_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_TEST_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_TEST_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_TEST_APP_ID,
  };

  if (environmentName === "Development") {
    firebaseConfig = firebaseConfigDev;
  }
  if (environmentName === "Production") {
    firebaseConfig = firebaseConfigProd;
  }
  if (environmentName === "Test") {
    firebaseConfig = firebaseConfigTest;
  }
  /*
firebaseConfig = firebaseConfigDev;
*/

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // Initialize Firebase Authentication and get a reference to the service
  return (auth = getAuth(app));
}

/* this function is used to make a camel case database field name like adminConfig and turn it into something we can display like Admin Config */

export function convertCamelToDisplayName(str) {
  return capitalizeWords(camelToNormal(str));
}

function camelToNormal(str) {
  return str.replace(/([A-Z])/g, " $1").toLowerCase();
}

function capitalizeWords(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export async function logUserAction(userId, action, additionalInfo) {
    const dataToSend = {"userId": userId, "action": action, "additionalInfo": additionalInfo }
      const response = await fetchSecure("/api/logUserAction", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(dataToSend),
      });
      const data = await response.json();
}

// module.exports = { fetchSecure };
