import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaRegCircleDot } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import { convertCamelToDisplayName } from "../utils/utils.js";

function List(props) {
  const [items, setItems] = useState(props.list || []);
  const [fields, setFields] = useState(props.fields || []);
  const [listConfig, setListConfig] = useState(props.list_config || []);
  const [regexString, setRegexString] = useState(props.regex || []);
  const [displayField, setDisplayField] = useState(
    props.fields && props.fields.length > 0 ? props.fields[0] : ""
  );
  const [showInputButton, setShowInputButton] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [formData, setFormData] = useState({ data: {}, status: "IDLE" });

  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  const validateRegex = (fieldData, regexString) => {
    try {
      const regex = new RegExp(regexString);
      return regex.test(fieldData);
    } catch (error) {
      console.error("Error validating regex: ", error);
      return false;
    }
  };

  const showInput = (e) => {
    e.preventDefault();
    setEditIndex(-1);
    setShowInputButton(true);
    setFormData({ data: {}, status: "IDLE" });
  };

  const showEditInput = (e, index) => {
    e.preventDefault();
    setEditIndex(index);
    setShowInputButton(false);

    // Initialize formData with the item being edited
    const item = items[index];
    if (fields.length === 0) {
      // For single-field lists, use `add_value` as the key
      setFormData({
        data: { add_value: item },
        status: "IDLE",
      });
    } else {
      // For multi-field lists, use the item as-is
      setFormData({
        data: { ...item },
        status: "IDLE",
      });
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    let newItems = [...items];

    if (fields.length === 0) {
      // Handle single-field lists
      if (!formData.data.add_value || formData.data.add_value.length === 0) return;
      if (regexString.length > 0 && !validateRegex(formData.data.add_value, regexString)) return;

      newItems = new Set(newItems);
      newItems.add(formData.data.add_value);
      newItems = Array.from(newItems);
    } else {
      // Handle multi-field lists
      if (!formData.data[displayField] || formData.data[displayField].length === 0) return;
      newItems.push(formData.data);
    }

    setItems(newItems);
    props.callback(props.field_name, newItems);
    setFormData({ data: {}, status: "IDLE" });
    setShowInputButton(false);
  };

  const handleEdit = (e, index) => {
    e.preventDefault();

    const updatedItems = [...items];
    if (fields.length === 0) {
      // For single-field lists
      updatedItems[index] = formData.data.add_value;
    } else {
      // For multi-field lists
      updatedItems[index] = { ...updatedItems[index], ...formData.data };
    }

    setItems(updatedItems);
    props.callback(props.field_name, updatedItems);

    setEditIndex(-1);
    setFormData({ data: {}, status: "IDLE" });
  };

  const handleRemove = (e, index) => {
    e.preventDefault();
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    props.callback(props.field_name, newItems);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setShowInputButton(false);
    setEditIndex(-1);
    setFormData({ data: {}, status: "IDLE" });
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
    props.callback(props.field_name, newItems);
  };

  const getInputFields = () => {
    return fields.map((field, x) => {
      const attributes = listConfig.length > x ? { size: listConfig[x]?.size } : {};
      const item = editIndex !== -1 ? formData.data : formData.data;

      return (
        <td key={field}>
          <input
            {...attributes}
            value={item[field] || ""}
            name={field}
            onChange={handleInputChange}
          />
        </td>
      );
    });
  };

  const getFieldHeaders = () => {
    return fields.map((field, index) => (
      <td key={index}>
        <b>
          <i>
            <font color="#fff">{convertCamelToDisplayName(field)}</font>
          </i>
        </b>
      </td>
    ));
  };

  return (
    <div className="input-with-add-preview-lists">
      {!showInputButton && editIndex === -1 && (
        <button onClick={showInput} className="primary-gradient-btn plus-btn">
          <FiPlus />
        </button>
      )}

      {(showInputButton || editIndex !== -1) && fields.length === 0 && (
        <form className="input-with-add-btn">
          <input
            name="add_value"
            className="form-control"
            value={formData.data.add_value || ""}
            onChange={handleInputChange}
          />
          <div className="input-with-right-action-x">
            <button
              onClick={editIndex !== -1 ? (e) => handleEdit(e, editIndex) : handleAdd}
              className="primary-gradient-btn submit-btn"
              disabled={!formData.data.add_value}
            >
              {editIndex !== -1 ? "Confirm" : "Submit"}
            </button>
            <button onClick={handleCancel} className="primary-gradient-btn submit-btn">
              Cancel
            </button>
          </div>
        </form>
      )}

      {(showInputButton || editIndex !== -1) && fields.length > 0 && (
        <form>
          <table>
            <thead>
              <tr>{getFieldHeaders()}</tr>
            </thead>
            <tbody>
              <tr>{getInputFields()}</tr>
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginTop: "20px",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <button
              onClick={editIndex !== -1 ? (e) => handleEdit(e, editIndex) : handleAdd}
              className="primary-gradient-btn submit-btn"
            >
              {editIndex !== -1 ? "Confirm" : "Confirm"}
            </button>
            <button onClick={handleCancel} className="primary-gradient-btn submit-btn">
              Cancel
            </button>
          </div>
        </form>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className="droppable-ul-lists">
              {items.map((item, index) => (
                <Draggable
                  key={item[displayField] || index}
                  draggableId={`${item[displayField] || index}`}
                  index={index}
                >
                  {(provided) => (
                    <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className="droppable-li-item"
                    >
                      <div className="tick">
                        <FaRegCircleDot />
                      </div>
                      <div className="text-div">
                        {fields.length === 0 ? item : item[displayField]}
                      </div>
                      <div className="action-div">
                        <button
                          onClick={(e) => showEditInput(e, index)}
                          className="plus-btn primary-gradient-btn"
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={(e) => handleRemove(e, index)}
                          className="plus-btn primary-gradient-btn"
                        >
                          <IoMdClose />
                        </button>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default List;