import React, { useEffect, useState } from "react";

import { withRouter } from "react-router";
import { useMyContext } from "../MyContext";
import { fetchSecure } from "../utils/utils";

import "../pages/Home.css";

//Components

function ShowAgent(props) {
  const [agentURL, setAgentURL] = useState("");
  const myContext = useMyContext();

  useEffect(() => {
    //console.log("in ShowAgent useEffect");
    async function loadConfig() {
      const tokenResponse = await fetchSecure("/api/getToken");
      const token = await tokenResponse.json();

      setAgentURL(
        myContext["data"]["agentURL"] +
          "/chat/WWWW/" +
          props.agent_id +
          "/" +
          token["accessToken"]
      );
    }
    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.agent_id]); /* KAD changed this on Feb 8, 2025 so the iframe would chnage when the agent_id property changed */

  return (
    <div style={{ textAlign: "center" }}>
      <iframe
        title={agentURL}
        src={agentURL}
        className='myIframe'
        height='620'
        scrolling='no'></iframe>
    </div>
  );
}

export default withRouter(ShowAgent);
