/* DELETE ME AFTER SOME TIME Jan 31, 2025 TODO KADFIX */
function OLDevaluateDynamicLogic(logicString, variables) {
  //const regex = /([a-z.A-Z_]+)\s*(==|!=)\s*([a-zA-Z_]+|\d+)/g;
  const regex =
    /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|\w+)\s*(==|!=|IN|in)\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|\w+|\d+)/g;

  const matches = logicString.matchAll(regex);

  let result = true;
  for (const match of matches) {
    const [/*_,*/ variable1, operator, variable2] = match;
    console.log(variable1 + "," + operator + "," + variable2);
    var value1 = "";
    if (variable1.indexOf(".") >= 0) {
      const arr = variable1.split(".");
      value1 = variables[arr[0]][arr[1]];
    } else {
      value1 = variables[variable1];
    }
    const value2 = variables[variable2] || variable2; // Handle literal values
    console.log(value1 + "," + JSON.stringify(value2) + "," + variable2);
    if (operator.toLowerCase() === "in") {
      result = result && value2.includes(value1);
    } else if (operator === "==") {
      result = result && value1 === value2;
    } else if (operator === "!=") {
      result = result && value1 !== value2;
    } else {
      // Handle unexpected operators
      console.error("Invalid operator:", operator);
      console.error("Logic string:", logicString);
      return false;
    }
  }

  return result;
}
function evaluateDynamicLogic(logicString, variables) {
  const regex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|\w+(?:\.\w+)*)\s*(==|!=|IN|in)\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|\w+(?:\.\w+)*|\d+)/g;  // Corrected Regex


  const matches = logicString.matchAll(regex);

  let result = true;

  for (const match of matches) {
    const [_, variable1, operator, variable2] = match;
    //console.log(variable1 + "," + operator + "," + variable2);

    // Improved nested property access for variable1 (same as before)
    let value1 = variables;
    const variable1Parts = variable1.split(".");
    for (const part of variable1Parts) {
      if (value1 && typeof value1 === 'object' && part in value1) {
        value1 = value1[part];
      } else {
        value1 = undefined;
        break;
      }
    }

    const value2 = variables[variable2] || variable2; // Handle literal values
    //console.log(value1 + "," + JSON.stringify(value2) + "," + variable2);

    if (operator.toLowerCase() === "in") {
      result = result && (Array.isArray(value2) && value2.includes(value1));
    } else if (operator === "==") {
      result = result && value1 === value2;
    } else if (operator === "!=") {
      result = result && value1 !== value2;
    } else {
      console.error("Invalid operator:", operator);
      console.error("Logic string:", logicString);
      return false;
    }
  }

  return result;
}
const capitalize = (field) => {
  return field[0].toUpperCase() + field.substring(1);
};

async function fetchSecure(url, options) {
  const token = localStorage.getItem("token");
  const wixToken = localStorage.getItem("wixToken");
  //console.log("Token = " + token, "Wix token = ", wixToken);

  if (options === undefined) {
    options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      },
    };
  } else {
    if (!("headers" in options)) {
      options["headers"] = {};
      options.headers = {
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      };
    } else {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      };
    }
  }
  //console.log(options);
  return fetch(url, options);
}

function getUniqueObjects(arr, key) {
  const seen = new Set(); // Use a Set to efficiently track unique values

  return arr.filter(obj => {
    const value = obj[key]; // Get the value of the specified key

    if (value !== undefined && !seen.has(value)) {
      seen.add(value); // Add the value to the Set if it's not already there
      return true; // Keep the object in the filtered array
    }
    return false; // Discard the object if the value has already been seen
  });
}

module.exports = { evaluateDynamicLogic, capitalize, fetchSecure, getUniqueObjects };
