import React from "react";

const Capabilities = () => {
  return ( 
<div className="consent-to-bill-msg-wrapper for-info-card mt">
<div className="custom-container">
<div style={{ color: 'var(--light-color)' }}>
<h3>High-level Capabilities</h3>
<ul>
<li>
Integrates into any website and platforms like WIX, Squarespace, and Wordpress
</li>
<li>
Highly Configurable Chatbot (colors, sequencing, integrations, knowledge-base
</li>
<li>
Full Logging
</li>
<li>
Improvement Recommendations
</li>
<li>
Analytics
</li>
<li>
Testing Tools
</li>
</ul>
<h3>Supported Document Types</h3>
<ul>
<li>
PDF
</li>
<li>
DOCX
</li>
<li>
XLSX/JSON
</li>
<li>
TXT
</li>
<li>
PNG/GIF/JPEG/JPGP/WEBP
</li>
</ul>

<h3>Supported Agent Types</h3>
<ul>
<li>
FAQ: an agent that answers FAQs. Just paste in your FAQs and the agent will work immediately
</li>
<li>
Document QA: an agent that will answer questions about the data stored in one or more documents  
</li>
<li>
Website QA: an agent which scans your website and answers questions based on the information on your website
</li>
<li>
Dialogflow:  an agent that connects to Google Dialogflow or Vertex Agents and will answer questions that Google Dialogflow or Vertex Agents is configured to answer
</li>
<li>
Webhook:  a custom agent which can answer any way you want or integrate with any other system to answer questions (requires a developer). This agent could be used to connect into other platforms like Google Dialogflow.
</li>
</ul>

<h3>Special Enhanced Features</h3>
<ul>
<li>
Agent Chaining: Combine Agents together to make more power agents 
</li>
<li>
SDK: Build more complex agents on top of our platform that leverage other models, or special integrations we don't support out of the box. Provide Rich Content responses like tables, pictures, forms 
</li>
<li>
Automated testing: Enhancing agents with new models or new knowledge is an ongoing support task.  We provide tools to build test plans and execute those test plans on a regular basis

</li>
</ul>
<h3>Supported LLM Models</h3>
<ul>
<li>
Gemini Flash 2.0
</li>
<li>
OpenAI 4o
</li>
</ul>
</div>
</div>
</div>


  )
};

export default Capabilities;
