import { createContext, useContext, useState, useEffect } from "react";
import { fetchSecure } from "./utils/utils";

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [data, setData] = useState({
    adminToolChatBot: "",
    environmentName: "",
  });
  const [configData, setConfigData] = useState({
  });
  //const [data, setData] = useState(null)
  const token =
    localStorage.getItem("access_token") || localStorage.getItem("token");
  useEffect(() => {
    //console.log("IN HERE MyContextProvider useEffect");
    async function loadAdminConfig() {
      try {
        const response = token
          ? await fetchSecure("/api/getAdminConfig")
          : await fetch("/api/getAdminConfig");
        const data = await response.json();
        setData(data);
        if (token) {
           const response1 = await fetchSecure("/api/getObjects/config");
           const data2 = await response1.json();
           setConfigData(data2[0]);
        }
      } catch (e) {
        console.log(`Failed to load adminConfig`);
        console.log(e);
        console.log(e.stack);
      }
    }
    loadAdminConfig();
  }, []);

  return (
    <MyContext.Provider value={{ data, setData, configData, setConfigData }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  return useContext(MyContext);
};
