import React, { useContext } from "react";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

const SystemAdminAgents = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {};
  config["agents"] = {
    collection: "agents",
    defaultSort: { selector: 3, asc: false },
    name: "Users",
    showLikes: false,
    buttonName: "Save",
    security: {
      create: "None",
      edit: "None",
      delete: "None",
      view: "AllUsers",
    },
    flags: "",
    fields: [
      {
        placeholder: "",
        display_name: "Agent ID",
        name: "id",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Agent Type",
        name: "agentType",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "User ID",
        name: "userID",
        input_type: "text",
        default: "",
        required: true,
      },
    ],
    table: [
      {
        selector: (row) => row.userId,
        name: "User ID",
        sortable: true,
        minWidth: "25%",
      },
      {
        selector: (row) => row.agentType,
        name: "Agent Type",
        sortable: true,
        minWidth: "25%",
      },
      {
        selector: (row) => row.creationDate,
        name: "Creation Date",
        sortable: true,
        sortOrder: "desc",
        minWidth: "10%",
        sortFunction: (rowB, rowA) => {
          // Perform ASCII comparison
          if (typeof rowB.creationDate === "undefined") return true;
          return rowB.creationDate.localeCompare(rowA.creationDate);
        },
        cell: (row) =>
          row.creationDate ? row.creationDate.substring(0, 10) : "Unknown",
      },
    ],
    filterField: "userId",
    exportFields: ["userId"],
    otherTableOptions: { paginationPerPage: 10 },
  };
  return (
    <div className='table-listing-page-wrapper'>
      <SuperAppObject
        depth={1}
        style={superAppStyle}
        config={config["agents"]}
        user={props.user}
        userDeep={props.userDeep}
        collection_name='agents'
        display_state='TABLE'
      />
    </div>
  );
};

export default SystemAdminAgents;
