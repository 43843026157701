import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useMyContext } from "../MyContext";

import { Navbar, Nav } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import GlobalContext from "../GlobalContext";
import { fetchSecure, logUserAction } from "../utils/utils";
import { FaCircleUser } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
// import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
//Components

//import WixLoginBar from "./WixLoginBar";
import SuperAppButton from "./SuperAppButton";

function Header(props) {
  const [user] = useAuthState(auth);
  const { data } = useMyContext();
  //const [activeMenu, setActiveMenu] = useState(data["environmentName"] === 'Development'?"home":"agents"); used to show only some stuff in dev
  const [activeMenu, setActiveMenu] = useState("home");
  const [member, setMember] =
    useState(
      null
    ); /* KAD changed from "" to null to make sure we don't show the Login button when already logged in Feb 4, 2025 NOT SURE IF THIS HELPED OR NOT */
  const [, /* showLoginButton */ setShowLoginButton] = useState(true);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [expanded, setExpanded] = useState(true);
  const [forceLogout, setForceLogout] = useState(false); // KAD added Dec 30 2024
  const [marketingMessages, setMarketingMessages] = useState({});
  const [clickedMessages, setClickedMessages] = useState([]);
  // Feb 13, 2025 KAD const [showCookie, setShowCookie] = useState(true);
  const location = useLocation();
  var pathname = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const globalData = useContext(GlobalContext);
  /* KAD don't think we need anymore with new Cookie Consent Feb 13, 2025 
  const cookieConsent = Cookies.get("cookieConsent");

  const handleCookie = (status) => {
    setShowCookie(false);
    Cookies.set("cookieConsent", status, { expires: 30 });
  };
*/

  useEffect(() => {
    //console.log("IN HERE App UseEffect for data");
    async function loadComponent() {
      setActiveMenu("home");

      /* This next line will set the menu based on the /home, /agents path appended on the URL */
      if (pathname.length > 0) setActiveMenu(pathname);

      //setMember(Cookies && Cookies.get("XRTSG")?JSON.parse(Cookies.get("XRTSG"))["loggedInUser"]:"");
      const sessionId = globalData.authConfig.sessionId;
      // Cookies && Cookies.get("XRTSG")
      //   ? JSON.parse(Cookies.get("XRTSG"))["sessionId"]
      //   : "";
      // console.log({ sessionId });

      if (sessionId && sessionId.length > 0) {
        // console.log(encodeURIComponent(sessionId));
        const response = await fetchSecure(
          "/api/getSession/" + encodeURIComponent(sessionId)
        );
        const data = await response.json();
        setMember(data["userId"]);
      } else {
        setForceLogout(true);
        auth.signOut().then(() => {
          // Session cookie invalidated
          //console.log("session cookie & storage gone");
          //Cookies.remove("XRTSG");
          sessionStorage.removeItem("sessionId");
          localStorage.removeItem("token");
          localStorage.removeItem("wixToken");
          setMember("");
          //Clean usedeep key - Sourav -31Jan25
          sessionStorage.removeItem("userDeepObj");
        });
      }
    }
    loadComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      setMember(user && user.email);
    }
    loadComponent();
  }, [user]);

  /* KAD added this Feb 8, 2025 to make sure we redirect to agents menu if silver or gold plan */
  useEffect(() => {
    async function loadComponent() {
      /*
       console.log("BLAHBLAH" + props.userDeep.planName + JSON.stringify(location));
       if (location.pathname !== '/agents' && (props.userDeep.planName === 'silver' || props.userDeep.planName === 'gold')) {
          console.log("trying to set active menu");
          setActiveMenu("agents");
          window.location.href = "/agents"
       }
*/
    }
    loadComponent();
  }, [props.userDeep]);

  // RAD 1/7/2025 - loads marketing messages
  useEffect(() => {
    async function loadMarketing() {
      // Checks to see if user object data has loaded
      if (Object.keys(props.userDeep).length !== 0) {
        const marketingResponse = await fetchSecure("/api/getMarketing");
        const marketingData = await marketingResponse.json();

        const messagesClickedArray = props.userDeep.messagesClicked;

        if (messagesClickedArray) {
          const filteredResult = marketingData.filter(
            (message) =>
              !messagesClickedArray.some(
                (clickedMessage) => clickedMessage.messageId === message.id
              )
          );

          setMarketingMessages(filteredResult);
        } else {
          setMarketingMessages(marketingData);
        }
      }
    }

    loadMarketing();
  }, [props.userDeep]);

  function isAdmin(props) {
    if (
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("admin") >= 0
    )
      return true;

    return false;
  }

  function isTester(props) {
    if (
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("tester") >= 0
    )
      return true;

    return false;
  }
  function isLoggedIn() {
    if (forceLogout) {
      return false;
    }
    //console.log(user && user.email && user.email.length > 0)
    //console.log(member)
    if (user && user.email && user.email.length > 0 && member) return true;

    return false;
  }

  /* Need space between when 2 bars */
  const style = {
    display: "flex",
    justifyContent: props.user !== "" ? "space-between" : "end",
  };
  const capitalize = (str) => {
    if (str === null) return "";
    if (typeof str === "undefined") return "";
    str = str.substring(0, 1).toUpperCase() + str.substring(1);
    return str;
  };
  const [afterLoginUserInfo, setAfterLoginUserInfo] = useState(false);
  // const [advertisement, setAdvertisement] = useState(false);

  // RAD 1/7/2025 - handles clicking the close button and recording it
  const handleMarketingClick = async (messageId) => {
    let clickedArray = props.userDeep.messagesClicked;

    const date = new Date().toISOString();
    const clickedMessage = { messageId: messageId, timeStamp: date };

    let updatedMessagesClicked;
    if (typeof clickedArray === "undefined") {
      updatedMessagesClicked = [...clickedMessages, clickedMessage];
    } else {
      updatedMessagesClicked = [
        ...clickedArray,
        ...clickedMessages,
        clickedMessage,
      ];
    }

    setClickedMessages(updatedMessagesClicked);

    const updatedUser = {
      ...props.userDeep,
      messagesClicked: updatedMessagesClicked,
    };

    const response = await fetchSecure("/api/saveObject/users", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(updatedUser),
    });
    const data = await response.json();
    if (data.success) {
      // remove the message from the marketingMessages var
      const updatedMessages = marketingMessages.filter(
        (message) => message.id !== messageId
      );

      setMarketingMessages(updatedMessages);
    } else {
      console.log("in data failure()");
      throw Error("Failed to register");
    }
  };

  return (
    <>
      {location.pathname !== "/signin" && (
        <Navbar
          variant="light"
          expand="lg"
          expanded={expanded}
          className="main-header"
        >
          <div className="custom-container">
            <IndexLinkContainer to="/" activeClassName="">
              <Navbar.Brand href="#">
                <img src="/images/genAIAgentBuilder-logo.png" alt="" />
              </Navbar.Brand>
            </IndexLinkContainer>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => {
                setExpanded(expanded ? false : true);
              }}
            />
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={expanded ? "show" : ""}
              style={style}
            >
              <button
                type="button"
                className="close-btn"
                onClick={() => {
                  setExpanded(expanded ? false : true);
                }}
              >
                <AiOutlineClose />
              </button>
              <Nav>
                {!isLoggedIn() && (
                  <>
                    <Navbar.Text>
                      <Link
                        to={"capabilities"}
                        className={
                          activeMenu === "capabilities"
                            ? "active nav-link"
                            : "nav-link"
                        }
                        style={
                          activeMenu === "capabilities"
                            ? superAppStyle.menuActive
                            : superAppStyle.menuInactive
                        }
                        onClick={() => {
                          setActiveMenu("capabilities");
                        }}
                      >
                        Capabilities
                      </Link>
                    </Navbar.Text>
                    <Navbar.Text>
                      <Link
                        to={"plan_table"}
                        className={
                          activeMenu === "plan_table"
                            ? "active nav-link"
                            : "nav-link"
                        }
                        style={
                          activeMenu === "plan_table"
                            ? superAppStyle.menuActive
                            : superAppStyle.menuInactive
                        }
                        onClick={() => {
                          setActiveMenu("plan_table");
                        }}
                      >
                        Pricing Plans
                      </Link>
                    </Navbar.Text>
                    <Navbar.Text>
                      <Link
                        to={"contact"}
                        className={
                          activeMenu === "contact"
                            ? "active nav-link"
                            : "nav-link"
                        }
                        style={
                          activeMenu === "contact"
                            ? superAppStyle.menuActive
                            : superAppStyle.menuInactive
                        }
                        onClick={() => {
                          setActiveMenu("contact");
                        }}
                      >
                        Contact
                      </Link>
                    </Navbar.Text>
                  </>
                )}
                {props.user !== "" &&
                  Object.keys(props.userDeep).length > 0 && ( // Vik added condition the prevent rendering until userPlan data is not ready to be rendered
                    <>
                      {!props.iframeLogin && !props?.isFinishRegistration && (
                        <Navbar.Text>
                          <Link
                            to={"home"}
                            className={
                              activeMenu === "home"
                                ? "active nav-link"
                                : "nav-link"
                            }
                            style={
                              activeMenu === "home"
                                ? superAppStyle.menuActive
                                : superAppStyle.menuInactive
                            }
                            onClick={() => {
                              setActiveMenu("home");
                            }}
                          >
                            Home
                          </Link>
                        </Navbar.Text>
                      )}
                      {isAdmin(props) && (
                        <Navbar.Text>
                          <Link
                            to={"playground"}
                            className={
                              activeMenu === "playground"
                                ? "active nav-link"
                                : "nav-link"
                            }
                            style={
                              activeMenu === "playground"
                                ? superAppStyle.menuActive
                                : superAppStyle.menuInactive
                            }
                            onClick={() => {
                              setActiveMenu("playground");
                            }}
                          >
                            Playground
                          </Link>
                        </Navbar.Text>
                      )}
                      {isAdmin(props) && (
                        <Navbar.Text>
                          <Link
                            to={"console"}
                            className={
                              activeMenu === "console"
                                ? "active nav-link"
                                : "nav-link"
                            }
                            style={
                              activeMenu === "console"
                                ? superAppStyle.menuActive
                                : superAppStyle.menuInactive
                            }
                            onClick={() => {
                              setActiveMenu("console");
                            }}
                          >
                            Console
                          </Link>
                        </Navbar.Text>
                      )}
                      {/* KAD added props.userDeep.roles to try and sync with the isAdmin and isRole...on Feb 4, 2025...seems to have helped draw all at the same time */}
                      {props.userDeep &&
                        props.userDeep.roles &&
                        props.userDeep.planName !== "unregistered" &&
                        !props?.isFinishRegistration && (
                          <>
                            <Navbar.Text>
                              <Link
                                to={"agents"}
                                className={
                                  activeMenu === "agents"
                                    ? "active nav-link"
                                    : "nav-link"
                                }
                                style={
                                  activeMenu === "agents"
                                    ? superAppStyle.menuActive
                                    : superAppStyle.menuInactive
                                }
                                onClick={() => {
                                  setActiveMenu("agents");
                                }}
                              >
                                Agents
                              </Link>
                            </Navbar.Text>
                            <Navbar.Text>
                              <Link
                                to={"help"}
                                className={
                                  activeMenu === "help"
                                    ? "active nav-link"
                                    : "nav-link"
                                }
                                style={
                                  activeMenu === "help"
                                    ? superAppStyle.menuActive
                                    : superAppStyle.menuInactive
                                }
                                onClick={() => {
                                  setActiveMenu("help");
                                }}
                              >
                                Help
                              </Link>
                            </Navbar.Text>
                            <Navbar.Text>
                              <Link
                                to={"contact"}
                                className={
                                  activeMenu === "contact"
                                    ? "active nav-link"
                                    : "nav-link"
                                }
                                style={
                                  activeMenu === "contact"
                                    ? superAppStyle.menuActive
                                    : superAppStyle.menuInactive
                                }
                                onClick={() => {
                                  setActiveMenu("contact");
                                }}
                              >
                                Contact
                              </Link>
                            </Navbar.Text>
                            <Navbar.Text>
                              <Link
                                to={"user_admin"}
                                className={
                                  activeMenu === "user_admin"
                                    ? "active nav-link"
                                    : "nav-link"
                                }
                                style={
                                  activeMenu === "user_admin"
                                    ? superAppStyle.menuActive
                                    : superAppStyle.menuInactive
                                }
                                onClick={() => {
                                  setActiveMenu("user_admin");
                                }}
                              >
                                Admin
                              </Link>
                            </Navbar.Text>
                            {isAdmin(props) && (
                              <Navbar.Text>
                                <Link
                                  to={"admin"}
                                  className={
                                    activeMenu === "admin"
                                      ? "active nav-link"
                                      : "nav-link"
                                  }
                                  style={
                                    activeMenu === "admin"
                                      ? superAppStyle.menuActive
                                      : superAppStyle.menuInactive
                                  }
                                  onClick={() => {
                                    setActiveMenu("admin");
                                  }}
                                >
                                  System Admin
                                </Link>
                              </Navbar.Text>
                            )}
                            {isTester(props) && (
                              <Navbar.Text>
                                <Link
                                  to={"tester"}
                                  className={
                                    activeMenu === "tester"
                                      ? "active nav-link"
                                      : "nav-link"
                                  }
                                  style={
                                    activeMenu === "tester"
                                      ? superAppStyle.menuActive
                                      : superAppStyle.menuInactive
                                  }
                                  onClick={() => {
                                    setActiveMenu("tester");
                                  }}
                                >
                                  Tester
                                </Link>
                              </Navbar.Text>
                            )}
                          </>
                        )}
                    </>
                  )}
                <Navbar.Text>
                  <div style={superAppStyle.headerText}>
                    {" "}
                    {Object.keys(props.userDeep).length > 0 && (
                      <div className="nav-link info-text">
                        Plan: {capitalize(props.userDeep["planName"])}
                      </div>
                    )}
                    {"  "}
                  </div>
                </Navbar.Text>
                {data["environmentName"] &&
                  data["environmentName"] !== "Production" && (
                    <Navbar.Text>
                      <div
                        className="nav-link info-text"
                        style={superAppStyle.headerText}
                      >
                        {" "}
                        {data["environmentName"]} {"  "}
                      </div>
                    </Navbar.Text>
                  )}
                <Navbar.Text>
                  <div className="nav-link">
                    {/* false && !props.iframeLogin && (
                      <WixLoginBar
                        user={props.user}
                        callback={props.callback}
                      />
                    ) */}

                    {!props.iframeLogin && (
                      <div>
                        {isLoggedIn() && (
                          // 12/25/2024 - Ryan changed this
                          <figure
                            className="after-login-user-info"
                            onClick={() =>
                              setAfterLoginUserInfo(!afterLoginUserInfo)
                            }
                          >
                            <div className="user-icon">
                              <FaCircleUser />
                            </div>
                            {/* <figcaption></figcaption> */}
                            <span
                              className={
                                afterLoginUserInfo ? "arow active" : "arow"
                              }
                            >
                              <FaAngleDown />
                            </span>

                            {afterLoginUserInfo && (
                              <>
                                <div className="after-login-user-dropdown">
                                  <figure className="user-info">
                                    <h4>{member}</h4>
                                  </figure>
                                  <div className="dropdown--item">
                                    <SuperAppButton
                                      className="dropdown-btn primary-gradient-btn"
                                      // hovertooltip={member}
                                      onClick={async () => {
                                        auth
                                          .signOut()
                                          .then(() => {
                                            logUserAction(
                                              member,
                                              "Logged Out",
                                              ""
                                            );
                                            // Session cookie invalidated
                                            console.log(
                                              "session cookie & storage gone"
                                            );
                                            //Cookies.remove("XRTSG");
                                            sessionStorage.removeItem(
                                              "sessionId"
                                            );
                                            localStorage.removeItem("token");
                                            localStorage.removeItem("wixToken");
                                            setMember("");
                                            window.location = "/";
                                            //Clean usedeep key - Sourav -31Jan25
                                            sessionStorage.removeItem(
                                              "userDeepObj"
                                            );
                                          })
                                          .catch((error) => {
                                            // An error happened
                                            console.error(error);
                                          });
                                        console.log("signing out");
                                      }}
                                    >
                                      Log Out
                                    </SuperAppButton>
                                  </div>
                                </div>
                                <div
                                  className="after-login-user-dropdown-overlay"
                                  onClick={() => setAfterLoginUserInfo(false)}
                                ></div>
                              </>
                            )}
                          </figure>
                        )}
                        {!isLoggedIn() && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <SuperAppButton
                              className="primary-gradient-btn"
                              size="sm"
                              onClick={() => {
                                props?.setFinishRegistration(false);
                                setShowLoginButton(false);
                                setForceLogout(false);
                                props.history.push("/signin");
                              }}
                            >
                              Login
                            </SuperAppButton>
                            {"  "}
                            <SuperAppButton
                              className="primary-gradient-btn"
                              size="sm"
                              onClick={() => {
                                props?.setFinishRegistration(false);
                                setShowLoginButton(false);
                                setForceLogout(false);
                                props.history.push("/signin");
                              }}
                            >
                              Try for Free
                            </SuperAppButton>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Navbar.Text>
                {/*<Nav className="ml-4" style={style}> */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      )}
      {/* KAD added this checked for unregistered because I didn't want to show the marketing messages on EVERY Screen */}
      {props.userDeep.planName !== "unregistered" &&
        marketingMessages?.length > 0 &&
        Array.isArray(marketingMessages) &&
        marketingMessages.map((message, index) => {
          return (
            <div key={index} className="advertisement-alert">
              <div className="custom-container">
                <div className="advertisement-alert-wrapper">
                  <div className="alert-text">
                    <p
                      dangerouslySetInnerHTML={{ __html: message.message }}
                    ></p>
                  </div>
                  <div className="alert-action">
                    <button
                      type="button"
                      className="closebtn"
                      onClick={() => {
                        handleMarketingClick(message.id); // Handle the click action
                      }}
                    >
                      <IoMdClose />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
export default withRouter(Header);

/*
                  <button onClick={() => {auth.signOut(); Cookies.remove("session"); window.location = "/"; }}>Sign Out</button>
      <Nav className="ml-2 mr-auto">
        <Button variant="primary" onClick={() => {props.history.push("/")}}>
            <FontAwesomeIcon icon={faHome} size="1x" />
            {" "}Home 
        </Button>
      </Nav>
              <Link to={"agents"}>
                <MenuButton title="Agents"></MenuButton>
              </Link>
{data.environmentName == 'Development' &&
}
*/
