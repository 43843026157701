import React, { useContext } from "react";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

const Marketing = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = useContext(GlobalContext)["marketingConfig"];
  return (
    <div className='table-listing-page-wrapper'>
      <SuperAppObject
        depth={1}
        style={superAppStyle}
        config={config["marketingMessages"]}
        user={props.user}
        userDeep={props.userDeep}
        collection_name='marketingMessages'
        display_state='TABLE'
      />
    </div>
  );
};

export default Marketing;
