import React from "react";

const ChatBot = ({ iframeSrc }) => {
  return (
    <div>
      <div
        style={{

          display: "block",
          position: "fixed",
          top: "65px",
          right: "20px",
          zIndex: "999",
          borderRadius: "20px",
          width: "400px",
        }}
      >
        <iframe
          title={iframeSrc}
          src={iframeSrc}
          class="myIframe"
          height="600px"
          scrolling="no"
        />
      </div>
    </div>
  );
};
/*
          width="webkit-fill-available"
          border: "10px",
          borderRadius: "20px",
          height: "600px",


          width="400px"
*/

export default ChatBot;
