import React, { useEffect, useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";

// Components
import NotLoggedInMessage from "./NotLoggedInMessage";
import Footer from "./Footer";
// import GlobalContext from "../GlobalContext";
import { fetchSecure } from "../utils/utils";
import PieChartComponent from "./PieChartComponent";
import RowMargin from "./RowMargin";
import TickPlacementBars from "./BarChartComponent";
// import { PieChart } from "@mui/x-charts/PieChart";

export default function SystemAdminStatistics(props) {
  // const containerRef = React.useRef(null);
  // const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [countOfAgentTypes, setCountOfAgentTypes] = useState({});
  const [countOfPlans, setCountOfPlans] = useState({});
  const [costOfLLM, setCostOfLLM] = useState(0);
  const [llmCostByDay, setLlmCostByDay] = useState([]);
  const [barChartConfig, setBarChartConfig] = useState(null);

  function createBarChartConfig(data, key) {
    const finalConfig = [];
    const uniqueKeys = [];
    for (let x = 0; x < data.length; x++) {
      //console.log(JSON.stringify(data[x]));
      const currentKeys = Object.keys(data[x]);
      currentKeys.forEach((currentKey) => {
        if (!uniqueKeys.includes(currentKey) && currentKey !== key) {
          uniqueKeys.push(currentKey);
        }
      });
    }
    //console.log(JSON.stringify(uniqueKeys));
    for (let x = 0; x < uniqueKeys.length; x++) {
      finalConfig.push({ dataKey: uniqueKeys[x], label: uniqueKeys[x] });
    }
    //console.log("finalConfig = " + JSON.stringify(finalConfig));
    return finalConfig;
  }
  /*
  series: [{ dataKey: config[0]["value"], label: config[0]["label"]},
           { dataKey: config[1]["value"], label: config[1]["label"]}],
*/
  /*
                [
                  { "value": "Unknown", 
                     "_key": "day", 
                     "label": "Unknown"
                  },
                  { "value": "gemini-1.5-flash-002", 
                     "_key": "day", 
                     "label": "gemini-1.5-flash-002"
                  }
                ]
  */

  // RAD 12/29/2024 - Added Pie Charts to visualize data

  // Fetch statistics
  const loadStats = async () => {
    try {
      await fetchSecure("api/getAdminStats")
        .then((response) => response.json())
        .then((data) => {
          setCountOfAgentTypes(data["countOfAgentTypes"]);
          setCountOfPlans(data["countOfPlans"]);
          setCostOfLLM(data["costOfLLM"]);
          setLlmCostByDay(data["llmCostByDay"]);
          setBarChartConfig(createBarChartConfig(data["llmCostByDay"], "day"));
        });
    } catch (e) {
      console.log(`Failed to loadStats`);
      console.log(e);
      console.log(e.stack);
    }
  };

  useEffect(() => {
    async function loadComponent() {
      await loadStats();
    }
    loadComponent();
  }, []);

  const convertToPieChartData = (countOfPlans) => {
    return Object.entries(countOfPlans).map(([label, value], index) => ({
      id: index, // Use the index as the id
      value, // Plan count as the value
      label: label.charAt(0).toUpperCase() + label.slice(1), // Capitalize the first letter of the plan
    }));
  };

  // Dynamically convert data for pie chart
  const planData = convertToPieChartData(countOfPlans);
  const agentTypes = convertToPieChartData(countOfAgentTypes);

  return (
    <React.StrictMode>
      <div style={{ width: "100%", minHeight: "300px" }}>
        {/* Snackbar Alert */}

        <h4>Total LLM Cost: ${costOfLLM}</h4>
        {props.user && props.user.length > 0 && (
          <React.Fragment>
            {planData.length > 0 ? (
              <div className='graph-container'>
                <h2 className='graph-title'>Plan Count</h2>
                <PieChartComponent data={planData} width={500} height={300} />
              </div>
            ) : (
              <p className='loading-message'>Loading plan data count...</p>
            )}
            {agentTypes.length > 0 ? (
              <div className='graph-container'>
                <h2 className='graph-title'>Agent Types</h2>
                <PieChartComponent data={agentTypes} width={600} height={300} />
              </div>
            ) : (
              <p className='loading-message'>Loading agent types count...</p>
            )}
            <RowMargin />
            <div className='graph-container'>
              <h2 className='graph-title'>LLM Cost By Day</h2>
              {barChartConfig && (
                <TickPlacementBars
                  data={llmCostByDay}
                  _key='day'
                  config={barChartConfig}
                />
              )}
            </div>
          </React.Fragment>
        )}
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
      </div>
    </React.StrictMode>
  );
}
