import React, { useEffect, useState, useContext } from "react";

//Components
import NotLoggedInMessage from "./NotLoggedInMessage";
import Footer from "./Footer";
import ImportTests from "./ImportTests";
import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";
import { fetchSecure } from "../utils/utils";
import { Alert, Box, Snackbar } from "@mui/material";

import Users from "./Users";
import SuperAppButton from "./SuperAppButton";

export default function TestPlans(props) {
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [allObjects, setAllObjects] = useState([]);

  function getConfig() {
    var configObj = {};
    try {
      configObj["test_plans"] = {
        collection: "testPlans",
        defaultSort: "kevin",
        name: "testPlans",
        defaultSort: { selector: 5, asc: false },
        showLikes: false,
        buttonName: "Save",
        security: {
          create: "AllUsers",
          edit: "AllUsers",
          delete: "AllUsers",
          view: "AllUsers",
        },
        flags: "filter_by_user",
        fields: [
          {
            placeholder: "",
            display_name: "ID",
            name: "id",
            input_type: "display",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "User ID",
            name: "userId",
            input_type: "display",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Agent",
            name: "agentId",
            input_type: "dropdown",
            help: "",
            values: "agents",
            dropdown_value_key: "id",
            dropdown_display_template: "[agentName] - [agentType]",
            colspan: 3,
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Tests",
            name: "tests",
            colspan: 3,
            input_type: "list",
            list_fields: ["question", "answer"],
            list_config: [
              { input_type: "text", size: "50" },
              { input_type: "text", size: "50" },
            ],
            list_display: "table",
            list_display_fields: ["question", "answer"],
            default: "",
            required: false,
          },
        ],
        table: [
          {
            selector: (row) => row.userId,
            name: "User ID",
            sortable: true,
            wrap: true,
            width: "40%",
          },
          {
            selector: (row) => row.agentName,
            name: "Agent Name",
            sortable: true,
            wrap: true,
            width: "40%",
          },
          {
            selector: (row) => row.creationDate,
            name: "Creation Date",
            hide: "md",
            sortable: true,
            sortOrder: "desc",
            minWidth: "20%",
            sortFunction: (rowB, rowA) => {
              // Perform ASCII comparison
              if (typeof rowB.creationDate === "undefined") return true;
              return rowB.creationDate.localeCompare(rowA.creationDate);
            },
            cell: (row) =>
              row.creationDate ? row.creationDate.substring(0, 10) : "Unknown",
          },
        ],
        filterField: "agentId",
        exportFields: ["agentId"],
        otherTableOptions: { paginationPerPage: 10 },
      };
    } catch (e) {
      console.log("Failed to configure: " + e);
      console.log(e.stack);
    }
    return configObj;
  }
  const config = getConfig();

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      const response = await fetchSecure(
        "/api/getObjects/agents" + "/where/userIds/array-contains/" + props.user
      );
      const result = await response.json();
      setAllObjects(result);
    }
    loadComponent();
  }, []);
  const Button1 = ({ label, onClick }) => (
    <SuperAppButton className="primary-gradient-btn" onClick={onClick}>
      {label}
    </SuperAppButton>
  );

  const ModalComponent1 = () => <div>Content of Modal 1</div>;
  const dynamicDisplayButtons = [
    { component: ImportTests, label: "Import Tests", _key: "agentId" },
  ];

  return (
    <React.StrictMode>
      <Box ref={containerRef} className="table-listing-page-wrapper">
        {props.user && props.user.length > 0 && (
          <React.Fragment>
            {/* <Paper style={superAppStyle.cardStyle} elevation={8}> */}
            <div className="text-white">
              <SuperAppObject
                style={superAppStyle}
                depth={1}
                all_objects={allObjects}
                config={config["test_plans"]}
                user={props.user}
                userDeep={props.userDeep}
                collection_name="testPlans"
                dynamicDisplayButtons={dynamicDisplayButtons}
                display_state="TABLE"
              />
            </div>
            {/* </Paper> */}
          </React.Fragment>
        )}
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
      </Box>
    </React.StrictMode>
  );
}
