import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

// Reusable PieChartComponent that accepts props
const PieChartComponent = ({ data, width = 400, height = 200 }) => {

  //console.log("PieChartComponent data: ", JSON.stringify(data));
  
  return (
    <PieChart
      series={[
        {
          data: data, // Use the passed data prop for dynamic input
          textStyle: { color: 'white' },
          color: "white",
          highlightScope: { fade: 'global', highlight: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      width={width}  // Use the passed width prop, with a default of 400
      height={height} // Use the passed height prop, with a default of 200
      sx={{ "& .MuiChartsLegend-series text": { color: "white !important" },
          }}
          slotProps={{
            legend: {
              labelStyle: {
                fontSize: 14,
                fill: 'white',
              },
            },
          }}
    />
  );
};

export default PieChartComponent;
