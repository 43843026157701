import React, { useContext } from "react";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";
import Agents from "../pages/Agents";

const Deleted = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  return (
    <div className='table-listing-page-wrapper'>
      <Agents user={props.user} userDeep={props.userDeep} show_deleted={true} />
    </div>
  );
};

export default Deleted;
