import React, { useEffect, useState } from "react";

//Components
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import { useMyContext } from "../MyContext";
import { fetchSecure } from "../utils/utils";
import ShowAgent from "../components/ShowAgent";
import RowMargin from "../components/RowMargin";

export default function Console(props) {
  const myContext = useMyContext();
  const [agentId, setAgentId] = useState(
    props.agent_id || "HBVj7vbb06ktMT2haNre"
  );
  const [data, setData] = useState([]);

  function setKevinAgentId(e) {
    const target = e.currentTarget;
    const value = target.value;
    console.log("IN HERE - " + value);
    setAgentId(value);
  }

  useEffect(() => {
    //console.log("IN HERE Help UseEffect");
    async function loadComponent() {}
    const additionalCommandLineArg =
      "/where/userIds/array-contains/" + props.user;

    fetchSecure("api/getObjects/agents" + additionalCommandLineArg)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      });

    loadComponent();
  }, []);

  return (
    <div className="privacyComponent-sec">
      <div className="custom-container">
        <select id="agent_id" name="agent_id" onChange={setKevinAgentId}>
          <option key={"xxx"} value="">
            Select an Agent
          </option>
          {data &&
            data.map((option, index) => {
              return (
                <option key={option["id"]} value={option["id"]}>
                  {option["agentName"]}
                </option>
              );
            })}
        </select>
        <RowMargin />
        {agentId && <ShowAgent agent_id={agentId} />}
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
      </div>
    </div>
  );
}
