import React, { useContext } from "react";

//import { Row, Col, Button } from "react-bootstrap";
//import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

const SystemAdminFeedback = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {};
  config["feedback"] = {
    collection: "feedback",
    name: "Feedback",
    defaultSort: { selector: 4, asc: false },

    showLikes: false,
    buttonName: "Save",
    security: {
      create: "AllUsers",
      edit: "AllUsers",
      delete: "AllUsers",
      view: "AllUsers",
    },
    flags: "attachments comments",
    fields: [
      {
        placeholder: "",
        display_name: "User",
        name: "user",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Request Type",
        name: "requestType",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Feedback",
        name: "feedback",
        input_type: "text",
        colspan: 3,
        default: "",
        required: true,
      },
    ],
    table: [
      {
        selector: (row) => row.user,
        name: "User",
        sortable: true,
        minWidth: "10%",
      },
      {
        selector: (row) => row.requestType,
        name: "Request Type",
        sortable: true,
        minWidth: "10%",
      },
      {
        selector: (row) => row.feedback,
        name: "Feedback",
        sortable: true,
        minWidth: "10%",
      },
      {
        selector: (row) => row.creationDate,
        name: "Creation Date",
        hide: "md",
        sortable: true,
        /* sortOrder: "desc", */
        minWidth: "10%",
        sortFunction: (rowB, rowA) => {
          // Perform ASCII comparison
          if (
            typeof rowB.creationDate === "undefined" ||
            rowB.creationDate === ""
          )
            return true;
          if (
            typeof rowA.creationDate === "undefined" ||
            rowA.creationDate === ""
          )
            return true;
          return rowB.creationDate.localeCompare(rowA.creationDate);
        },
        cell: (row) =>
          row.creationDate ? row.creationDate.substring(0, 10) : "Unknown",
      },
    ],
    filterField: "feedback",
    exportFields: ["user", "feedback"],
    otherTableOptions: { paginationPerPage: 10 },
  };
  return (
    <div className='table-listing-page-wrapper'>
      <SuperAppObject
        depth={1}
        style={superAppStyle}
        config={config["feedback"]}
        user={props.user}
        userDeep={props.userDeep}
        collection_name='feedback'
        display_state='TABLE'
      />
    </div>
  );
};

export default SystemAdminFeedback;
/*
        input_data={props.feedback}
*/
