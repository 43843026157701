import React, { useContext, useState } from 'react';

import RowMargin from "./RowMargin";
import GlobalContext from '../GlobalContext';

const TesterSideBar = (props) => {
  const [activeMenu, setActiveMenu] = useState("Setup Test");
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  const activeStyle = superAppStyle.sideBarActive;
  const inactiveStyle = superAppStyle.sideBarInactive;

  return (
    <>
      <ul className='menu-lists'>
        <li className='menu-item'><button className={activeMenu === 'Setup Test' && 'active'} onClick={(e) => { setActiveMenu("Setup Test"); props.callback("Setup Test");}}>Setup Test</button></li>
{/*
        <li className='menu-item'><button className={activeMenu === 'Statistics' && 'active'} onClick={(e) => { setActiveMenu("Statistics"); props.callback("Statistics")}}>Statistics</button></li>
        <li className='menu-item'><button className={activeMenu === 'System Config' && 'active'} onClick={(e) => { setActiveMenu("System Config"); props.callback("System Config")}}>System Config</button></li>
*/}
      </ul>
    </>
  );
};

export default TesterSideBar;
