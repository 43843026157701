import React, { useContext } from "react";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";
import BackupConfig from "./BackupConfig";
import RestoreConfig from "./RestoreConfig";
import { fetchSecure } from "../utils/utils";
import { useMyContext } from "../MyContext";

const SystemAdminConfig = (props) => {
  const { setData } = useMyContext();
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {};
  config["adminConfig"] = {
    collection: "adminConfig",
    name: "Admin Config",
    showLikes: false,
    buttonName: "Save",
    security: {
      create: "AllUsers",
      edit: "AllUsers",
      delete: "None",
      view: "AllUsers",
    },
    flags: "",
    fields: [
      {
        placeholder: "",
        display_name: "Config ID",
        name: "id",
        input_type: "display",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Agent Types",
        name: "agentTypes",
        input_type: "list",
        list_fields: ["agentType", "displayName", "URL", "displayRule"],
        display_list_field: "agentType",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Default Next Agents",
        name: "defaultNextAgents",
        input_type: "list",
        list_fields: ["agentName", "id", "agentType"],
        display_list_field: "agentName",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Debug On",
        name: "debugOn",
        input_type: "checkbox",
        help: "Turns on global debugging flag to debug issues in production",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Allow Any Website Scanning",
        name: "allowAnyScanning",
        input_type: "checkbox",
        default: "",
        help: "Allows customers to scan any website....turn OFF in production",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Admin Emails (new version)",
        name: "adminEmails",
        help: "This will replace Admin Email 1, 2 eventually.  These emails get CC'd on certain maintenance related notifications",
        input_type: "list",
        regex: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Admin Email 1",
        name: "adminEmail1",
        help: "These emails get CC'd on certain maintenance related notifications",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Admin Email 2",
        name: "adminEmail2",
        input_type: "text",
        default: "",
        required: true,
      },
      /* KAD took out Jan 29, 2025  LEFT 1 and 2 above just in case we haven't rewritten all the places that use those fields to use the List
         {
            placeholder: "",
            display_name: "Admin Email 3",
            name: "adminEmail3",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Admin Email 4",
            name: "adminEmail4",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Admin Email 5",
            name: "adminEmail5",
            input_type: "text",
            default: "",
            required: false
         },
         {
            placeholder: "",
            display_name: "Admin Email 6",
            name: "adminEmail6",
            input_type: "text",
            default: "",
            required: false
         },
*/
      {
        placeholder: "",
        display_name: "Max Trials Allowed",
        name: "maxTrialsAllowed",
        input_type: "text",
        help: "Number of simultaneous active trials we will allow",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Show Admin Tool Chat",
        name: "showAdminToolChat",
        input_type: "checkbox",
        help: "Shows the Admin Tool Chatbot on the Agent builder UI",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Admin Tool ChatBot URL",
        name: "adminToolChatBot",
        input_type: "text",
        help: "We might have a Chatbot for our own software, and this URL will point to the actual chatbot",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Agent URL",
        name: "agentURL",
        input_type: "text",
        help: "This is the URL for the embedded Iframe agent that shows up on Agent Details screen.  We have a Dev version for Dev environment, and a different one for Production",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Failed Responses",
        name: "failedResponses",
        input_type: "list",
        help: 'This is a list of messages the LLM might return to the user which we will use to call "Failed" and use to improve the agent by adding more training data.  Add new ones as you discover them.',
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Max Messages Per Month (NOT USED???)",
        name: "maxMessagesPerMonth",
        input_type: "text",
        help: "This is not used anymore I believe",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Async File Size Threshold (MB)",
        name: "asyncFileSizeThreshold",
        help: "This is the maximum sized file we will try to run synchronously, before we give up and just tell the user to wait for an email when it's done",
        input_type: "text",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name:
          "Max FAQ Length (chars) (NOT USED CURRENTLY...in User Object",
        name: "maxFAQLength",
        help: "This is the system level maximum length one can enter into an FAQ input box.  Account/User level setting will override typically",
        input_type: "text",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Days Till Purge",
        name: "daysTillPurge",
        input_type: "text",
        help: "This is the number of days before we purge deleted Agents. Use by the purge code in the apis directory.",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Trial Length",
        name: "trialLength",
        input_type: "text",
        help: "The length in days of a trial",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Alert Num Days Before Trial Ends",
        name: "alertNumDaysBeforeTrialEnds",
        input_type: "text",
        help: "This is the number of days we send emails to the user before their trial ends",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Environment Name",
        name: "environmentName",
        input_type: "text",
        help: "The name of the environment so we can keep straight if we are in Dev, Test, or Production since they all look similar",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Default FAQ Prompt",
        name: "defaultFAQPrompt",
        input_type: "textarea",
        colspan: 3,
        text_area_num_rows: 10,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Notification Webhook",
        name: "notificationWebhook",
        input_type: "text",
        help: "This is the URL for a webhook which will take alerts programmed in the software and send them to administrators. We currently use Google Chat, but this could easily be text messages in the future if we built a custom webhook.",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Marketing Message",
        name: "marketingMessage",
        input_type: "text",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Contact Us Request Types",
        name: "requestTypes",
        input_type: "list",
        help: "This is the list of Request Types that show up on the Contact page.  We might think of others in the future.",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Privacy Policy",
        name: "privacyPolicy",
        input_type: "textarea",
        help: "This is the privacy policy statement that shows up at the bottom of the Create Account screen.  It can contain HTML.",
        colspan: 3,
        text_area_num_rows: 10,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Test Setups",
        name: "testSetups",
        help: "A list of test setups that Testing can use to test software quicker",
        input_type: "list",
        list_fields: ["testName", "displayName"],
        list_config: [
          { input_type: "text", size: "20", required: true },
          { input_type: "text", size: "50", required: true },
        ],
        display_list_field: "testName",
        default: "",
        required: false,
      },
      {
        placeholder: "Creation Date",
        display_name: "Creation Date",
        name: "creationDate",
        input_type: "date",
        flags: "display",
        required: false,
      },
      {
        placeholder: "Update Date",
        display_name: "Update Date",
        name: "updateDate",
        input_type: "date",
        flags: "display",
        required: false,
      },
    ],
    table: [
      {
        selector: (row) => row.id,
        name: "Admin ID",
        sortable: true,
        minWidth: "10%",
      },
    ],
    filterField: "id",
    exportFields: ["id"],
    otherTableOptions: { paginationPerPage: 20 },
  };

  const dynamicDisplayButtons = [
    { component: BackupConfig, label: "Backup Config", _key: "id" },
    { component: RestoreConfig, label: "Restore Config", _key: "id" },
  ];

  // To update the context after succesful saving. Vik, Feb 11, 2025
  const updateAdminConfig = async () => {
    try {
      const adminResponse = await fetchSecure("/api/getAdminConfig");
      const adminData = await adminResponse.json();
      console.log("Admin config updated:", adminData);
      setData(adminData); // Update global context
    } catch (error) {
      console.error("Failed to update admin config:", error);
    }
  };
  return (
    <div className="table-listing-page-wrapper">
      <SuperAppObject
        depth={1}
        style={superAppStyle}
        config={config["adminConfig"]}
        user={props.user}
        userDeep={props.userDeep}
        collection_name="adminConfig"
        id={props.id}
        dynamicDisplayButtons={dynamicDisplayButtons}
        display_state="DISPLAY"
        updateAdminConfig={updateAdminConfig} // Pass API function as prop
      />
    </div>
  );
};

export default SystemAdminConfig;
