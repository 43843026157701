import React, { useState, useEffect, useContext } from "react";

import { Spinner } from "react-bootstrap";
import GlobalContext from "../GlobalContext";

import { fetchSecure } from "../utils/utils";
import "../index.css";
import SuperAppButton from "./SuperAppButton";

const ScanWebsite = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [ scanning, setScanning ] = useState(false);

  const scanWebsite = async () => {
    alert(
      "Scanning " + props.data["website"] + " and building search index"
    );
    setScanning(true);
    const response = await fetchSecure(
      "api/scanWebsite/" +
        encodeURIComponent(props.data["website"]) +
        "/" +
        props.data["id"] +
        "/" +
        props.user
    );
    //console.log(response);
    const data = await response.json();
    //console.log(data);
    //if (globalData.debug)
    //console.log("Result from scan = " + JSON.stringify(data));
    if ("status" in data && data.status === "failed")
      alert("Website scanning failed: " + data.error);
    if ("status" in data && data.status === "success") {
      alert("Website scanning was successful");
      props.refreshDetails();
    }
    setScanning(false);
  };

  useEffect(() => {
    //console.log("IN HERE ScanWebsite useEffect - ");
    async function run() {
    }
    run();
  }, []);

  return (
     <>
       {props.data && props.data.website &&
            props.data["agentType"] === "WEBSITE_QA" &&
            props.data["website"].length > 0 &&
      <SuperAppButton
        className='primary-gradient-btn'
        onClick={scanWebsite}>
        {props.label}
        {scanning && <><div style={{marginRight: '.5' + 'em'}}></div><Spinner animation='border' size='sm' /></>}
      </SuperAppButton>
     }
     </>
  )
}
export default ScanWebsite;
