import React, { useContext } from "react";
import SuperAppObject from "../components/superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";
import { useMyContext } from "../MyContext";

import { withRouter } from "react-router";
import "./Home.css";

//Components
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
import ScanWebsite from "../components/ScanWebsite"; /* KAD added Jan 29, 2025 */

function Agents(props) {
  //const globalData = useContext(GlobalContext);
  const myContext = useMyContext();
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const debug = myContext["data"]["debugOn"];

  const containerRef = React.useRef(null);

  function getConfig() {
    var configObj = {};
    try {
      //setSortData({selector: "creationDate", asc: true});
      /* KAD I am not using this subtable concept for testPlans anymore..made own component Jan 26, 2025 */
      configObj["testPlan"] = {
        collection: "testPlan",
        name: "Test Plan",
        showLikes: false,
        buttonName: "Save",
        security: {
          create: "AllUsers",
          edit: "AllUsers",
          delete: "None",
          view: "AllUsers",
        },
        flags: "",
        fields: [
          {
            placeholder: "",
            display_name: "ID",
            name: "id",
            input_type: "display",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Question",
            name: "question",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Answer",
            name: "answer",
            input_type: "textarea",
            colspan: 3,
            default: "",
            required: true,
          },
        ],
        table: [
          {
            selector: (row) => row.question,
            name: "Question",
            sortable: true,
            minWidth: "45%",
          },
          {
            selector: (row) => row.answer,
            name: "Answer",
            sortable: true,
            minWidth: "45%",
          },
        ],
        filterField: "question",
        exportFields: ["question", "answer"],
        otherTableOptions: { paginationPerPage: 20 },
      };
      configObj["agents"] = {
        collection: "agents",
        name: "Agents",
        showLikes: false,
        buttonName: "Save",
        defaultSort: { selector: 5, asc: false },
        security: {
          create: "AllUsers",
          edit: "AllUsers",
          delete: "createdBy",
          view: "AllUsers",
        },
        flags: "attachments filter_by_user quickReplies",
        subTables: [configObj["testPlan"]],
        fields: [
          {
            header: "General",
            colspan: 3,
          },
          {
            placeholder: "",
            display_name: "Agent Status",
            name: "status",
            input_type: "display",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Agent ID",
            name: "id",
            input_type: "display",
            link: "[agentURL]/chat/WWWW/[id]",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Agent Version",
            name: "version",
            input_type: "dropdown",
            values: ["v1"],
            help: "You can choose which version of agent you would like to use. Newer ones may have additional features or performance benefits. Please read the documentation or release notes for more information about upgrading.",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Agent Type",
            name: "agentType",
            input_type: "dropdown",
            values: "adminConfig.agentTypes", //[ "NEW_AGENT_TYPE", "FAQ", "DOCUMENT_QA", "DIALOGFLOW", "WEBHOOK"],
            dropdown_value_key: "agentType",
            dropdown_display_template: "[displayName]",
            help: "FYI, this field will change based on your subscription plan. For convenience, we have defined ALL of the agent types available.  The FAQ agent answers questions based on loading your FAQs. The Document QA agent will answer questions about an uploaded PDF or DOCX file.  The Website QA agent will scan your website, and try to answer questions from the data on your website. The Dialogflow agent will integrate to a chatbot built on Google Cloud.  The Webhook agent will connect to a backend server which you create and allows developers to create more complex chatbots using their own technology stack. If you need another type of chatbot, please contact us at info@genaisolutions.ai for more information.",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Model Type",
            name: "modelType",
            input_type: "dropdown",
            values: ["Gemini", "Open AI"],
            default: "",
            help: "We recommend you start with Gemini first. If you can't get the results you desire you can try Open AI",
            conditional: "agentType == FAQ",
            required: true,
          },
          {
            placeholder: "A short name for the agent",
            display_name: "Agent Name",
            name: "agentName",
            input_type: "text",
            help: "This field is informational only",
            max_length: 25,
            default: "",
            required: true,
          },
          {
            placeholder: "A description of the agent",
            display_name: "Agent Description",
            name: "agentDescription",
            input_type: "textarea",
            help: "This field is informational only",
            colspan: 3,
            required: false,
          },
          {
            placeholder: "",
            display_name: "Webhook URL",
            name: "webhookURL",
            input_type: "text",
            colspan: 3,
            default: "",
            conditional: "agentType == WEBHOOK",
            required: true,
          },
          {
            placeholder:
              "Type/paste in any data that can be used to answer questions from customers",
            display_name: "FAQs",
            name: "faqs",
            input_type: "textarea",
            help: "This field will store the data that contains questions and answer from your customer.  The data can be stored in many formats. You can have the questions on the left and the answers on the right with a colon between them (e.g., What time is it?:3PM), or you can go vertical with the auestion on top and the answer on the bottom (e.g., Q: What time is it <NEWLINE> A: 3PM.  It should even be possible to just dump in paragraphs of text that describe your business.  You will need to experiment with your data entry to get the perfect answer for your customers.",
            text_area_num_rows: 20,
            colspan: 3,
            display_x_rows: 20,
            is_scrollable: true,
            scrollable_max_height: "250px",
            max_length: "users.maxFAQLength",
            default: "",
            conditional: "agentType == FAQ",
            required: true,
          },
          {
            placeholder:
              "Website you'd like to scan data (http://www.example.com)",
            display_name: "Website to scan",
            name: "website",
            input_type: "text",
            default: "",
            conditional: "agentType == WEBSITE_QA",
            required: true,
          },
          {
            placeholder:
              "Enter a personalization prompt to control the answers to customer queries more precisely.",
            display_name: "Prompt",
            name: "prompt",
            input_type: "textarea",
            text_area_num_rows: 10,
            help: "Select one of the following to get started, or you can create your own.",
            colspan: 3,
            // samples: ["You are an expert on [TOPIC]. You can only answer questions from the given context.  Do not use your global knowledge to answer questions.  Answer any question in 3-5 sentences. If you don't know the answer to a question, say you don't know the answer. If the user asks a question about something other than [TOPIC], say you are only an expert on [TOPIC].", "You speak multiple languages and you are an expert on [TOPIC]. You can only answer questions from the given context.  Do not use your global knowledge to answer questions.  Detect the language the question is and Answer that question in the same language in 3-5 sentences. If you don't know the answer to a question, say you don't know the answer. If the user asks a question about something other than [TOPIC], say you are only an expert on [TOPIC]."], KAD took out based on recommendations for Dev team Jan 22, 2025
            samples: [
              "You are a [ROLE] specializing in [TOPIC]. Answer questions concisely in no more than three sentences using the given context. If a question falls outside your expertise, politely inform the user of your focus area and encourage them to ask about [TOPIC].",
              "You are a friendly assistant who helps with [TOPIC]. Provide short, accurate answers within three sentences based on the context provided. If you don’t have the information, let the user know and suggest they refine their question.",
              "You are an expert in [TOPIC] who explains concepts with examples. Answer any question with clarity and, if needed, provide an example in no more than three sentences. Stay within the provided context and redirect the user to ask about [TOPIC] if they go off track.",
              "You are a problem solver for [TOPIC]. Provide clear, actionable answers in three sentences or fewer, focusing on practical solutions from the given context. If a question isn’t related to [TOPIC], politely remind the user of your area of expertise.",
              "You are a multilingual expert on [TOPIC]. Respond to questions using the language of the query and keep answers within three sentences, relying only on the provided context. If the user strays from [TOPIC], remind them of your expertise area and encourage topic-specific questions.",
            ],
            max_length: 500,
            default: "",
            conditional: "agentType == DOCUMENT_QA",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Dialogflow Agent ID",
            name: "dialogflowAgentId",
            input_type: "text",
            default: "",
            conditional: "agentType == DIALOGFLOW",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Dialogflow Project ID",
            name: "dialogflowProjectId",
            input_type: "text",
            default: "",
            conditional: "agentType == DIALOGFLOW",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Dialogflow Location",
            name: "dialogflowLocation",
            input_type: "text",
            default: "",
            conditional: "agentType == DIALOGFLOW",
            required: true,
          },
          {
            header: "Customization",
            colspan: 3,
          },
          {
            placeholder:
              "Enter a personalization prompt to control the answers to customer queries more precisely",
            display_name: "Prompt",
            name: "prompt",
            input_type: "textarea",
            text_area_num_rows: 10,
            colspan: 3,
            max_length: 500,
            samples: [
              "You are an expert on [TOPIC]. You can only answer questions from the given context.  Do not use your global knowledge to answer questions.  Answer any question in 3-5 sentences. If you don't know the answer to a question, say you don't know the answer. If the user asks a question about something other than [TOPIC], say you are only an expert on [TOPIC].",
            ],
            default: "",
            conditional: "agentType == WEBSITE_QA",
            required: false,
          },
          {
            placeholder: "",
            display_name: "FAQ Prompt",
            name: "faqPrompt",
            input_type: "textarea",
            help: 'Entering data into this field will override the default FAQ Prompt.  The data in this field needs to be entered a particular way for the agent to work, so we recommend you select the default prompt under "Show/Hide Samples" and modify that.  Please retain the values in the {} (brackets) as these values are searched and replaced during execution',
            samples: [
              "Please answer the question from the following context:Question:Answer{faqs}{otherContext} If none of the questions match, answer the question by saying that you don't know the answer to the question.  Please use '--' in front of multiple itemized answers to the question.  Question: {question}",
              "You speak multiple languages.  Please detect which language the user is speaking and answer the questions in that same language from the following context:\n Question:Answer\n {faqs}\n {otherContext}\n If none of the questions match, answer the question by saying that you don't know the answer to the question.\n Please use '--' in front of multiple itemized answers to the question.\n Question: {question}\n",
            ],
            text_area_num_rows: 5,
            colspan: 3,
            max_length: 500,
            default: "",
            conditional: "agentType == FAQ",
            required: false,
          },
          {
            placeholder: "The welcome message to the user",
            display_name: "First Phrase",
            name: "firstPhrase",
            input_type: "textarea",
            help: "This is message that greets the users when they first start using the agent. For example, Welcome to my chat bot. It can answer questions like how is the weather or where can I buy a good wine",
            samples: [
              "Welcome to my [TOPIC] chatbot. I can answer questions about [TOPIC].",
              "Good {timeOfDay}.  Welcome to my [TOPIC] chatbot. It can answer questions about [TOPIC].",
            ],
            colspan: 3,
            required: false,
          },
          {
            placeholder: "An image URL that shows up when the chatbot starts",
            display_name: "First Image",
            name: "firstImage",
            input_type: "text",
            image_picker: true,
            help: "When the user starts using your agent/chatbot, you can show an image as part of the initialization sequence",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Ask For Name?",
            name: "askForName",
            input_type: "checkbox",
            help: "Turns on Ask For Name functionality.  Add the phrase for more customization",
            required: false,
          },
          {
            placeholder: "A list of quick replies",
            display_name: "Quick Replies",
            name: "quickReplies",
            input_type: "list",
            help: 'Quick Replies (or chips) are buttons which show up in the Agent/Chatbot screen and make it easy for the user to click on versus having to type in their response.  The values entered here only show up when you type "help" or click on the "plus" button near the bottom of the chatbot screen. They are many times sample questions the user can ask the bot to get started.',
            default: "",
            required: false,
          },
          {
            placeholder: "The text inside the chat input field",
            display_name: "Input Helper Text",
            name: "inputHelperText",
            input_type: "text",
            help: "The agent/chatbot comes with some default text in the little input area at the bottom of the screen.  Use this field if you want to change it to something else.",
            default: "",
            required: false,
          },
          {
            placeholder: "The phrase to explain why you are asking",
            display_name: "Ask For Name Phrase",
            name: "askForNamePhrase",
            input_type: "text",
            help: "This will prompt the user to enter their user name and email if you desire",
            required: false,
          },
          {
            placeholder:
              "The text that is displayed when somebody types 'help'",
            display_name: "Help Text",
            name: "helpText",
            input_type: "textarea",
            help: "Use this field to tell the user how to use your chatbot/agent. Use this field in conjunction with the Quick Replies to explain what type of queries your bot can handle.",
            text_area_num_rows: 3,
            default: "",
            colspan: 3,
            required: false,
          },
          {
            placeholder: "",
            display_name: "Play Sound On Response",
            name: "playSoundOnResponse",
            input_type: "dropdown",
            values: ["beep1", "beep2", "beep3", "beep4", "beep5"],
            help: "Play sound when response is printed",
            //conditional1: "users.userId == kevindunetz@koodums.com",
            //conditional1: "users.userId IN admins",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Speak Response",
            name: "speakResponse",
            input_type: "checkbox",
            help: "Speak the words for the response",
            //conditional1: "users.userId == kevindunetz@koodums.com",
            conditional1: "users.userId IN admins",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Show Citation",
            name: "showCitation",
            input_type: "checkbox",
            default: "",
            help: "Check this if you want the location in the file to be shown at the end of the response",
            conditional: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Max Response Size",
            name: "maxSentences",
            input_type: "dropdown",
            values: ["3", "4", "5", "6", "7", "8", "9", "10"],
            help: "Tell the LLM how many sentences you want the response to contain",
            default: "3",
            conditional: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Salutations",
            name: "salutations",
            input_type: "list",
            default: "",
            help: "",
            conditional: "",
            required: false,
          },
          {
            placeholder: "Select a second agent to call and try and answer",
            display_name: "Next Agent",
            name: "nextAgent",
            input_type: "dropdown",
            help: "By using this field, you are telling the system to go to another agent to answer a customer's query IF this agent is not able to answer.  We monitor answers like \"I don't know\" to determine if the agent has failed.  We limit the second agent to either WEBSITE QA or DOCUMENT QA agents....which have more sophistication and allow for alternative sources to answer. In the future, other options like a search of Wikipedia or Google Search might be available.",
            values: "myObjects",
            dropdown_value_key: "id",
            dropdown_display_template: "[agentName] - [agentType]",
            default: "",
            conditional: "agentType == FAQ",
            conditional1:
              "agentType == FAQ AND users.planName != silver AND users.planName != freemium AND users.planName != trial",
            required: false,
          },
          {
            header: "User Interface",
            colspan: 3,
          },
          {
            placeholder:
              "The color of the chatbot/agent header (e.g., blue, red, green or #FF0000 format allowed)",
            display_name: "Header Color",
            name: "headerColor",
            input_type: "color",
            help: "This is the background color of the top of the agent/chatbot",
            default: "#32a111",
            required: true,
            validation: { isColor },
          },
          {
            placeholder: "The color of the agent/chatbot content area",
            display_name: "Background Color",
            name: "backgroundColor",
            input_type: "color",
            help: "This is the color of the content area of the agent/chatbot",
            default: "#FFFFFF",
            required: true,
            validation: { isColor },
          },
          {
            placeholder: "The color of the user request bubble",
            display_name: "User Request Bubble Color",
            name: "userRequestBubbleColor",
            input_type: "color",
            help: "This is the color of the user request bubble",
            default: "#ADD8E6",
            required: true,
            validation: { isColor },
          },
          {
            placeholder: "The color of the user question font",
            display_name: "User Request Font Color",
            name: "userRequestFontColor",
            input_type: "color",
            help: "This is the color of the user request font",
            default: "#000000",
            required: true,
            validation: { isColor },
          },
          {
            placeholder: "The color of the chatbot response bubble",
            display_name: "Chatbot Response Color",
            name: "chatbotResponseBubbleColor",
            input_type: "color",
            help: "This is the color of the chatbot response bubble",
            default: "#EDEDED",
            required: true,
            validation: { isColor },
          },
          {
            placeholder: "The color of the chatbot response font",
            display_name: "Chatbot Response Font Color",
            name: "chatbotResponseFontColor",
            input_type: "color",
            help: "This is the color of the chatbot response font",
            default: "#000000",
            required: true,
            validation: { isColor },
          },
          /*
            {
               placeholder: "",
               display_name: "Border Width",
               name: "borderWidth",
               input_type: "dropdown",
               values: ["1px", "2px", "3px"],
               //conditional1: "users.userId == kevindunetz@koodums.com",
               conditional1: "users.userId IN admins",
               help: "This is width of the border around the chatbot",
               required: false,
            },
*/
          {
            placeholder: "",
            display_name: "Icon Location",
            name: "iconLocation",
            input_type: "dropdown",
            //values: ["Top Right", "Bottom Right"], OLD WAY before Jan 14, 2025
            //default: "Bottom Right",
            values: ["top: 0px;", "bottom: 48px;"],
            values_display_names: ["Top Right", "Bottom Right"],
            default: "bottom: 48px;",
            help: "This is the location of the chat icon on your website",
            required: true,
          },
          {
            placeholder:
              "For this next field, you can add in some extra tokens that will be replaced at runtime: {timeOfDay} returns morning, afternoon, or night, and {user} will populate the user's name to make the interaction more personable",
            display_name: "",
            name: "",
            input_type: "none",
            required: false,
          },
          {
            placeholder: "An image URL for the Agent Icon for your website",
            display_name: "Agent Icon",
            name: "agentIcon",
            input_type: "text",
            help: "This is the image to use for your agent/chat in production.  We have provided a couple samples here for you to get started, but you can use any image you want",
            samples: [
              "https://upload.wikimedia.org/wikipedia/commons/8/85/Circle-icons-chat.svg",
              "https://upload.wikimedia.org/wikipedia/commons/e/e2/Echo_chat_icon.svg",
            ],
            default:
              "https://upload.wikimedia.org/wikipedia/commons/8/85/Circle-icons-chat.svg",
            required: true,
          },
          {
            placeholder:
              "The animated Gif that draws while waiting for a response.",
            display_name: "Animation GIF",
            name: "animationGIF",
            input_type: "text",
            image_picker: true,
            help: 'Some responses take a while to complete.  The default animation is a "Spinner", but if you want something else you can enter a URL here',
            default: "",
            required: false,
          },
          {
            placeholder: "This configures the text under the animation image",
            display_name: "Animation Caption",
            name: "animationCaption",
            input_type: "text",
            help: 'This feature goes with the Animation GIF feature.  You could say something like "Calculating Your Answer" or "Searching for an Answer". The default is "Thinking".',
            default: "",
            required: false,
          },
          {
            placeholder:
              "The font color of the Animation Caption (e.g., blue, red, green or #FF0000 format allowed)",
            display_name: "Animation Caption Color",
            name: "animationCaptionColor",
            input_type: "color",
            help: "This is the font color of Animation Caption",
            default: "#000000",
            required: false,
            validation: { isColor },
          },
          {
            header: "Administration",
            colspan: 3,
          },
          {
            placeholder: "",
            display_name: "Authorized Editors",
            name: "userIds",
            input_type: "list",
            help: "A list of other people who are authorized to edit this agent/chatbot.  Do NOT delete yourself from this list. FYI, the change only takes effect after you SAVE the entire form. Just exit out of the form if you delete yourself by accident.",
            default: "",
            regex: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$",
            required: false,
          },
          {
            placeholder: "A list of IP addresses to block",
            display_name: "Blocked IPs",
            name: "blockedIPs",
            input_type: "list",
            help: "A list of IP addresses you want to block from accessing your agents/chatbots",
            regex:
              "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$",
            default: "",
            required: false,
          },
          {
            placeholder: "A list of IP addresses to allow",
            display_name: "Allowed IPs",
            name: "allowedIPs",
            input_type: "list",
            help: "A list of IP addresses you want to allow access to your agents/chatbots",
            regex:
              "^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$",
            default: "",
            required: false,
          },
          {
            placeholder:
              "Put a message here and the agent will be disabled for ALL",
            display_name: "Disabled",
            name: "disabled",
            input_type: "checkbox",
            help: "Check this box if you want to disable ALL access to the agent/chatbot.  You might want to do this if you find an error in your chatbot and need time to fix it.  Even if you check this box, the agent/chatbot will continue to work in the Admin Tool embedded agent/chatbot...so you can debug it.",
            default: "",
            required: false,
          },
          {
            placeholder:
              "The message that will be shown if the agent is disabled",
            display_name: "Disabled Agent Message",
            name: "disabledMessage",
            input_type: "textarea",
            colspan: 3,
            help: "You can use html in this field to render tables or pictures.  You could also change font sizes, styles, or colors.",
            default: "",
            required: false,
          },
          {
            placeholder: "Stream results or show results all at one time",
            display_name: "Stream Results",
            name: "streamResults",
            input_type: "checkbox",
            help: "Check this box if you want to stream the results from the backend versus show it all at the same time which tends to take longer to show",
            default: "true",
            required: false,
          },
          {
            placeholder:
              "A customer configurable list of Failed Responses that will triggered the Failed Message Response OR will trigger the Next Agent feature",
            display_name: "Failed Messages",
            name: "failedMessages",
            input_type: "list",
            help: "A customer configurable list of Failed Responses that will triggered the Failed Message Response OR will trigger the Next Agent feature",
            default: "",
            required: false,
          },
          {
            placeholder:
              "A message you'd like to say to user if you can't answer their question",
            display_name: "Failed Message Response",
            name: "failedMessage",
            input_type: "textarea",
            colspan: 3,
            help: "This would probably be something like. If you'd like more information contact info@xxx.com",
            default: "",
            required: false,
          },
          {
            placeholder:
              "A message you'd like to say to user if you exceed your current plan message count",
            display_name: "Exceed Plan Message Count Response",
            name: "exceedPlanMessage",
            input_type: "textarea",
            colspan: 3,
            help: "This would probably be something like. If you'd like more information contact info@xxx.com",
            default: "",
            required: false,
          },
          {
            placeholder:
              "Your privacy policy statement and links to your website.  Will show a form as well to get acknowledgement of the policy",
            display_name: "Privacy Policy",
            name: "privacyPolicy",
            input_type: "textarea",
            colspan: 3,
            help: "Your privacy policy statement and links to your website.  Will show a form as well to get acknowledgement of the policy",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Indexing Complete",
            name: "indexingComplete",
            input_type: "display",
            default: "",
            conditional: "agentType == DOCUMENT_QA",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Indexing Complete",
            name: "indexingComplete",
            input_type: "display",
            default: "",
            conditional: "agentType == WEBSITE_QA",
            required: false,
          },
          {
            placeholder: "Message Count",
            display_name: "Monthly Message Count",
            name: "messageCount",
            input_type: "text",
            readonly: true,
            show_if_populated: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "Last Website Scan",
            display_name: "Last Website Scan",
            name: "lastWebsiteScan",
            input_type: "text",
            readonly: true,
            show_if_populated: true,
            conditional: "agentType == WEBSITE_QA",
            flags: "display",
            required: false,
          },
          {
            placeholder: "Cloned Date",
            display_name: "Cloned Date",
            name: "clonedDate",
            input_type: "text",
            readonly: true,
            show_if_populated: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "Cloned Object ID",
            display_name: "Cloned Object ID",
            name: "clonedObjectId",
            input_type: "text",
            readonly: true,
            show_if_populated: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "Wix Instance Id",
            display_name: "Wix Instance Id",
            name: "wixInstanceId",
            input_type: "text",
            show_if_populated: true,
            readonly: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "Plan Name",
            display_name: "Plan Name",
            name: "planName",
            input_type: "text",
            show_if_populated: true,
            readonly: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "Subscribed?",
            display_name: "Subscribed",
            name: "subscribed",
            input_type: "boolean",
            //show_if_populated: true,
            readonly: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "Locked?",
            display_name: "Locked",
            name: "locked",
            input_type: "boolean",
            show_if_populated: true,
            readonly: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "The date you subscribed",
            display_name: "Subscribe Date",
            name: "subscribeDate",
            input_type: "text",
            show_if_populated: true,
            readonly: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "The date you cancelled",
            display_name: "Cancellation Date",
            name: "cancellationDate",
            input_type: "text",
            show_if_populated: true,
            readonly: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "Created By",
            display_name: "Created By",
            name: "createdBy",
            input_type: "text",
            readonly: true,
            flags: "display",
            required: false,
          },
          {
            placeholder: "Creation Date",
            display_name: "Creation Date",
            name: "creationDate",
            input_type: "date",
            flags: "display",
            required: false,
          },
          {
            placeholder: "Update Date",
            display_name: "Update Date",
            name: "updateDate",
            input_type: "date",
            show_if_populated: true,
            flags: "display",
            required: false,
          },
          /* KAD added to stage this on Jan 20, 2025 but not 100% sure where each of these fields belongs */
          /*
            {
               placeholder: "",
               display_name: "Max Website Pages",
               name: "maxWebsitePages",
               input_type: "input",
               conditional1: "users.userId IN admins AND agentType == WEBSITE_QA",
               help: "This is the max pages that can be scanned for this agent",
               required: false,
            },
            {
               placeholder: "",
               display_name: "LLM Model Name",
               name: "llmModelName",
               input_type: "dropdown",
               values: ["gemini-1.5-flash-002", "gemini-1.5-flash-001-pro", "gemini-2.0-flash-exp"],
               conditional1: "users.userId IN admins",
               help: "This is the underlying model used with the vector database",
               required: false,
            },
            {
               placeholder: "",
               display_name: "Max Output Tokens",
               name: "maxOutputTokens",
               input_type: "input",
               conditional1: "users.userId IN admins",
               help: "This is the max number of tokens that can be used to formulate an answer to a query",
               required: false,
            },
*/
          {
            placeholder: "",
            display_name: "Embedded Code Snippet",
            name: "id",
            input_type: "display",
            show_copy_button: true,
            //For scrollbar - Arpan
            is_scrollable: true,
            scrollable_max_height: "250px",
            display_template: `<div id='iframeContainer' class='toggleBtn' style='display: none;'>
<iframe id='myIframe' class='myIframe' src='[agentURL]/chat/WWWW/[id]' width='400' height='600' scrolling='no'></iframe>
</div>
<button id='toggleBtn' class='toggleBtn'>
  <img src='[agentIcon]' height='40px', width='40px'></img>
</button>
<style>
.myIframe {
  border-radius: 20px;
  overflow: hidden;
}
.toggleBtn {
  z-index: 999;
  position: fixed;
  [iconLocation] \/\/change this value to adjust the vertical location
  right: 48px; \/\/change this value to adjust the horizontal location 
}
</style>
<script>
const toggleBtn = document.getElementById('toggleBtn');
const iframeContainer = document.getElementById('iframeContainer');
toggleBtn.addEventListener('click', () => {
iframeContainer.style.display = iframeContainer.style.display === 'none' ? 'block' : 'none';
});
</script>`,
            colspan: 3,
            default: "",
            required: false,
          },
        ],
        table: [
          {
            selector: (row) => row.id,
            name: "Agent ID",
            sortable: true,
            minWidth: "20%",
          },
          {
            selector: (row) => row.user,
            name: "User",
            wrap: true,
            sortable: true,
            hide: "md",
            minWidth: "10%",
          },
          {
            selector: (row) => row.agentName,
            name: "Agent Name",
            sortable: true,
            minWidth: "30%",
          },
          {
            selector: (row) => row.agentType,
            name: "Agent Type",
            sortable: true,
            minWidth: "10%",
          },
          {
            selector: (row) => row.creationDate,
            name: "Creation Date",
            hide: "md",
            sortable: true,
            sortOrder: "desc",
            minWidth: "10%",
            sortFunction: (rowB, rowA) => {
              // Perform ASCII comparison
              if (typeof rowB.creationDate === "undefined") return true;
              return rowB.creationDate.localeCompare(rowA.creationDate);
            },
            cell: (row) =>
              row.creationDate ? row.creationDate.substring(0, 10) : "Unknown",
          },
        ],
        filterField: "agentName",
        exportFields: [
          "id",
          "agentName",
          "agentDescription",
          "agentType",
          "prompt",
          "faqs",
          "firstPhrase",
          "webhookURL",
          "dialogflowAgentId",
          "dialogflowProjectId",
          "dialogflowLocation",
          "helpText",
          "headerColor",
          "inputHelperText",
          "animationGIF",
          "animationCaption",
          "creationDate",
          "updateDate",
        ],
        otherTableOptions: { paginationPerPage: 20 },
      };

      if (props.show_deleted === true) {
        /* KAD added Jan 26, 2025 */
        configObj["agents"]["collection"] = "agentsDeleted";
        configObj["agents"]["name"] = "Deleted Agents";
        configObj["agents"]["flags"] = "attachments quickReplies";
      }
    } catch (e) {
      console.log("Failed to configure: " + e);
      console.log(e.stack);
    }
    return configObj;
  }

  const isColor = (strColor) => {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== "";
  };

  function validateObject(obj) {
    // check to see if the agent is ready to be used
    // check for attachments, indexes completed..complex formatting of fields like FAQ
    // and update the status during the save option
    let statusError = "";
    if (!("indexingComplete" in obj) && obj.agentType === "DOCUMENT_QA") {
      statusError +=
        "Agent Not Ready for use.  Please upload files to create the index";
    }

    /* if (!isColor(obj.headerColor)) {
         statusError += ", Header Color is not a valid color";
      } */

    if (statusError.length === 0) return { status: "Agent ready for use" };
    else return { status: statusError };
  }
  //cell: row => row.deal.substring(0,30) ,
  const config = getConfig();

  const scrollToTop = () => {
    if (containerRef.current !== null) {
      //containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'top' });
      containerRef.current.scrollIntoView(true);
    }
  };

  /* KAD added Jan 29, 2025 */
  const dynamicDisplayButtons = [
    { component: ScanWebsite, label: "Scan Website", _key: "id" },
  ];
  return (
    <React.StrictMode>
      <div className="table-listing-page-s1">
        <div className="custom-container">
          {props.user && (
            <div className="table-listing-page-wrapper">
              {props.user &&
                props.user.length > 0 &&
                props.id &&
                props.id.length > 0 && (
                  <SuperAppObject
                    title={props.show_deleted ? "Deleted Agents" : ""}
                    depth={1}
                    id={props.id}
                    style={superAppStyle}
                    config={config["agents"]}
                    deepConfig={config}
                    user={props.user}
                    userDeep={props.userDeep}
                    collection_name={
                      props.show_deleted ? "agentsDeleted" : "agents"
                    }
                    display_state="DISPLAY"
                    validate_object={validateObject}
                    scroll_to_top={scrollToTop}
                    showAgent="true"
                    dynamicDisplayButtons={dynamicDisplayButtons}
                  />
                )}

              {props.user && props.user.length > 0 && !("id" in props) && (
                <SuperAppObject
                  title={props.show_deleted ? "Deleted Agents" : ""}
                  depth={1}
                  style={superAppStyle}
                  config={config["agents"]}
                  deepConfig={config}
                  user={props.user}
                  userDeep={props.userDeep}
                  collection_name={
                    props.show_deleted ? "agentsDeleted" : "agents"
                  }
                  display_state="TABLE"
                  validate_object={validateObject}
                  scroll_to_top={scrollToTop}
                  showAgent="true"
                  dynamicDisplayButtons={dynamicDisplayButtons}
                />
              )}
            </div>
          )}
          <NotLoggedInMessage {...props} />
          {props.showLoginErrorMessage && <Footer />}
        </div>
      </div>
    </React.StrictMode>
  );
}
export default withRouter(Agents);
