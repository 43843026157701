import React, { useState, useEffect, useContext } from "react";

import GlobalContext from "../GlobalContext";

import { fetchSecure } from "../utils/utils";
import "../index.css";
import SuperAppButton from "./SuperAppButton";

const BackupConfig = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState([]);

  async function handleBackupAdminConfig() {
    const response = await fetchSecure(
      "/api/copyObject/adminConfig/adminConfigBackup/" + props.user
    );
    const data = await response.json();

    if (data.success) {
      alert("Admin Config has been backed up");
    } else {
      alert("Admin Config could NOT be backed up");
    }
  }

  useEffect(() => {
    //console.log("IN HERE BackupConfig useEffect - ");
    async function run() {
    }
    run();
  }, []);
  return (
      <SuperAppButton
        className='primary-gradient-btn'
        onClick={handleBackupAdminConfig}>
        {props.label}
      </SuperAppButton>
  )
}
export default BackupConfig;
