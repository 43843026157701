import React, { useEffect, useState } from "react";

//Components
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
//import { useMyContext } from "../MyContext";
import RowMargin from "../components/RowMargin";
import ConfigEditor from "../components/ConfigEditor";
import "../index.css"

const jsonData = {
  name: 'John',
  age: 30,
  city: 'New York'
};
const config =  { "tab_order":[{"name":"order_management", "displayName": "Order Management", "id":"0"}],
                 "UpdateDate":"2023-12-27T03:08:16.124Z",
                 "app_config":{
                    "order_management":
                         {
                         "security":
                            {"view":"AllUsers","edit":"CreatedBy","create":"AllUsers","delete":"CreatedBy"},
                         "displayType":"TABLE",
                         "table":[{"name":"field1",display_name:"Field 1", "sortable":true,"id":"0"},{"name":"field2",display_name: "Field 2", "sortable":false, "id":"0"}],
                         "name":"Order Management",
                         "style":{},
                         "fields":[
                              {"name":"asdf","input_type":"text","id":"0","display_name":"asf"},
                              {"name":"lasdkjf","input_type":"text","id":"0","display_name":"lkjasd"}],
                         "filterField":"field1",
                         "tableName":"order_management"}}}

export default function Playground(props) {
  //const myContext = useMyContext();
  

  useEffect(() => {
    //console.log("IN HERE Help UseEffect");
    async function loadComponent() { console.log("User changed to " + props.user);}
  
    loadComponent();
  }, [props.user, props.userDeep]);

  return (
    <div> 
     <ConfigEditor user={props.user} userDeep={props.userDeep} />
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
    </div>
  );
}
/*
     <Editor
      value={jsonData}
      onChange={updatedJson => console.log(updatedJson)}
    />
*/
