import React, { useEffect, useState, useContext } from "react";
import { HashLink } from "react-router-hash-link";

//Components
import RowMargin from "./RowMargin";
import SuperAppButton from "./SuperAppButton";
import NotLoggedInMessage from "./NotLoggedInMessage";
import Footer from "./Footer";

import { useMyContext } from "../MyContext";

import { fetchSecure } from "./superappobject/utils";

export default function SetupTest(props) {
  const [wixToken]  = useState(localStorage.getItem("wixToken")); // KAD Dec 26, 2024 ...need logic to show different billing information based on Wix or Firebase 
  const myContext = useMyContext();

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {}
    loadComponent();
  }, []);

  const handleSubmit = async (_ev) => {
    _ev.preventDefault();
    _ev.stopPropagation();
    try {
       const testTypeSelect = _ev.target.querySelector('select[name="test_type"]'); 
       const testType = testTypeSelect.value;
       const res = await fetchSecure("/api/setup_test", {
         headers: { "Content-Type": "application/json" },
         method: "post",
         body: JSON.stringify({ "test_type": testType, user_id: props.userDeep.id}),
       });
       const finalResp = await res.json();
       if (finalResp["status"] === 'success')  {
          alert(testType + " was setup successfully.  " + finalResp["msg"]);
       } 
       else {
          alert(testType + " was NOT setup successfully" );
       }
       testTypeSelect.selectedIndex = -1;
    } catch (e) {
       console.error(e);
       console.error(e.stack);
    }
  };

  return (
    <React.StrictMode>
      <>
        <>
          <>
            {props.user && props.user.length > 0 && (
              <>
                <h3>Setup Test</h3>
                  <>
                    <form onSubmit={handleSubmit}>
 <select id="test_type" name="test_type">
                    <option key={"xxx"} value="">Select a Test Type</option>
            {myContext["data"]["testSetups"].map((option, index) => {
                return (
                    <option key={option["testName"]} value={option["testName"]}>{option["displayName"]}</option>
                )
            })}
  </select>
                      <RowMargin />
                      <SuperAppButton
                        id='checkout-and-portal-button'
                        type='submit'
                        className='primary-gradient-btn'>
                        Setup Test 
                      </SuperAppButton>
                      <RowMargin />
                    </form>
                  </>
              </>
            )}
            <NotLoggedInMessage {...props} />
            {props.showLoginErrorMessage && <Footer />}
          </>
        </>
      </>
    </React.StrictMode>
  );
}
/*
    <option value="cronjob_testing">Cronjob Testing</option>
    <option value="credit_card_testing">Credit Card Testing</option>
*/
