import React, { useEffect, useState } from "react";

import { Row, Col, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Components
import RowMargin from "./RowMargin";
import SuperAppButton from "./SuperAppButton";
import { fetchSecure } from "../utils/utils";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// generate select dropdown option list dynamically
function Options({ options }) {
  return options.map((option, index) => (
    <MenuItem key={index} value={option}>
      {option}
    </MenuItem>
  ));
}

export default function Registration(props) {
  const [status, setStatus] = useState("IDLE");
  var numEmployeeOptions = ["1-9", "10-99", "100-999", "> 1000"];

  const handleSubmit = async (values) => {
    try {
      /* this will cause the Loading text to show up while waiting */
      setStatus("SUBMITTED");
      values.id = props?.userDeep?.id;
      const response = await fetchSecure("/api/saveObject/users", {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(values),
      });
      const data = await response.json();
      if (data.success) {
        console.log("INSIDE SUCCESS");
        setStatus("SUCCESS");
        props.setScreen(1);
        setTimeout(() => {
          console.log("RELOADING ======");
          window.location.href = "/";
        }, 3000);
        //await fetchSecure("/api/verifyUser/" + data.id); KAD took this out Feb 4, 2025 because not sure we need it anymore
      } else {
        console.log("in data failure()");
        throw Error("Failed to register");
      }
    } catch (e) {
      console.log(`Failed to add register`);
      console.error(e);
      console.error(e.stack);
      setStatus("ERROR");
    }
  };

  useEffect(() => {
    async function loadComponent() {
      setStatus("IDLE");
    }
    loadComponent();
  }, [props.userDeep]);

  const validationSchema = Yup.object({
    question1: Yup.string().required("Please provide a company name"),
    question2: Yup.string()
      .url("Invalid url format, Example: https://example.com")
      .required(
        "Please provide your website URL (this allows us to help configure your chatbot)"
      ),
    question3: Yup.string().required("Please provide an employee count"),
    question4: Yup.string().required(
      " Please provide some reasons why you want to evaluate this solution"
    ),
  });

  const formik = useFormik({
    initialValues: {
      question1: "",
      question2: "",
      question3: "",
      question4: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <React.Fragment>
        {status !== "SUCCESS" && (
          <>
            <p style={{ color: "var(--light-color)" }}>
              Thanks for your interest in the Gen Al Solutions Agent Builder.
              Before we give you full access for 30 days to try our amazing
              technology, we need to ask a couple of quick questions.
            </p>
            <form className='login-form' onSubmit={formik.handleSubmit}>
              <TextField
                className='form-group'
                label='Name'
                placeholder='Company name'
                fullWidth
                name='question1'
                value={formik.values.question1}
                onChange={formik.handleChange}
                error={
                  formik.touched.question1 && Boolean(formik.errors.question1)
                }
                helperText={formik.touched.question1 && formik.errors.question1}
              />
              <TextField
                className='form-group'
                label='Website'
                placeholder='Company website (https://example.com)'
                fullWidth
                name='question2'
                value={formik.values.question2}
                onChange={formik.handleChange}
                error={
                  formik.touched.question2 && Boolean(formik.errors.question2)
                }
                helperText={formik.touched.question2 && formik.errors.question2}
              />
              <FormControl
                className='form-group'
                fullWidth
                variant='outlined'
                error={
                  formik.touched.question3 && Boolean(formik.errors.question3)
                }>
                <InputLabel id='question3-label'>
                  Number of Employees
                </InputLabel>
                <Select
                  labelId='question3-label'
                  name='question3'
                  value={formik.values.question3}
                  onChange={formik.handleChange}
                  MenuProps={{
                    disablePortal: true, // Ensures the menu is not rendered in a portal
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Adjust menu height
                        overflowY: "auto", // Ensure scrolling works smoothly
                      },
                    },
                  }}
                  label='Number of Employees'>
                  {Options({
                    options: numEmployeeOptions,
                  })}
                </Select>
                <FormHelperText>{formik.errors.question3}</FormHelperText>
              </FormControl>
              <TextField
                className='form-group'
                label='Can you explain why you are interested in our solution?'
                placeholder='Explain why you are interested in our solution'
                fullWidth
                multiline
                rows={3}
                name='question4'
                value={formik.values.question4}
                onChange={formik.handleChange}
                error={
                  formik.touched.question4 && Boolean(formik.errors.question4)
                }
                helperText={formik.touched.question4 && formik.errors.question4}
              />
              <div className='submit-btn-lists'>
                <SuperAppButton
                  type='submit'
                  className='submit-btn primary-gradient-btn'>
                  Save
                </SuperAppButton>
              </div>
            </form>
          </>
        )}
      </React.Fragment>

      {status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='success'>
                Thank you for registering. You will now be redirected to our trial page.
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
}
/*
                Thank you for registering. Please check your inbox for an email
                from Gen AI Solutions, and click on the provided link to enable
                your trial. If you don't see it in your main inbox, please check
                your spam folder.
*/
