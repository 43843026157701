import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";

//Components
import RowMargin from "../components/RowMargin";
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import GlobalContext from "../GlobalContext";
import NewPlanTable from "./NewPlanTable"; //Subscribe route - Sourav - 31Jan25

export default function Subscribe(props) {
  const [screen, setScreen] = useState(1);
  const [currentPlanName, setCurrentPlanName] = useState(
    props?.userDeep?.planName
  );
  const [, /*allPlansData*/ setAllPlansData] = useState([]);
  const [newPlanData, setNewPlanData] = useState([]);
  const [trialNumDaysLeft, setTrialNumDaysLeft] = useState(
    props?.userDeep?.trialLength || 100
  );
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [formData, setFormData] = useState({
    data: props?.userDeep,
    status: "IDLE",
  });

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      /* const response = await fetch("/api/getObjects/plans"); */
      const response = await fetch("/api/getPlans");
      const planDataArray = await response.json();
      setAllPlansData(planDataArray);

      setFormData({
        data: props?.userDeep,
        status: "IDLE",
      });

      var d1 = new Date(props?.userDeep?.creationDate),
        d2 = new Date();
      setTrialNumDaysLeft(
        props?.userDeep?.trialLength - Math.floor((d2 - d1) / 60000 / 24 / 60)
      );
      setCurrentPlanName(props?.userDeep?.planName);
    }
    loadComponent();
  }, [props?.userDeep]);

  async function nextScreen() {
    setScreen(screen + 1);
  }
  async function selectPlan(planName) {
    /* first load the specifics of the plan that was selected */
    /*
         const response = await fetch("/api/getObjects/plans/where/planName/==/" + planName)
         const planDataArray = await response.json();
         const planData = planDataArray[0];
    */
    const response = await fetch("/api/getPlanByName/" + planName);
    const planData = await response.json();
    setNewPlanData(planData);
    console.log(planData);

    /* Add the data into the form */
    formData.data["planName"] = planName;
    formData.data["maxAgents"] = planData["maxAgents"];
    formData.data["maxMessageCount"] = planData["maxMessageCount"];
    formData.data["maxFileSize"] = planData["maxFileSize"];

    /* save the form */
    const response2 = await fetch("/api/saveObject/users", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(formData.data),
    });
    const data = await response2.json();
    if (data.success) {
      setScreen(screen + 1);
    } else {
      alert("Plan was not updated");
    }
  }
  const capitalize = (str) => {
    str = str.substring(0, 1).toUpperCase() + str.substring(1);
    return str;
  };
  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  //Subscribe route - Sourav - 31Jan25
  function showPlanButtons() {
    const wixToken = localStorage.getItem("wixToken");
    if (typeof wixToken === "string" && wixToken.length > 0) return false;
    return true;
  }

  //Subscribe UI - Sourav - 31Jan25
  return (
    <>
      <RowMargin />
      <h1 style={superAppStyle.homePageH1Style}>
        {`Welcome to your Agent/Chatbot Builder ${props?.userDeep?.planName} subscription`}
      </h1>
      <NewPlanTable
        showButtons={showPlanButtons}
        currentPlanName={currentPlanName || props?.userDeep?.planName} //Fix current plan name- Sourav - 30Jan2025
        selectPlan={selectPlan}
        isFinishRegistration={false}
      />
    </>
  );
  return (
    <React.Fragment>
      {props?.user && screen === 1 && (
        <>
          <RowMargin />
          <h1 style={superAppStyle.homePageH1Style}>
            Welcome to your Agent/Chatbot Builder Trial
          </h1>
          <div
            style={{
              fontSize: "20px",
              textAlign: "left",
              paddingTop: "30px",
              paddingLeft: "100px",
            }}
          >
            You have {trialNumDaysLeft} days left to try out all our different
            types of agents
            <ul>
              <li>
                FAQ Agent - an agent that will answer frequently asked questions
              </li>

              <li>
                PDF QA Agent - an agent that will answer questions about
                uploaded PDF documents
              </li>
              <li>
                Website QA Agent - an agent that will answer questions about
                scanned websites that you maintain
              </li>
              <li>
                Dialogflow Agent - an agent that will connect to a Dialogflow or
                Vertex AI Agent that you created in Google
              </li>
              <p></p>
            </ul>
            During this trial, you will be allowed to create up to{" "}
            {props?.userDeep.maxAgents} agents of any type; however, you will
            NOT be able to put these agents into any production sites. The
            software provides a way for you to see and interact with your
            chatbot/agent. The software provides a way for you to see and
            interact with your chatbot/agent. You will also be limited by the
            size of the data that you are able to store. Other than these
            limitations, the agents should be fully functional. We also have
            some demos on the main website which can aid in your evaluation.
            <p></p>
            If you have done your evaluation, please use the following table to
            evaluate our various plans. Click on the "SELECT" button for the
            plan you want to choose.
            <p></p>
            <table border="2">
              <tr>
                {" "}
                <td>
                  <b>Feature</b>
                </td>
                <td>
                  <b>Freemium</b>
                </td>
                <td>
                  <b>Silver Plan</b>
                </td>
                <td>
                  <b>Gold Plan</b>
                </td>
                <td>
                  <b>Platinum Plan</b>
                </td>{" "}
              </tr>
              <tr>
                <td>Max Agents</td>
                <td>5</td>
                <td>1</td>
                <td>5</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Max Messages Per Month</td>
                <td>30</td>
                <td>1000</td>
                <td>5000</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Max File Size</td>
                <td>1M</td>
                <td>25M</td>
                <td>50M</td>
                <td>100M</td>
              </tr>
              <tr>
                <td>Type of Bots</td>
                <td>
                  <wrap>FAQ, Dialogflow/Vertex, Webhook</wrap>
                </td>
                <td>Any</td>
                <td>Any</td>
                <td>Any</td>
              </tr>
              <tr>
                <td>Agent Chaining</td>
                <td>
                  <wrap>No</wrap>
                </td>
                <td>No</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Automated Agent Testing</td>
                <td>
                  <wrap>No</wrap>
                </td>
                <td>No</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Supports Production Workloads</td>
                <td>No</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Supports Dev/Test/Prod Environments</td>
                <td>No</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>Cost</td>
                <td>$0</td>
                <td>$25/mo/agent</td>
                <td>$50/mo/agent</td>
                <td>$100/mo/agent</td>
              </tr>
              <tr>
                <td> </td>
                <td></td>
                <td style={{ textAlign: "center" }}>
                  {currentPlanName !== "silver" && (
                    <Button onClick={(e) => selectPlan("silver")}>
                      SELECT
                    </Button>
                  )}
                  {currentPlanName === "silver" && <>CURRENT</>}
                </td>
                <td style={{ textAlign: "center" }}>
                  {currentPlanName !== "gold" && (
                    <Button onClick={(e) => selectPlan("gold")}>SELECT</Button>
                  )}
                  {currentPlanName === "gold" && <>CURRENT</>}
                </td>
                <td style={{ textAlign: "center" }}>
                  {currentPlanName !== "platinum" && (
                    <Button onClick={(e) => selectPlan("platinum")}>
                      SELECT
                    </Button>
                  )}
                  {currentPlanName === "platinum" && <>CURRENT</>}
                </td>
              </tr>
            </table>
            <p></p>
            Assistance to help you build your Agent is available. Contact
            sales@genaisolutions.ai for more infomation about the cost.
            <p></p>
          </div>
        </>
      )}
      {props?.user && screen === 2 && (
        <>
          <div className="consent-to-bill-msg-sec">
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper">
                <h1>Consent to Bill</h1>
                <h4>
                  You have selected the{" "}
                  <strong>{capitalize(newPlanData["planName"])}</strong> plan.
                  By clicking the "Accept" button, you consent to being charge $
                  {newPlanData["cost"]}/month until you cancel the service. You
                  also understand that billing is only monthly so if you don't
                  cancel before your billing Day of month you will have to pay
                  the entire month.
                  <RowMargin />
                  <div className="more-action">
                    <Button
                      name="Submit"
                      onClick={nextScreen}
                      className="primary-gradient-btn action-btn"
                    >
                      Accept
                    </Button>
                  </div>
                </h4>
              </div>
            </div>
          </div>
        </>
      )}
      {props?.user && screen === 3 && (
        <>
          <div className="consent-to-bill-msg-sec">
            <div className="custom-container">
              <div className="consent-to-bill-msg-wrapper">
                <h1>Billing Screen</h1>
                <h4>SELECT YOUR BILLING INFORMATION AND HIT PAY</h4>
                <div className="more-action">
                  <Button
                    className="action-btn primary-gradient-btn"
                    name="Submit"
                    onClick={nextScreen}
                  >
                    Accept
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <NotLoggedInMessage {...props} />
      {props?.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
/*
    Current Plan = {capitalize(currentPlanName)}
          <form>
            <select name="planName" onChange={handleInputChange}>
              <option selected value=""></option>
              { currentPlanName !== "freemium" && <option value="freemium">Freemium</option>}
              { currentPlanName !== "silver" && <option value="silver">Silver</option>}
              { currentPlanName !== "gold" && <option value="gold">Gold</option>}
              { currentPlanName !== "platinum" && <option value="platinum">Platinum</option>}
            </select>
          </form> 
          <Button name="Submit" onClick={handleClick}>Submit</Button>
*/
