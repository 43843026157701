import React, { useEffect } from "react";

//Components
import Feedback from "../components/Feedback";
import RowMargin from "../components/RowMargin";
import Footer from "../components/Footer";
// import GlobalContext from "../GlobalContext";

export default function Contact(props) {
  //const containerRef = React.useRef(null);

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {}
    loadComponent();
  }, []);

  return (
    <div className="contact-page center-form">
      <div className="custom-container">
        <div className="form-sec">
          <div className="form-container w700px">
            <div className="headerPart">
              <h4>
                For help with support, email us at{" "}
                <a href="mailto:support@genaisolutions.ai">
                  support@genaisolutions.ai
                </a>
              </h4>
              <h4>
                For sales, email us at{" "}
                <a href="mailto:support@genaisolutions.ai">
                  sales@genaisolutions.ai
                </a>
              </h4>
              <h4>
                For general questions/information, email us at{" "}
                <a href="mailto:support@genaisolutions.ai">
                  info@genaisolutions.ai
                </a>
              </h4>
             {Object.keys(props.userDeep).length > 0 &&
              <>
              <h4 style={{textAlign: "center"}}>
               OR 
              </h4>
              <h4 style={{textAlign: "center"}}>
                 Fill out the form below
              </h4>
              <RowMargin />
              </>
            }
            </div>
            {Object.keys(props.userDeep).length > 0 &&
            <div className="bodyPart">
              <Feedback
                user={props.user}
                user_id={props.user}
                userDeep={props.userDeep}
              />
            </div>
            }
            {props.showLoginErrorMessage && <Footer />}
          </div>
        </div>
      </div>
    </div>
  );
}
