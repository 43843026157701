import React from "react";
// import GlobalContext from "../GlobalContext";

//Components
// import RowMargin from "./RowMargin";
// import SuperAppButton from "./SuperAppButton";
import { Link } from "@mui/material";

export default function NotLoggedInMessage(props) {
  // const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  return (
    <React.Fragment>
      {!props.user && props.showLoginErrorMessage && (
        <>
          {/* start: main-banner */}
          <div className="main-banner">
            <div className="custom-container">
              <div className="text-contianer">
                <h1>Empower Your Vision with GenAI Agent Builder.</h1>
                <p>
                  Create intelligent, responsive agents that redefine
                  engagement. With GenAI Agent Builder, you hold the keys to a
                  custom AI experience - tailored, scalable, and ready to drive
                  value across your industry.
                </p>
                <div className="more-action">
                  <Link
                    href={"#genAiSolution-Welcome-sec"}
                    type="button"
                    className="primary-gradient-btn"
                  >
                    Get Started
                    <span className="icon">
                      <img alt="" src="/images/si_arrow-right-fill.svg" />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="image-contianer">
                <img src="/images/main-banner-pic1.png" alt="" />
              </div>
            </div>
          </div>
          {/* end: main-banner */}

          {/* start: agent-builder-specification-sec */}
          <div className="agent-builder-specification-sec">
            <div className="custom-container">
              <div className="sec-wrapper">
                <div className="item-lists">
                  <div className="item-col">
                    <div className="item-box">
                      <div className="icon">
                        <img
                          src="/images/agent-builder-specification-icon1.svg"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Build AI Agents Without Coding Expertise</h3>
                        <p>No coding required, just an easy to use editor that simplifies AI creation.</p>
                      </div>
                    </div>
                  </div>

                  <div className="item-col">
                    <div className="item-box">
                      <div className="icon">
                        <img
                          src="/images/agent-builder-specification-icon2.svg"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Customizable AI to Fit Your Business Needs</h3>
                        <p>
                          Your business is unique, and so are your needs. The
                          GenAI Agent Builder allows you to fully customize AI
                          behavior
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item-col">
                    <div className="item-box">
                      <div className="icon">
                        <img
                          src="/images/agent-builder-specification-icon3.svg"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Seamless Integration with Existing Systems</h3>
                        <p>
                          Our integration capabilities allow your AI agents to
                          work within your existing tech ecosystem.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item-col">
                    <div className="item-box">
                      <div className="icon">
                        <img
                          src="/images/agent-builder-specification-icon4.svg"
                          alt=""
                        />
                      </div>
                      <div className="text">
                        <h3>Data-Driven Insights to Optimize Performance</h3>
                        <p>
                          Gain actionable insights with comprehensive analytics
                          and reporting tools.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end: agent-builder-specification-sec */}

          {/* start: genAiSolution-Welcome-sec */}
          <div
            className="genAiSolution-Welcome-sec"
            id="genAiSolution-Welcome-sec"
          >
            <div className="custom-container">
              <div className="image-container">
                <img src="/images/genAiSolution-Welcome-pic.png" alt="" />
              </div>
              <div className="text-container">
                {/* <div className="tag-area">
                  <div className="tag-box">GenAI solution</div>
                </div> */}
                <h1>Welcome to our Agent/Chatbot Builder</h1>

                <p>
                  Using this tool, you can create different types of
                  Chatbots/Agents for your business website, or your
                  organization's internal Intranets. Chatbots can be a great way
                  to engage with your customers anonymously to learn about their
                  needs and help them get their questions answered without
                  having to talk with every customer.
                </p>
                <ul className="icon-with-text-lists">
                  <li>
                    <div className="icon">
                      <img src="/images/icon1.svg" alt="" />
                    </div>
                    <div className="text">
                      <h4>
                        If you have an account already, please click on the{" "}
                        <span className="link-color">Login </span>
                        link to access the tool
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="/images/icon2.svg" alt="" />
                    </div>
                    <div className="text">
                      <h4>
                        If you are a customer of{" "}
                        <span className="link-color">WIX</span>, we will be
                        releasing our solution in the WIX marketplace shortly,
                        however you can use our trial here to get started
                      </h4>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <img src="/images/icon3.svg" alt="" />
                    </div>
                    <div className="text">
                      <h4>
                        If you want to
                        <span className="link-color">
                          {" "}
                          create Agents for other websites
                        </span>{" "}
                        (than WIX) or mobile devices, please login to get a
                        trial account
                      </h4>
                    </div>
                  </li>
                </ul>
                <p>
                  Please note that you will need to log in with a business email
                  address that matches your website's domain if you wish to
                  build a Chatbot that answers questions from a scan of your
                  website (like a Google Search of your website). The log in
                  screen provides a way to create a new account and password to
                  achieve this. You cannot use a Gmail account.
                </p>
                {/* <div>
                <SuperAppButton
                  className='primary-gradient-btn'
                  size='lg'
                  onClick={() => {
                    window.location = "/signin";
                  }}>
                  Sign In
                </SuperAppButton>
              </div> */}
              </div>
            </div>
          </div>
          {/* end: genAiSolution-Welcome-sec */}
        </>
      )}
    </React.Fragment>
  );
}
/*
            <li> If you are a customer of WIX, please click <a href="http://www.wix.com">here</a> to get access through the WIX marketplace</li> 
*/
