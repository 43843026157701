//import Header from "../comp/header";
//import Footer from "../comp/footer";
// import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { auth } from "../firebase/config";
import { Form, Alert } from "react-bootstrap";

import RowMargin from "../components/RowMargin";
import SuperAppButton from "../components/SuperAppButton";

import { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import GlobalContext from "../GlobalContext";

import {
  updateProfile,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

// import { useNavigate } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";

//import Loading from "../comp/Loading";

//import Error404 from "./error404";

export default function Signup() {
  //const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [showForm, setShowForm] = useState(true);
  const [hasError, setHasError] = useState();
  const [errorMessage, setErrorMessage] = useState();
  // const [hasAlert, setHasAlert] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const [userName /* setUserName */] = useState();
  const [phone /* setPhone */] = useState();
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  useEffect(() => {
    if (user) {
      //navigate("/");
      //window.location = "/"
    }
  });

  //5MIfPjTuGBgiEsWlEqFjOljPB3b2

  // Sign Up Function For The SignUp Button
  const signUpBTN = (eo) => {
    eo.preventDefault();
    if (password !== password2) {
      setHasError(true);
      setErrorMessage("Passwords need to match");
    } else {
      setHasError(false);
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed up
          const user = userCredential.user;
          console.log(user);
          sendEmailVerification(auth.currentUser).then(() => {
            console.log("Email verification sent!");
            setShowForm(false);
            setAlertMessage(
              "An Email with a link has been sent to your email to confirm"
            );
            // ...
          });

          updateProfile(auth.currentUser, {
            displayName: userName,
            phoneNumber: phone,
          })
            .then(() => {
              // Profile updated!
              // ...
            })
            .catch((error) => {
              // An error occurred
              // ...
            });
          console.log("Done");

          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          setHasError(true);
          switch (errorCode) {
            case "auth/invalid-email":
              setErrorMessage("Invalid Email");

              break;

            case "auth/invalid-credential":
              setErrorMessage("Invalid Email");
              break;

            case "auth/weak-password":
              setErrorMessage("Weak Password");
              break;

            case "auth/email-already-in-use":
              setErrorMessage("Email already in use");
              break;

            default:
              setErrorMessage("Please Check Your Email/Password....");
              break;
          }
          // ..
        });
    }
  };

  //Loading

  //Not Sign In

  //Sign in Without email verfication

  // sign in && email verified email  => navigate()
  if (loading) {
    // {
    //   /* <Loading/> */
    // }
    return <>Loading</>;
  }
  if (error) {
    // {
    //   /* return <Error404/>; */
    // }
    return <>Error</>;
  }

  if (true) {
    return (
      <>
        {/* <Helmet> */}
        <title>Sign Up Page </title>
        {/* </Helmet> */}
        <main>
          <h1 style={superAppStyle.homePageH1Style}>Create A New Account </h1>
          {showForm && (
            <Form className="login-form" onSubmit={signUpBTN}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>

              <RowMargin />
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <RowMargin />
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <div style={{ display: "flex", width: "100%" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button onClick={() => setShowPassword(!showPassword)}>
                    {" "}
                    {showPassword ? <FaEye /> : <FaEyeSlash />}{" "}
                  </button>
                </div>
              </Form.Group>

              <RowMargin />
              <Form.Group controlId="formBasicPassword2">
                <Form.Label>Password 2</Form.Label>
                <div style={{ display: "flex", width: "100%" }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password again"
                    value={password2}
                    onChange={(e) => setPassword2(e.target.value)}
                    required
                  />
                  <button onClick={() => setShowPassword(!showPassword)}>
                    {" "}
                    {showPassword ? <FaEye /> : <FaEyeSlash />}{" "}
                  </button>
                </div>
              </Form.Group>
              <RowMargin />
              <SuperAppButton
                onClick={(eo) => {
                  signUpBTN(eo);
                }}
                variant="primary"
                type="submit"
              >
                Create Account
              </SuperAppButton>
              <RowMargin />
            </Form>
          )}
          {alertMessage && <Alert variant="success">{alertMessage}</Alert>}
          {hasError && <Alert variant="danger">{errorMessage}</Alert>}
          {false && user && (
            <Alert variant="success">Successfully Logged In</Alert>
          )}
          <p className="account">
            Already have an account ... <Link to={"/signin"}>Sign In</Link>{" "}
          </p>
          {/* </div> */}
        </main>
      </>
    );
  } else {
    <div>User already signed up</div>;
  }
}
/*
          <form action="">
            <input
              onChange={(eo) => {
                setuserName(eo.target.value);
              }}
              id="username"
              placeholder="User Name..."
              type="text"
              required
            />
            <input
              onChange={(eo) => {
                setemail(eo.target.value);
              }}
              id="email"
              placeholder="Email..."
              type="email"
              required
            />
            <input
              onChange={(eo) => {
                setPhone(eo.target.value);
              }}
              id="phonenumber"
              placeholder="Phone..."
              type="number"
              required
            />
            <input
              onChange={(eo) => {
                setpassword(eo.target.value);
              }}
              id="password"
              placeholder="Password..."
              type="password"
              required
            />
            <button
              onClick={(eo) => {
                signUpBTN(eo)
              }}
            >
              Sign Up
            </button>
            {hasError && <p>{errorMessage}</p>}
          </form>
*/
