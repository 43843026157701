import React, { useEffect, useState } from "react";

//Components
// import RowMargin from "../components/RowMargin";
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import SystemAdminSidebar from "../components/SystemAdminSidebar";
import FailedMessages from "../components/FailedMessages";
import SystemAdminStatistics from "../components/SystemAdminStatistics";
import Plans from "../components/Plans";
import Users from "../components/Users";
import SystemAdminAgents from "../components/SystemAdminAgents";
import SystemAdminFeedback from "../components/SystemAdminFeedback";
import SystemAdminConfig from "../components/SystemAdminConfig";
import DeletedObjects from "../components/DeletedObjects"; /* KAD added Jan 26, 2025 */
import { fetchSecure } from "../utils/utils";
import Marketing from "../components/MarketingMessages";

export default function SystemAdmin(props) {
  const [failedMessages, setFailedMessages] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const [, /* users*/ setUsers] = useState([]);
  const [id, setId] = useState("");
  const [screen, setScreen] = useState("Config");

  async function handleBackupAdminConfig() {
    const response = await fetchSecure(
      "/api/copyObject/adminConfig/adminConfigBackup/" + props.user
    );
    const data = await response.json();

    if (data.success) {
      alert("Admin Config has been backed up");
    } else {
      alert("Admin Config could NOT be backed up");
    }
  }

  async function handleRestoreAdminConfig() {
    const response = await fetchSecure(
      "/api/copyObject/adminConfigBackup/adminConfig/" + props.user
    );
    const data = await response.json();

    if (data.success) {
      alert("Admin Config has been restored");
    } else {
      alert("Admin Config could NOT be restored");
    }
  }

  const getAdminData = async (id) => {
    try {
      await fetchSecure("/api/getAdminData")
        .then((response) => response.json())
        .then((data) => {
          setFailedMessages(data["failed_messages"]);
          setUsers(data["users"]);
          setFeedback(data["feedback"]);
          setId(data["id"]);
        });
    } catch (e) {
      console.log(`Failed to getAdminData`);
      console.log(e);
      console.log(e.stack);
    }
  };

  useEffect(() => {
    async function loadAdminData() {
      //console.log("IN HERE Admin UseEffect");
      /* running this command sets some State variables up above */
      await getAdminData();
    }
    loadAdminData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="admin-page-layout">
        <div className="custom-container">
          <SystemAdminSidebar callback={setScreen} />
          {props.user &&
            props.user.length > 0 &&
            props.showLoginErrorMessage && (
              <div className="right-content">
                <>
                  {screen === "Config" && (
                    <>
                      {/*
                      <Box
                        className='d-flex mb-4'
                        style={{
                          flexWrap: "wrap",
                          rowGap: "20px",
                          columnGap: "10px",
                        }}>
                        <SuperAppButton
                          className='primary-gradient-btn'
                          onClick={handleBackupAdminConfig}>
                          Backup Config
                        </SuperAppButton>
                        <SuperAppButton
                          className='primary-gradient-btn'
                          onClick={handleRestoreAdminConfig}>
                          Restore Config
                        </SuperAppButton>
                      </Box>
*/}
                      <SystemAdminConfig
                        id={id}
                        user={props.user}
                        userDeep={props.userDeep}
                      />
                    </>
                  )}
                  {screen === "Statistics" && (
                    <SystemAdminStatistics
                      user={props.user}
                      userDeep={props.userDeep}
                    />
                  )}

                  {screen === "Plans" && (
                    <Plans user={props.user} userDeep={props.userDeep} />
                  )}
                  {screen === "Failed Messages" && (
                    <FailedMessages
                      failedMessages={failedMessages}
                      user={props.user}
                      userDeep={props.userDeep}
                    />
                  )}

                  {screen === "Feedback" && (
                    <SystemAdminFeedback
                      feedback={feedback}
                      user={props.user}
                      userDeep={props.userDeep}
                    />
                  )}
                  {screen === "Users" && (
                    <Users user={props.user} userDeep={props.userDeep} />
                  )}
                  {screen === "AdminAgents" && (
                    <SystemAdminAgents
                      user={props.user}
                      userDeep={props.userDeep}
                    />
                  )}
                  {screen === "Marketing" && (
                    <Marketing user={props.user} userDeep={props.userDeep} />
                  )}
                  {screen === "DeletedObjects" && (
                    <DeletedObjects
                      user={props.user}
                      userDeep={props.userDeep}
                    />
                  )}
                </>
              </div>
            )}
        </div>
      </div>

      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
