import React from "react";

function formatTemplate(template, options) {
  return template.replace(/\[([^\]]+)\]/g, (match, key) => {
    return options[key] || match;
  });
}

// Example usage:
// const displayTemplate = "[agentName] - [agentType]";
/*
const options = {
  agentName: "John Doe",
  agentType: "Sales Agent"
};
*/

// generate select dropdown option list dynamically
function Options({ options, config, def }) {
  //console.log(JSON.stringify(config));
  //console.log(JSON.stringify(options));
  if (
    options &&
    options.length > 0 &&
    typeof options[0] === "object" &&
    typeof config.dropdown_display_template === "string"
  ) {
    /* Dropdown box basd on key/value pairs stored in a Hashmap, and use a display template to show multiple fields in the dropdown view */
    return options.map((option, index) => { /* KAD added index for a unique key Feb 3, 2025 */
      var formattedString = formatTemplate(
        config.dropdown_display_template,
        option
      );
      if (formattedString === config.dropdown_display_template) {
        formattedString = option[config.dropdown_value_key];
      }
      return (
        <option
          key={index}
          value={option[config.dropdown_value_key]}>
          {formattedString}
        </option>
      );
    });
  } else if (
    options &&
    options.length > 0 &&
    typeof options[0] === "object" &&
    typeof config.dropdown_display_template === "undefined"
  ) {
    /* Dropdown box based key/value pairs stored in a Hashmap. Just show the value in the dropdown view */
    return options.map((option) => {
      const key = Object.keys(option)[0];
      const value = Object.values(option)[0];
      //console.log("key = " + key + ", value = " + value);
      return (
        <option key={key} value={key}>
          {value}
        </option>
      );
    });
  } else {
    /* must be a string ....might want to be explicit here */

    return options.map((option) => {
      //const attributes = {}
      //console.log(option + "," + def);
      //if (option === def) attributes["selected"] = "true";
      return <option key={option}>{option}</option>;
    });
  }
}

export default Options;
