import React, { useEffect, useState, useContext } from "react";

//Components
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import TesterSidebar from "../components/TesterSidebar";
import SetupTest from "../components/SetupTest";
import UserAdminStatistics from "../components/UserAdminStatistics";
import SystemConfig from "../components/SystemConfig";
import GlobalContext from "../GlobalContext";

export default function Tester(props) {
  const [screen, setScreen] = useState("Setup Test");

  useEffect(() => {
    async function loadAdminData() {
      //console.log("IN HERE Tester UseEffect");
      /* running this command sets some State variables up above */
    }
    loadAdminData();
  }, []);

  return (
    <React.Fragment>
      <div className='admin-page-layout'>
        <div className='custom-container'>
          <div className='left-side-menu'>
            <TesterSidebar callback={setScreen} />
          </div>
          {props.user &&
            props.user.length > 0 &&
            props.showLoginErrorMessage && (
              <div className='right-content'>
                <>
                  {screen === "Setup Test" && (
                    <SetupTest user={props.user} userDeep={props.userDeep} />
                  )}
                  {screen === "Statistics" && (
                    <UserAdminStatistics user={props.user} userDeep={props.userDeep} />
                  )}
                  {screen === "System Config" && (
                    <SystemConfig user={props.user} userDeep={props.userDeep} />
                  )}
                </>
              </div>
            )}
        </div>
      </div>
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
/*
          {screen ==='Billing' && 
              <Billing user={props.user} userDeep={props.userDeep} />
          }
*/
