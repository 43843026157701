import React, { useEffect, useState } from "react";
import { fetchSecure } from "../utils/utils";

const SecureImage = (props) => {
  const [source, setSource] = useState("");

  useEffect(() => {
    const getSourceResp = async () => {
      const url = props.url;

      if (url.startsWith("/api/getFile/")) {
        const modifiedUrl = url.replace("/api/getFile", "/api/getFileImage");

        const response = await fetchSecure(modifiedUrl);

        var data = await response.json();

        setSource(data[0].src);
      } else {
        setSource(props.url);
      }
    };

    getSourceResp();
  }, [props.url]);

  return <img src={source} width="50px" alt="" />;
};

export default SecureImage;
