import Cookies from "js-cookie";
import { createClient, OAuthStrategy } from "@wix/sdk";
// import { plans } from "@wix/pricing-plans";
import { redirects } from "@wix/redirects";

export default async function WixRedirectURL(plan) {
  const myWixClient = createClient({
    modules: { redirects },
    auth: OAuthStrategy({
      clientId: `498e3f0a-62b1-43ee-8a8d-1ff072217b5b`,
      tokens: JSON.parse(Cookies.get("session") || null),
    }),
  });
  const redirect = await myWixClient.redirects.createRedirectSession({
    paidPlansCheckout: { planId: plan._id },
    callbacks: { postFlowUrl: window.location.href },
  });
  return redirect;
}
